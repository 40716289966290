import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

import invoiceImg from "../../../../Assets/assetsnew/invoices.svg";
import EditProjectModal from "./editProjectModal/EditProjectModal";

import {
  uProjectStartDate,
  uProjectEndDate,
  uProjectBillingScheduleStartDate,
} from "../../../../store/service/projectService";

import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";
import Styled from "./termStyles";
import CustomDatePickerTwo from "../../Commons/DatePickers/CustomDatePickerTwo";
import EditServiceModal from "./editProjectModal/EditServiceModal";
import moment from "moment";

export default function terms() {
  const dispatch = useDispatch();
  const selectedProject = useSelector((state) => state.project.selectedProject);
  const [showEditProjectModal, setShowEditProjectModal] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [invoiceStartDate, setInvoiceStartDate] = useState(null);
  const [showServiceModal, setShowServiceModal] = useState(false);

  useEffect(() => {
    setStartDate(
      selectedProject &&
        selectedProject.project_start_date &&
        moment(selectedProject && selectedProject.project_start_date).toDate()
    );
    setEndDate(
      selectedProject &&
        selectedProject.project_end_date &&
        moment(selectedProject && selectedProject.project_end_date).toDate()
    );

    setInvoiceStartDate(
      selectedProject &&
        selectedProject.billing_scheduled &&
        selectedProject.billing_scheduled.invoice_frequency === "Once" &&
        selectedProject.billing_scheduled.issue_invoice
        ? moment(selectedProject.billing_scheduled.issue_invoice).toDate()
        : selectedProject &&
          selectedProject.billing_scheduled &&
          selectedProject.billing_scheduled.invoice_frequency === "Weekly" &&
          selectedProject.billing_scheduled.weekly_invoice &&
          selectedProject.billing_scheduled.weekly_invoice.start_date
        ? moment(
            selectedProject.billing_scheduled.weekly_invoice.start_date
          ).toDate()
        : selectedProject &&
          selectedProject.billing_scheduled &&
          selectedProject.billing_scheduled.invoice_frequency === "Bi-weekly" &&
          selectedProject.billing_scheduled.biWeekly_invoice &&
          selectedProject.billing_scheduled.biWeekly_invoice.start_date
        ? moment(
            selectedProject.billing_scheduled.biWeekly_invoice.start_date
          ).toDate()
        : selectedProject &&
          selectedProject.billing_scheduled &&
          selectedProject.billing_scheduled.invoice_frequency === "Monthly" &&
          selectedProject.billing_scheduled.monthly_invoice &&
          selectedProject.billing_scheduled.monthly_invoice.start_date
        ? moment(
            selectedProject.billing_scheduled.monthly_invoice.start_date
          ).toDate()
        : null
    );
  }, [showEditProjectModal, selectedProject]);

  const changeStartDate = (date) => {
    const Obj = {
      _id: selectedProject && selectedProject._id,
      project_start_date: date
        ? moment(date).toDate()
        : selectedProject &&
          selectedProject.project_start_date &&
          moment(
            selectedProject && selectedProject.project_start_date
          ).toDate(),
    };
    dispatch(uProjectStartDate(Obj));
  };

  const changeEndDate = (date) => {
    const Obj = {
      _id: selectedProject._id,
      project_end_date: date && moment(date).toDate(),
    };
    dispatch(uProjectEndDate(Obj));
  };

  const changeInvoiceStartDate = (date) => {
    const Obj = {
      _id: selectedProject._id,
      changedDate: date && moment(date).toDate(),
    };
    dispatch(uProjectBillingScheduleStartDate(Obj));
  };

  return (
    <div className="py-4">
      <TextStyles.FontSize20px className="d-flex justify-content-start">
        Terms
      </TextStyles.FontSize20px>

      <Styled.TermBox className="mt-3">
        <div className="d-flex flex-row gap-2 justify-content-between align-items-center w-100">
          <TextStyles.FontSize20px
            className="d-flex text-start w-100"
            style={{ color: Colors.primary, minWidth: 100, fontSize: 22 }}
          >
            <span className="three-dots">
              {selectedProject && selectedProject.project_name}
            </span>
          </TextStyles.FontSize20px>
          <div className="d-flex justify-content-center align-items-center">
            <Styled.TermEditBox onClick={() => setShowEditProjectModal(true)}>
              <BorderColorOutlinedIcon
                sx={{ color: Colors.primary, fontSize: 20 }}
              />

              <TextStyles.FontSize14px
                style={{
                  color: Colors.primary,
                  marginLeft: 6,
                  fontWeight: 600,
                }}
              >
                Edit
              </TextStyles.FontSize14px>
            </Styled.TermEditBox>
          </div>
        </div>
        <hr style={{ height: 0, borderTop: "2px solid #999999" }} />

        <TextStyles.FontSize18px className="d-flex text-start w-100">
          <span className="three-dots"> Billing schedule</span>
        </TextStyles.FontSize18px>
        <TextStyles.GreyFont14px className="d-flex text-start mb-3 w-100">
          <span className="three-dots">
            Set dates to get invoice reminders.
          </span>
        </TextStyles.GreyFont14px>

        <div className="d-flex gap-3 justify-content-center justify-content-sm-start flex-wrap">
          <div>
            <Styled.TermDateBox>
              <CustomDatePickerTwo
                onChange={(date) => {
                  setStartDate(date);
                  changeStartDate(date);
                }}
                selected={startDate}
                title="Start date"
                placeholder="Starts date"
                dateFormat="DD MMM YYYY"
                maxDate={endDate && moment(endDate).toDate()}
                allowClear={false}
              />
            </Styled.TermDateBox>
          </div>
          <div>
            <Styled.TermDateBox>
              <CustomDatePickerTwo
                onChange={(date) => {
                  setEndDate(date);
                  changeEndDate(date);
                }}
                selected={endDate}
                title="End date"
                placeholder={endDate ? endDate : "Ongoing"}
                dateFormat="DD MMM YYYY"
                minDate={startDate && moment(startDate).toDate()}
                allowClear={true}
              />
            </Styled.TermDateBox>
          </div>

          <Styled.TermDateBox>
            <CustomDatePickerTwo
              onChange={(date) => {
                setInvoiceStartDate(date);
                changeInvoiceStartDate(date);
              }}
              selected={invoiceStartDate}
              title="1st Invoice date"
              placeholder="Invoice start date"
              dateFormat="DD MMM YYYY"
              allowClear={true}
            />
          </Styled.TermDateBox>
          <Styled.TermDateBox className="px-2 py-3">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex flex-column">
                <TextStyles.FontSize14px
                  className="d-flex"
                  style={{ fontWeight: 500 }}
                >
                  I will bill
                </TextStyles.FontSize14px>
                <TextStyles.GreyFont14px className="d-flex mt-1">
                  {selectedProject &&
                    selectedProject.billing_scheduled &&
                    selectedProject.billing_scheduled.invoice_frequency}
                </TextStyles.GreyFont14px>
              </div>
              <img src={invoiceImg} alt="calenderBtn" width="20" height="20" />
            </div>
          </Styled.TermDateBox>
        </div>
      </Styled.TermBox>

      <Styled.TermBox className="mt-3 mt-sm-4 mt-md-5">
        <div className="d-flex gap-2 justify-content-between align-items-center w-100">
          <TextStyles.FontSize18px
            className="d-flex text-start w-100"
            style={{ minWidth: 100 }}
          >
            <span className="three-dots"> Project services</span>
          </TextStyles.FontSize18px>
          <div className="d-flex justify-content-center align-items-center">
            <Styled.TermEditBox onClick={() => setShowEditProjectModal(true)}>
              <BorderColorOutlinedIcon
                sx={{ color: Colors.primary, fontSize: 20 }}
              />

              <TextStyles.FontSize14px
                style={{
                  color: Colors.primary,
                  marginLeft: 6,
                  fontWeight: 600,
                }}
              >
                Edit
              </TextStyles.FontSize14px>
            </Styled.TermEditBox>
          </div>
        </div>
        {selectedProject &&
          selectedProject.service_list &&
          selectedProject.service_list.map((item, index) => {
            return (
              <div key={index}>
                <hr style={{ height: 0, borderTop: "2px solid #999999" }} />
                <TextStyles.FontSize14px className="d-flex gap-2 align-items-center w-100">
                  <div
                    className="d-flex"
                    style={{ width: "150%", minWidth: 100 }}
                  >
                    <span className="three-dots"> {item.service_name}</span>
                  </div>
                  <div
                    className="d-flex"
                    style={{ width: "75%", minWidth: 50 }}
                  >
                    <span className="three-dots">
                      {" "}
                      {selectedProject.currency &&
                        selectedProject.currency.symbol}
                      {item.service_rate
                        ? parseFloat(item.service_rate).toFixed(2)
                        : "00.00"}
                    </span>
                  </div>
                  <div
                    className="d-flex justify-content-end"
                    style={{ width: "75%", minWidth: 50 }}
                  >
                    <span className="three-dots">
                      {" "}
                      {item.service_rate_type}
                    </span>
                  </div>
                </TextStyles.FontSize14px>
              </div>
            );
          })}

        <hr style={{ height: 0, borderTop: "2px solid #999999" }} />
        <TextStyles.FontSize14px
          className="d-flex justify-content-end"
          style={{ cursor: "pointer" }}
          // onClick={() => setShowEditProjectModal(true)}
          onClick={() => setShowServiceModal(true)}
        >
          <AddCircleOutlineOutlinedIcon sx={{ color: Colors.font1 }} />
          <div className="ps-2">Add service</div>
        </TextStyles.FontSize14px>
      </Styled.TermBox>

      <Styled.TermBox className="mt-3 mt-sm-4 mt-md-5">
        <div className="d-flex gap-2 justify-content-between align-items-center w-100">
          <TextStyles.FontSize18px
            className="d-flex text-start w-100"
            style={{ minWidth: 100 }}
          >
            <span className="three-dots">Billing schedule</span>
          </TextStyles.FontSize18px>
          <div className="d-flex justify-content-center align-items-center">
            <Styled.TermEditBox onClick={() => setShowEditProjectModal(true)}>
              <BorderColorOutlinedIcon
                sx={{ color: Colors.primary, fontSize: 20 }}
              />

              <TextStyles.FontSize14px
                style={{
                  color: Colors.primary,
                  marginLeft: 6,
                  fontWeight: 600,
                }}
              >
                Edit
              </TextStyles.FontSize14px>
            </Styled.TermEditBox>
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center mt-3 w-100">
          <TextStyles.GreyFont14px
            className="d-flex text-start justify-content-start w-100"
            style={{ minWidth: 100 }}
          >
            <span className="three-dots"> Deposit amount</span>
          </TextStyles.GreyFont14px>
          <TextStyles.GreyFont14px
            className="d-flex justify-content-end w-100"
            style={{ minWidth: 50 }}
          >
            <span className="three-dots">
              {selectedProject &&
              selectedProject.billing_scheduled &&
              selectedProject.billing_scheduled.deposit &&
              selectedProject.billing_scheduled.deposit.require_deposit ===
                true &&
              selectedProject.billing_scheduled.deposit.value
                ? `${
                    selectedProject &&
                    selectedProject.currency &&
                    selectedProject.currency.symbol
                  }${parseFloat(
                    selectedProject.billing_scheduled.deposit.value
                  ).toFixed(2)}`
                : "Deposit not required"}
            </span>
          </TextStyles.GreyFont14px>
        </div>
      </Styled.TermBox>
      {showEditProjectModal && (
        <EditProjectModal
          selectedProject={selectedProject}
          show={showEditProjectModal}
          handleClose={() => setShowEditProjectModal(false)}
        />
      )}

      {showServiceModal && (
        <EditServiceModal
          show={showServiceModal}
          selectedProject={selectedProject}
          onClose={() => setShowServiceModal(false)}
        />
      )}
    </div>
  );
}
