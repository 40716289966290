import React from "react";

import TextStyles from "../../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";

const RelationshiWithParty = (props) => {
  const selectedData = props && props.selectedData;

  const heading =
    selectedData &&
    selectedData.relationship_of_parties &&
    selectedData.relationship_of_parties.heading
      ? selectedData &&
        selectedData.relationship_of_parties &&
        selectedData.relationship_of_parties.heading
      : "Relationship of Parties";
  const paragraph =
    selectedData &&
    selectedData.relationship_of_parties &&
    selectedData.relationship_of_parties.paragraph
      ? selectedData &&
        selectedData.relationship_of_parties &&
        selectedData.relationship_of_parties.paragraph
      : "This Agreement does not establish a partnership, joint venture, agency, or employment relationship between Contractor and Client. Both Parties acknowledge that Contractor is an independent contractor throughout the duration of this Agreement.";

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.relationship_of_parties &&
          selectedData.relationship_of_parties.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox className="mt-4">
        <TextStyles.FontSize20px className="mb-3 fw-medium">
          {heading}
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px className="lh-lg">{paragraph}</TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
    </div>
  );
};

export default RelationshiWithParty;
