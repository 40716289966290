import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import { Popover } from "antd";
import Joyride, { EVENTS, STATUS } from "react-joyride";

import EditNote from "./Components/EditNote";
import NoteList from "./Components/NoteList";
import NoteGrid from "./Components/NoteGrid";

import {
  deleteNote,
  fetchNotesForUser,
} from "../../../../store/service/notesService";
import AssignmentIcon from "@mui/icons-material/Assignment";
import EventIcon from "@mui/icons-material/Event";
import AdjustOutlinedIcon from "@mui/icons-material/AdjustOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import DeleteModal from "../../Commons/DeleteModal";
import ArrowUpwardTwoToneIcon from "@mui/icons-material/ArrowUpwardTwoTone";
import ArrowDownwardTwoToneIcon from "@mui/icons-material/ArrowDownwardTwoTone";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import Pagination from "../../Commons/Pagination";
import { noteActions } from "../../../../store/storage/noteSlice";
import Colors from "../../../../Constants/Colors";
import TextStyles from "../../../../Constants/TextStyles";

import Styled from "./NotesStyles";
import AddNote from "./Components/AddNote";
import { updateUserTourSetupData } from "../../../../store/service/AuthService";

const notesStep = [
  {
    title: (
      <TextStyles.FontSize18px>Create your first note</TextStyles.FontSize18px>
    ),
    content: (
      <TextStyles.GreyFont14px className="d-flex text-start">
        After creating a note, you can see all your created notes in the note
        list.
      </TextStyles.GreyFont14px>
    ),
    target: ".note_tour",
    disableBeacon: true,
  },
];

const itemsPerPage = 10;

function MyNotes() {
  const [showAddNote, setShowAddNote] = useState(false);
  const [toggleListView, setToggleListView] = useState(true);

  const [showEditNote, setShowEditNote] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [page, setPage] = useState(1);

  const { notes, totalUserNote, sortingNotesData } = useSelector(
    (state) => state.notes
  );
  const dispatch = useDispatch();

  const selectedSortingOne =
    sortingNotesData &&
    sortingNotesData.selectedSortingOne &&
    sortingNotesData.selectedSortingOne;

  const selectedSortingTwo =
    sortingNotesData &&
    sortingNotesData.selectedSortingTwo &&
    sortingNotesData.selectedSortingTwo;

  const selectedNoteSortingOne =
    sortingNotesData &&
    sortingNotesData.selectedSortingOne &&
    sortingNotesData.selectedSortingOne;

  const selectedNoteSortingTwo =
    sortingNotesData &&
    sortingNotesData.selectedSortingTwo &&
    sortingNotesData.selectedSortingTwo;

  // sorting invoice
  const [openSorting, setOpenSorting] = useState(false);

  const hideSortingHandler = () => {
    setOpenSorting(false);
  };
  const handleOpenSortingChange = (newOpen) => {
    setOpenSorting(newOpen);
  };

  const handleSelectNoteId = (id) => {
    setSelectedId(id);
  };

  const handleDeleteNote = (id) => {
    setShowDeleteModal(true);

    setConfirmDialog({
      onConfirm: () =>
        dispatch(deleteNote(id)).then((data) => {
          if (data.meta.requestStatus === "fulfilled") {
            message.success("Note deleted successfully!");
          } else {
            message.error("Failed to delete note");
          }
        }),
    });
  };

  // Tour setup
  const tourData = useSelector((state) => state.auth.userTourSetupData);
  const [run, setRun] = useState(true);

  useEffect(() => {
    setRun(tourData && tourData.note_tour);
  }, [tourData]);

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
    }
    if ([EVENTS.TOUR_END, EVENTS.SKIP].includes(type)) {
      dispatch(updateUserTourSetupData({ note_tour: false }));
    }
  };

  const handlePageClick = (e, val) => {
    setPage(val);
  };

  useEffect(() => {
    dispatch(
      fetchNotesForUser({
        page,
        itemsPerPage,
        selectedSortingOne,
        selectedSortingTwo,
      })
    ).then((data) => {
      if (!data.meta.requestStatus === "fulfilled") {
        message.error("Failed to fetch notes");
      }
    });
  }, [page, notes.length, selectedSortingOne, selectedSortingTwo]);

  useEffect(() => {
    if (notes.length === 0 && page > 1) setPage(page - 1);
  }, [notes.length]);

  const sortingNotes = (e) => {
    dispatch(
      noteActions.sort({
        selectedSortingOne: e.target.value,
      })
    );
  };

  const sortingNotesOrder = (e) => {
    dispatch(
      noteActions.sort({
        selectedSortingTwo: e.target.value,
      })
    );
  };

  const notesSortingContent = () => {
    return (
      <TextStyles.FontSize14px>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="note-title">
            <AssignmentIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Title</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="note-title"
              type="radio"
              name="sorting"
              value="title"
              checked={selectedNoteSortingOne === "title"}
              onChange={sortingNotes}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="note-createdAt">
            <EventIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Create date</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="note-createdAt"
              type="radio"
              name="sorting"
              value="createdAt"
              checked={selectedNoteSortingOne === "createdAt"}
              onChange={sortingNotes}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="note-note">
            <DescriptionIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Note</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="note-note"
              type="radio"
              name="sorting"
              checked={selectedNoteSortingOne === "note"}
              value="note"
              onChange={sortingNotes}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="note-priority">
            <AdjustOutlinedIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Priority</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="note-priority"
              type="radio"
              name="sorting"
              checked={selectedNoteSortingOne === "priority"}
              value="priority"
              onChange={sortingNotes}
            />
          </div>
        </Styled.Sorting>

        <hr
          style={{
            borderColor: "f9f9f9",
            marginTop: 10,
            marginBottom: 10,
            borderTop: "none",
            opacity: 0.1,
          }}
        />

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="note-asc">
            <ArrowUpwardTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">Ascending (A-Z)</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="note-asc"
              type="radio"
              name="ordering"
              checked={selectedNoteSortingTwo === "asc"}
              value="asc"
              onChange={sortingNotesOrder}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="note-desc">
            <ArrowDownwardTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">Descending (Z-A)</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="note-desc"
              type="radio"
              name="ordering"
              checked={selectedNoteSortingTwo === "desc"}
              value="desc"
              onChange={sortingNotesOrder}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting className="mt-3">
          <TextStyles.GreyFont16px
            onClick={hideSortingHandler}
            style={{
              width: "100%",
              fontWeight: 500,
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            Done
          </TextStyles.GreyFont16px>
        </Styled.Sorting>
      </TextStyles.FontSize14px>
    );
  };

  return (
    <div className="mt-3">
      <div className="d-flex flex-wrap gap-3 justify-content-sm-between mb-4">
        <div>
          <TextStyles.FontSize20px className="font-weight-500">
            Notes
          </TextStyles.FontSize20px>
        </div>
        <div className="d-flex flex-wrap align-items-center justify-content-center gap-3">
          <TextStyles.HoverEffectSpan
            onClick={() => setToggleListView((view) => !view)}
          >
            {toggleListView ? "Grid View" : "List View"}
          </TextStyles.HoverEffectSpan>

          <TextStyles.FontSize16px>
            <Popover
              placement="bottomRight"
              content={notesSortingContent}
              trigger="click"
              open={openSorting}
              onOpenChange={handleOpenSortingChange}
            >
              <TextStyles.HoverEffectSpan sort={true}>
                Sort
                <ArrowDropDownOutlinedIcon
                  sx={{
                    color: Colors.font2,
                    fontSize: 27,
                    transform: openSorting ? "rotate(180deg)" : "",
                  }}
                />
              </TextStyles.HoverEffectSpan>
            </Popover>
          </TextStyles.FontSize16px>

          <Styled.NewCreate
            onClick={() => setShowAddNote(true)}
            className="note_tour"
          >
            <AddOutlinedIcon sx={{ fontSize: 20, color: Colors.white }} />
            <span className="ms-1 pe-2">New note</span>
          </Styled.NewCreate>
        </div>
      </div>

      {toggleListView ? (
        <NoteList
          setShowEditNote={setShowEditNote}
          handleSelectNoteId={handleSelectNoteId}
          handleDeleteNote={handleDeleteNote}
        />
      ) : (
        <NoteGrid
          setShowEditNote={setShowEditNote}
          handleSelectNoteId={handleSelectNoteId}
          handleDeleteNote={handleDeleteNote}
        />
      )}

      {totalUserNote > itemsPerPage && (
        <div className="d-flex justify-content-center py-4">
          <Pagination
            handlePageClick={handlePageClick}
            pageCount={Math.ceil(totalUserNote / itemsPerPage)}
            page={page}
          />
        </div>
      )}

      <Joyride
        steps={notesStep}
        run={run}
        disableBeacon
        continuous={true}
        showSkipButton={true}
        showProgress={true}
        scrollToFirstStep={false}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        styles={{
          options: {
            primaryColor: Colors.primary,
            textColor: Colors.font1,
            zIndex: 50,
            overlayColor: "rgba(0, 0, 0, 0.2)",
            spotlightShadow: "0 0 15px rgba(0, 0, 0, 0)",
          },
          beacon: {
            zIndex: 50,
            left: 50,
          },
          overlay: {
            zIndex: 50,
          },
          tooltipTitle: {
            fontSize: 18,
            margin: 0,
          },
          tooltipContent: {
            padding: "10px 0 0 0",
          },
        }}
        defaultStyles={{
          tooltipContent: {
            padding: "0px",
          },
        }}
      />

      {showAddNote && (
        <AddNote
          showAddNote={showAddNote}
          onCloseModal={() => setShowAddNote(false)}
        />
      )}

      {showEditNote && (
        <EditNote
          selectedId={selectedId}
          showEditNote={showEditNote}
          onCloseModal={() => setShowEditNote(false)}
        />
      )}

      {showDeleteModal && (
        <DeleteModal
          title="note"
          confirmDialog={confirmDialog}
          showDeleteModal={showDeleteModal}
          handleCloseDeleteModal={() => setShowDeleteModal(false)}
        />
      )}
    </div>
  );
}

export default MyNotes;
