import React from "react";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "./PageOne";
import Colors from "../../../../../../Constants/Colors";

const Client = (props) => {
  const selectedData = props && props.selectedData;
  const data = props && props.data;
  const businessInfoData = props && props.businessInfoData;
  const primaryContactData =
    selectedData &&
    selectedData.client_id &&
    selectedData.client_id.contacts &&
    selectedData.client_id.contacts.filter(
      (d) => d.email === (selectedData && selectedData.client_email)
    );
  let primaryContact;
  if (primaryContactData.length === 1) {
    primaryContact = primaryContactData && primaryContactData[0];
  } else if (primaryContactData.length > 1) {
    primaryContact =
      selectedData &&
      selectedData.client_id &&
      selectedData.client_id.contacts &&
      selectedData.client_id.contacts[0];
  } else {
    primaryContact = null;
  }

  const {
    street,
    suite,
    city,
    state,
    country,
    zip_code,
    tax_id_label,
    tax_id_number,
  } = (selectedData && selectedData.client_id) || {};

  const clientAddress = [street, suite, city, state, country, zip_code]
    .filter((elem) => elem)
    .join(", ");

  const {
    street: businessStreet,
    suite: businessSuite,
    city: businessCity,
    state: businessState,
    country: businessCountry,
    zip_code: businessZipcode,
    tax_id_label: businessTaxIDLabel,
    tax_id_number: businessTaxIDNumber,
  } = businessInfoData || {};

  const businessAddress = [
    businessStreet,
    businessSuite,
    businessCity,
    businessState,
    businessCountry,
    businessZipcode,
  ]
    .filter((elem) => elem)
    .join(", ");

  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <Styled.ReviewContentBox style={{ minHeight: 210 }} className="mt-4">
          <TextStyles.FontSize20px
            className="fw-medium"
            style={{ color: Colors.primary }}
          >
            Client
          </TextStyles.FontSize20px>
          <TextStyles.FontSize18px className="mt-2 fw-medium">
            {selectedData &&
              selectedData.client_id &&
              selectedData.client_id.company_name}
          </TextStyles.FontSize18px>

          <TextStyles.FontSize14px>
            {primaryContact && primaryContact.contact_name}
          </TextStyles.FontSize14px>

          <TextStyles.GreyFont14px className="mt-2">
            <span className="fw-medium">Email: </span>
            {primaryContact && primaryContact.email}
          </TextStyles.GreyFont14px>

          {clientAddress ? (
            <TextStyles.GreyFont14px>
              <span className="fw-medium">Address: </span>
              {clientAddress}
            </TextStyles.GreyFont14px>
          ) : (
            ""
          )}

          {tax_id_label ? (
            <TextStyles.GreyFont14px>
              <span className="fw-medium">Tax ID Label: </span>
              {tax_id_label}
            </TextStyles.GreyFont14px>
          ) : (
            ""
          )}

          {tax_id_number ? (
            <TextStyles.GreyFont14px>
              <span className="fw-medium">Tax ID Number: </span>
              {tax_id_number}
            </TextStyles.GreyFont14px>
          ) : (
            ""
          )}
        </Styled.ReviewContentBox>
      </div>
      <div className="col-12 col-md-6">
        <Styled.ReviewContentBox style={{ minHeight: 210 }} className="mt-4">
          <TextStyles.FontSize20px
            className="fw-medium"
            style={{ color: Colors.primary }}
          >
            Contractor
          </TextStyles.FontSize20px>
          <TextStyles.FontSize18px className="d-flex justify-content-start mt-2 fw-medium">
            {businessInfoData && businessInfoData.business_name
              ? businessInfoData.business_name
              : `${data.firstName} ${data.lastName}`}
          </TextStyles.FontSize18px>
          <TextStyles.GreyFont14px className="mt-2">
            <span className="fw-medium">Email: </span>
            {data && data.email}
          </TextStyles.GreyFont14px>

          {businessAddress ? (
            <TextStyles.GreyFont14px>
              <span className="fw-medium">Address: </span>
              {businessAddress}
            </TextStyles.GreyFont14px>
          ) : (
            ""
          )}

          {businessTaxIDLabel ? (
            <TextStyles.GreyFont14px>
              <span className="fw-medium">Tax ID Label: </span>
              {businessTaxIDLabel}
            </TextStyles.GreyFont14px>
          ) : (
            ""
          )}

          {businessTaxIDNumber ? (
            <TextStyles.GreyFont14px>
              <span className="fw-medium">Tax ID Number: </span>
              {businessTaxIDNumber}
            </TextStyles.GreyFont14px>
          ) : (
            ""
          )}
        </Styled.ReviewContentBox>
      </div>
    </div>
  );
};

export default Client;
