import styled from "styled-components";
import Colors from "../../../../../Constants/Colors";

const ReviewBox = styled.div`
  width: 100%;
  max-width: 50rem;
  height: auto;
  padding: 1.5rem;
  border-radius: 0.75rem;
  border: solid 1px #b2b6c5;
  background-color: ${Colors.white};
`;

const EditButtonToolTip = styled.div`
  display: ${(props) => (props.hover ? "block" : "none")};
  z-index: 105;
  position: absolute;
  width: 6.25rem;
  font-family: Poppins, serif;
  font-size: 0.875rem;
  cursor: pointer;
  color: ${(props) => props.color || Colors.white};
  background-color: ${(props) => props.backgroundColor || Colors.success};
  padding: 1rem;
  border-radius: 8px;
`;

const ContractSettingModal = styled.div`
  min-width: 36.25rem;
  max-width: 36.25rem;
  position: fixed;
  margin: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

const ArrowBox = styled.div`
  width: 1.875rem;
  height: 1.875rem;
  cursor: pointer;
  &:hover {
    border-radius: 6px;
    border: 1px solid #b2b6c5;
    background-color: ${Colors.tableHeadColor};
  }
`;

const inputBox = styled.div`
  border-radius: 0.75rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  margin-right: 3px;
  height: 3.437rem;
  border: 1px solid ${(props) => (props.invalid ? "red" : Colors.font2)};
  background-color: ${(props) => (props.invalid ? "#e7d5d5" : Colors.white)};
`;

export default {
  ReviewBox,
  EditButtonToolTip,
  ContractSettingModal,
  ArrowBox,
  inputBox,
};
