import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Styled from "./NoteListStyles";
import moment from "moment";

import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import noNotesImage from "../../../../../Assets/assetsnew/no-notes.svg";

import { ReactTooltip } from "../../../Commons/ReactTooltip";

import { noteActions } from "../../../../../store/storage/noteSlice";

import TextStyles from "../../../../../Constants/TextStyles";
import Colors from "../../../../../Constants/Colors";

function NoteList({ setShowEditNote, handleSelectNoteId, handleDeleteNote }) {
  const { isLoading, notes, sortingNotesData } = useSelector(
    (state) => state.notes
  );
  const dispatch = useDispatch();

  const selectedSortingOne =
    sortingNotesData &&
    sortingNotesData.selectedSortingOne &&
    sortingNotesData.selectedSortingOne;

  const selectedSortingTwo =
    sortingNotesData &&
    sortingNotesData.selectedSortingTwo &&
    sortingNotesData.selectedSortingTwo;

  const handleSortNotes = (type) => {
    dispatch(
      noteActions.sort({
        selectedSortingOne: type,
        selectedSortingTwo: selectedSortingTwo === "desc" ? "asc" : "desc",
      })
    );
  };

  return (
    <>
      {notes.length > 0 ? (
        <>
          <Styled.TableHead>
            <div
              className="three-dots"
              onClick={() => handleSortNotes("title")}
            >
              <ReactTooltip anchorId="title" content="Sort by title" />
              <span className="hover-title" id="title" role="button">
                <span>Title</span>
                <span>
                  {selectedSortingOne === "title" ? (
                    <>
                      {selectedSortingTwo === "desc" ? (
                        <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                      ) : (
                        <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                      )}
                    </>
                  ) : (
                    <>
                      {selectedSortingTwo === "asc" ? (
                        <ArrowUpwardRoundedIcon
                          sx={{
                            fontSize: "1.2rem",
                          }}
                          className="visible-title"
                        />
                      ) : (
                        <ArrowDownwardRoundedIcon
                          sx={{
                            fontSize: "1.2rem",
                          }}
                          className="visible-title"
                        />
                      )}
                    </>
                  )}
                </span>
              </span>
            </div>

            <div
              className="three-dots"
              onClick={() => handleSortNotes("createdAt")}
            >
              <ReactTooltip
                anchorId="createdAt"
                content="Sort by create date"
              />
              <span className="hover-createdAt" id="createdAt" role="button">
                <span>Create date</span>
                <span>
                  {selectedSortingOne === "createdAt" ? (
                    <>
                      {selectedSortingTwo === "desc" ? (
                        <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                      ) : (
                        <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                      )}
                    </>
                  ) : (
                    <>
                      {selectedSortingTwo === "asc" ? (
                        <ArrowUpwardRoundedIcon
                          sx={{
                            fontSize: "1.2rem",
                          }}
                          className="visible-createdAt"
                        />
                      ) : (
                        <ArrowDownwardRoundedIcon
                          sx={{
                            fontSize: "1.2rem",
                          }}
                          className="visible-createdAt"
                        />
                      )}
                    </>
                  )}
                </span>
              </span>
            </div>

            <div className="three-dots" onClick={() => handleSortNotes("note")}>
              <ReactTooltip anchorId="note" content="Sort by note" />
              <span className="hover-note" id="note" role="button">
                <span>Note</span>
                <span>
                  {selectedSortingOne === "note" ? (
                    <>
                      {selectedSortingTwo === "desc" ? (
                        <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                      ) : (
                        <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                      )}
                    </>
                  ) : (
                    <>
                      {selectedSortingTwo === "asc" ? (
                        <ArrowUpwardRoundedIcon
                          sx={{
                            fontSize: "1.2rem",
                          }}
                          className="visible-note"
                        />
                      ) : (
                        <ArrowDownwardRoundedIcon
                          sx={{
                            fontSize: "1.2rem",
                          }}
                          className="visible-note"
                        />
                      )}
                    </>
                  )}
                </span>
              </span>
            </div>

            <div
              className="three-dots"
              onClick={() => handleSortNotes("priority")}
            >
              <ReactTooltip anchorId="priority" content="Sort by priority" />
              <span className="hover-priority" id="priority" role="button">
                <span>Priority</span>
                <span>
                  {selectedSortingOne === "priority" ? (
                    <>
                      {selectedSortingTwo === "desc" ? (
                        <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                      ) : (
                        <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                      )}
                    </>
                  ) : (
                    <>
                      {selectedSortingTwo === "asc" ? (
                        <ArrowUpwardRoundedIcon
                          sx={{
                            fontSize: "1.2rem",
                          }}
                          className="visible-priority"
                        />
                      ) : (
                        <ArrowDownwardRoundedIcon
                          sx={{
                            fontSize: "1.2rem",
                          }}
                          className="visible-priority"
                        />
                      )}
                    </>
                  )}
                </span>
              </span>
            </div>

            <div></div>
          </Styled.TableHead>

          <ul className="p-0 m-0">
            {notes.map((note, i) => (
              <Styled.TableRow
                className={`${isLoading ? "pe-none" : "pe-auto"}`}
                key={i}
                onClick={(e) => {
                  e.preventDefault();
                  handleSelectNoteId(i);
                  setShowEditNote(true);
                }}
                style={isLoading ? { opacity: 0.5, cursor: "not-allowed" } : {}}
              >
                <p className="three-dots">{note.title}</p>

                <p className="three-dots">
                  {moment(note.createdAt).format("DD MMM YYYY")}
                </p>

                <p className="three-dots">{note.note}</p>

                <p
                  className={`three-dots text-capitalize fw-medium ${
                    note.priority === "high"
                      ? "text-danger"
                      : note.priority === "low"
                      ? "text-secondary"
                      : "text-success"
                  }`}
                >
                  {note.priority}
                </p>

                <div className="d-flex gap-3">
                  <span className="d-none d-sm-inline-block" id={`edit-${i}`}>
                    <ReactTooltip anchorId={`edit-${i}`} content="Edit note" />
                    <EditIcon
                      sx={{
                        color: Colors.frontendFont2,
                        cursor: "pointer",
                        fontSize: 30,
                        background: Colors.white,
                        padding: "2px 6px",
                        borderRadius: "6px",
                        width: 35,
                        height: 30,
                      }}
                    />
                  </span>
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteNote(note._id);
                    }}
                    id={`delete-${i}`}
                  >
                    <ReactTooltip
                      anchorId={`delete-${i}`}
                      content="Delete note"
                    />
                    <DeleteForeverRoundedIcon
                      sx={{
                        color: Colors.frontendFont2,
                        cursor: "pointer",
                        fontSize: 30,
                        background: Colors.white,
                        padding: "2px 2px",
                        borderRadius: "6px",
                        width: 35,
                        height: 30,
                      }}
                    />
                  </span>
                </div>
              </Styled.TableRow>
            ))}
          </ul>
        </>
      ) : (
        <Styled.ListBox>
          <img src={noNotesImage} alt="no-notes-img" />
          <TextStyles.GreyFont18px className="mt-2">
            There are no notes created yet.
          </TextStyles.GreyFont18px>
        </Styled.ListBox>
      )}
    </>
  );
}

export default NoteList;
