import React from "react";
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ListAltIcon from "@mui/icons-material/ListAlt";
// import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import Colors from "../../../Constants/Colors";

const useStyles = makeStyles({
  root: {
    border: `1px solid ${Colors.borderInput} !important`,
    backgroundColor: Colors.white,
    fontWeight: "400 !important",
    fontFamily: "Poppins !important",
    fontSize: "0.875rem !important",
    height: "2.75rem !important",
    "&:hover": {
      border: `1px solid ${Colors.primary} !important`,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },

  select: {
    display: "flex !important",
    justifyContent: "start",
    alignItems: "center !important",
  },

  listMenu: {
    color: Colors.font1,
    fontWeight: "400 !important",
    marginBottom: "2px !important",
    fontSize: "0.875rem !important",
    fontFamily: "Poppins !important",
    "&:hover": {
      backgroundColor: `${Colors.backgroundPink} !important`,
      border: "none",
    },
    '&[aria-selected="true"]': {
      backgroundColor: `${Colors.backgroundPink} !important`,
    },
  },
});

export default function ReminderStatus(props) {
  const classes = useStyles();

  return (
    <div>
      <Select
        classes={{
          select: classes.select,
        }}
        className={classes.root}
        value={props.defaultValue ? props.defaultValue : "todo"}
        variant="outlined"
        onChange={props.onChange}
        sx={{ minWidth: 140 }}
        MenuProps={{
          PaperProps: { sx: { maxHeight: 250 } },
        }}
      >
        <MenuItem
          value={"todo"}
          classes={{
            root: classes.listMenu,
          }}
        >
          <ListAltIcon
            sx={{
              color: Colors.primary,
              fontSize: 20,
              margin: "0 6px 0 0",
            }}
          />
          To do
        </MenuItem>
        {/* <MenuItem
          value={"future"}
          classes={{
            root: classes.listMenu,
          }}
        >
          <AccessTimeIcon
            sx={{
              color: Colors.primary,
              fontSize: 20,
              margin: "0 6px 0 0",
            }}
          />
          Postpone
        </MenuItem> */}
        <MenuItem
          value={"done"}
          classes={{
            root: classes.listMenu,
          }}
        >
          <TaskAltIcon
            sx={{
              color: Colors.primary,
              fontSize: 20,
              margin: "0 6px 0 0",
            }}
          />
          Completed
        </MenuItem>
      </Select>
    </div>
  );
}
