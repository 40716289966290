import React from "react";
import moment from "moment";
import clientBtn from "../../../../../Assets/assetsnew/image-client-new.svg";
import Services from "./Services/Services.jsx";
import BillingSchedules from "./BillingSchedules/BillingSchedules.jsx";
import Signature from "./Signatures/Signature.jsx";

import Text from "./AddSection/Text/Text";
import Image from "./AddSection/Image/Image";
import Table from "./AddSection/Table/Table";
import Column from "./AddSection/Column/Column";
import File from "./AddSection/File/File";

import Styled from "./ReviewStyles";
import TextStyles from "../../../../../Constants/TextStyles";
import Colors from "../../../../../Constants/Colors";

import { diffDate } from "../.././../Commons/utils";
const Review = (props) => {
  const selectedData = props && props.selectedData;
  const data = props && props.data;
  const businessInfoData = props && props.businessInfoData;
  const brandAndLogoData = props && props.brandAndLogoData;

  const primaryContactData =
    selectedData &&
    selectedData.client_id &&
    selectedData.client_id.contacts &&
    selectedData.client_id.contacts.filter(
      (d) => d.email === (selectedData && selectedData.client_email)
    );
  let primaryContact;
  if (primaryContactData.length === 1) {
    primaryContact = primaryContactData && primaryContactData[0];
  } else if (primaryContactData.length > 1) {
    primaryContact =
      selectedData &&
      selectedData.client_id &&
      selectedData.client_id.contacts &&
      selectedData.client_id.contacts[0];
  } else {
    primaryContact = null;
  }

  const {
    street,
    suite,
    city,
    state,
    country,
    zip_code,
    tax_id_label,
    tax_id_number,
  } = (selectedData && selectedData.client_id) || {};

  const clientAddress = [street, suite, city, state, country, zip_code]
    .filter((elem) => elem)
    .join(", ");

  const {
    street: businessStreet,
    suite: businessSuite,
    city: businessCity,
    state: businessState,
    country: businessCountry,
    zip_code: businessZipcode,
    tax_id_label: businessTaxIDLabel,
    tax_id_number: businessTaxIDNumber,
  } = businessInfoData || {};

  const businessAddress = [
    businessStreet,
    businessSuite,
    businessCity,
    businessState,
    businessCountry,
    businessZipcode,
  ]
    .filter((elem) => elem)
    .join(", ");

  return (
    <div className="px-lg-4 px-md-4 px-sm-3 px-2">
      <div className="d-flex justify-content-center w-100">
        <div style={{ width: "100%", maxWidth: 700 }}>
          <Styled.ReviewContainer
            style={{
              borderTop: `4px solid ${
                brandAndLogoData && brandAndLogoData.brand_color
                  ? brandAndLogoData.brand_color
                  : Colors.primary
              }`,
            }}
          >
            {brandAndLogoData &&
              brandAndLogoData.brand_logo &&
              brandAndLogoData.brand_logo.url && (
                <Styled.BrandLogoBox
                  logoAlignment={
                    brandAndLogoData &&
                    brandAndLogoData.brand_logo &&
                    brandAndLogoData.brand_logo.url
                      ? brandAndLogoData.logo_alignment
                      : "center"
                  }
                >
                  <img
                    src={brandAndLogoData.brand_logo.url}
                    alt="logoImg"
                    style={{ maxHeight: 100 }}
                  />
                </Styled.BrandLogoBox>
              )}

            <Styled.ReviewBox className="mt-4">
              <div>
                <div className="d-flex gap-3 align-items-center">
                  <div style={{ maxWidth: 50 }}>
                    <img className="w-100" src={clientBtn} alt="clientBtn" />
                  </div>

                  <TextStyles.FontSize20px className="three-dots fw-medium">
                    {selectedData && selectedData.project_name}
                  </TextStyles.FontSize20px>
                </div>

                <div className="d-flex gap-2 justify-content-start align-items-center mt-4">
                  <TextStyles.FontSize16px className="fw-medium">
                    Starts:
                  </TextStyles.FontSize16px>

                  <TextStyles.GreyFont14px
                    className="d-flex"
                    style={{ minWidth: 50 }}
                  >
                    <span className="three-dots">
                      {moment(
                        selectedData && selectedData.project_start_date
                      ).format("DD MMM YYYY")}{" "}
                    </span>
                  </TextStyles.GreyFont14px>
                </div>

                {selectedData && selectedData.project_end_date ? (
                  <div className="d-flex gap-2 justify-content-start align-items-center mt-2">
                    <TextStyles.FontSize16px className="fw-medium">
                      Ends:
                    </TextStyles.FontSize16px>
                    <TextStyles.GreyFont14px
                      className="d-flex"
                      style={{ minWidth: 50 }}
                    >
                      <span className="three-dots">
                        {moment(selectedData.project_end_date).format(
                          "DD MMM YYYY"
                        )}
                      </span>
                    </TextStyles.GreyFont14px>
                  </div>
                ) : (
                  <div className="d-flex gap-2 justify-content-start align-items-center mt-2">
                    <TextStyles.FontSize16px className="fw-medium">
                      Ends:
                    </TextStyles.FontSize16px>
                    <TextStyles.GreyFont14px>On going</TextStyles.GreyFont14px>
                  </div>
                )}

                {selectedData &&
                  selectedData.project_end_date &&
                  selectedData.project_start_date && (
                    <div className="d-flex gap-2 justify-content-start align-items-center mt-2">
                      <TextStyles.FontSize16px className="fw-medium">
                        Duration:
                      </TextStyles.FontSize16px>
                      <TextStyles.GreyFont14px
                        className="d-flex"
                        style={{ minWidth: 30 }}
                      >
                        <span className="three-dots">
                          {diffDate(
                            selectedData.project_end_date,
                            selectedData.project_start_date
                          )}
                        </span>
                      </TextStyles.GreyFont14px>
                    </div>
                  )}
              </div>

              <div
                className="my-3"
                style={{
                  width: "100%",
                  height: "0.5px",
                  backgroundColor: "#a9abb3",
                }}
              />
              <div className="row">
                <div className="col-12 col-sm-6">
                  <TextStyles.GreyFont18px
                    className="fw-medium"
                    style={{ color: Colors.primary }}
                  >
                    To
                  </TextStyles.GreyFont18px>
                  <TextStyles.FontSize18px className="d-flex justify-content-start mt-2 fw-medium">
                    {selectedData &&
                      selectedData.client_id &&
                      selectedData.client_id.company_name}
                  </TextStyles.FontSize18px>

                  <TextStyles.FontSize14px>
                    {primaryContact && primaryContact.contact_name}
                  </TextStyles.FontSize14px>

                  <TextStyles.GreyFont14px className="mt-2">
                    <span className="fw-medium">Email: </span>
                    {primaryContact && primaryContact.email}
                  </TextStyles.GreyFont14px>

                  {clientAddress ? (
                    <TextStyles.GreyFont14px>
                      <span className="fw-medium">Address: </span>
                      {clientAddress}
                    </TextStyles.GreyFont14px>
                  ) : (
                    ""
                  )}

                  {tax_id_label ? (
                    <TextStyles.GreyFont14px>
                      <span className="fw-medium">Tax ID Label: </span>
                      {tax_id_label}
                    </TextStyles.GreyFont14px>
                  ) : (
                    ""
                  )}

                  {tax_id_number ? (
                    <TextStyles.GreyFont14px>
                      <span className="fw-medium">Tax ID Number: </span>
                      {tax_id_number}
                    </TextStyles.GreyFont14px>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className="d-flex d-sm-none my-3"
                  style={{
                    width: "100%",
                    height: "1px",
                    backgroundColor: "#a9abb3",
                  }}
                />
                <div className="col-12 col-sm-6">
                  <TextStyles.GreyFont18px
                    className="fw-medium"
                    style={{ color: Colors.primary }}
                  >
                    From
                  </TextStyles.GreyFont18px>
                  <TextStyles.FontSize18px className="d-flex justify-content-start mt-2 fw-medium">
                    {businessInfoData &&
                    businessInfoData.business_name !== undefined
                      ? businessInfoData.business_name
                      : data && `${data.firstName} ${data.lastName}`}
                  </TextStyles.FontSize18px>

                  <TextStyles.GreyFont14px className="mt-2">
                    <span className="fw-medium">Email: </span>
                    {data && data.email}
                  </TextStyles.GreyFont14px>

                  {businessAddress ? (
                    <TextStyles.GreyFont14px>
                      <span className="fw-medium">Address: </span>
                      {businessAddress}
                    </TextStyles.GreyFont14px>
                  ) : (
                    ""
                  )}

                  {businessTaxIDLabel ? (
                    <TextStyles.GreyFont14px>
                      <span className="fw-medium">Tax ID Label: </span>
                      {businessTaxIDLabel}
                    </TextStyles.GreyFont14px>
                  ) : (
                    ""
                  )}

                  {businessTaxIDNumber ? (
                    <TextStyles.GreyFont14px>
                      <span className="fw-medium">Tax ID Number: </span>
                      {businessTaxIDNumber}
                    </TextStyles.GreyFont14px>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Styled.ReviewBox>

            {selectedData &&
              selectedData.add_section_text_one &&
              selectedData.add_section_text_one.map((data, index) => {
                return (
                  <Text
                    key={index}
                    data={data}
                    pc_id={selectedData._id}
                    position="one"
                  />
                );
              })}

            {selectedData &&
              selectedData.add_section_image_one &&
              selectedData.add_section_image_one.map((data, index) => {
                return (
                  <Image
                    key={index}
                    data={data}
                    position="one"
                    pc_id={selectedData._id}
                  />
                );
              })}

            {selectedData &&
              selectedData.add_section_table_one &&
              selectedData.add_section_table_one.map((data, index) => {
                return (
                  <Table
                    key={index}
                    data={data}
                    position="one"
                    pc_id={selectedData._id}
                  />
                );
              })}

            {selectedData &&
              selectedData.add_section_column_one &&
              selectedData.add_section_column_one.map((data, index) => {
                return (
                  <Column
                    key={index}
                    data={data}
                    position="one"
                    pc_id={selectedData._id}
                  />
                );
              })}

            {selectedData &&
              selectedData.add_section_file_one &&
              selectedData.add_section_file_one.map((data, index) => {
                return (
                  <File
                    key={index}
                    data={data}
                    position="one"
                    pc_id={selectedData._id}
                  />
                );
              })}

            <Services selectedData={selectedData} />
            <BillingSchedules selectedData={selectedData} />

            {selectedData &&
              selectedData.add_section_text_two &&
              selectedData.add_section_text_two.map((data, index) => {
                return (
                  <Text
                    key={index}
                    data={data}
                    position="two"
                    pc_id={selectedData._id}
                  />
                );
              })}

            {selectedData &&
              selectedData.add_section_image_two &&
              selectedData.add_section_image_two.map((data, index) => {
                return (
                  <Image
                    key={index}
                    data={data}
                    position="two"
                    pc_id={selectedData._id}
                  />
                );
              })}

            {selectedData &&
              selectedData.add_section_table_two &&
              selectedData.add_section_table_two.map((data, index) => {
                return (
                  <Table
                    key={index}
                    data={data}
                    position="two"
                    pc_id={selectedData._id}
                  />
                );
              })}

            {selectedData &&
              selectedData.add_section_column_two &&
              selectedData.add_section_column_two.map((data, index) => {
                return (
                  <Column
                    key={index}
                    data={data}
                    position="two"
                    pc_id={selectedData._id}
                  />
                );
              })}

            {selectedData &&
              selectedData.add_section_file_two &&
              selectedData.add_section_file_two.map((data, index) => {
                return (
                  <File
                    key={index}
                    data={data}
                    position="two"
                    pc_id={selectedData._id}
                  />
                );
              })}
            <Signature
              selectedData={selectedData}
              data={data}
              businessInfoData={businessInfoData}
              primaryContact={primaryContact}
            />
          </Styled.ReviewContainer>
        </div>
      </div>
    </div>
  );
};

export default Review;
