import React from "react";
import { useSelector } from "react-redux";
import Styled from "./NoteGridStyles";

import noNotesImage from "../../../../../Assets/assetsnew/no-notes.svg";

import TextStyles from "../../../../../Constants/TextStyles";

const MAX_NOTE_LENGTH = 200;
const ONE_LINE_NOTE_LENGTH = 30;

function NoteGrid({ setShowEditNote, handleSelectNoteId, handleDeleteNote }) {
  const { isLoading, notes } = useSelector((state) => state.notes);

  return (
    <Styled.NoteGridStyled>
      {notes.length > 0 ? (
        notes.map(({ _id, title, note, priority }, i) => (
          <Styled.NoteItem
            className={`${isLoading ? "pe-none" : "pe-auto"}`}
            key={i}
            onClick={(e) => {
              e.preventDefault();
              handleSelectNoteId(i);
              setShowEditNote(true);
            }}
            style={isLoading ? { opacity: 0.5, cursor: "not-allowed" } : {}}
          >
            <span
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteNote(_id);
              }}
              style={{
                position: "absolute",
                top: 4,
                right: 10,
                fontSize: 24,
              }}
            >
              &times;
            </span>

            <p className="fw-medium mb-2 three-dots" style={{ fontSize: 16 }}>
              {title}
            </p>

            <p
              className={`mb-2 lh-lg ${
                note.length > ONE_LINE_NOTE_LENGTH && !note.includes(" ")
                  ? "three-dots"
                  : "overflow-hidden"
              }`}
            >
              {note.length > MAX_NOTE_LENGTH
                ? `${note.slice(0, MAX_NOTE_LENGTH)}...`
                : note}
            </p>

            <p
              className={`three-dots text-capitalize fw-medium ${
                priority === "high"
                  ? "text-danger"
                  : priority === "low"
                  ? "text-secondary"
                  : "text-success"
              }`}
            >
              {priority}
            </p>
          </Styled.NoteItem>
        ))
      ) : (
        <Styled.ListBox>
          <img src={noNotesImage} alt="no-notes-img" />
          <TextStyles.GreyFont18px className="mt-2">
            There are no notes created yet.
          </TextStyles.GreyFont18px>
        </Styled.ListBox>
      )}
    </Styled.NoteGridStyled>
  );
}

export default NoteGrid;
