import React from "react";
import styled, { keyframes } from "styled-components";
import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";

// Keyframes for fade-in and fade-out
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

// Styled component for animating quotes
const QuoteContainer = styled.div`
  animation: ${({ isFadingOut }) => (isFadingOut ? fadeOut : fadeIn)} 1s
    ease-in-out;
  opacity: ${({ isFadingOut }) => (isFadingOut ? 0 : 1)};
  transition: opacity 0.5s ease-in-out;
`;

const DashboardItemBox = styled.div`
  width: 100%;
  padding: 45px 30px;
  border-radius: 12px;
  box-shadow: 0 8px 12px 0 rgba(238, 241, 245, 0.89);
  background-color: ${Colors.white};

  .content {
    font-size: 24px;
    text-align: center;
    font-style: italic;
  }

  .founder {
    font-size: 20px;
    text-align: center;
    font-weight: 500;
    color: ${Colors.primary};
  }

  .company {
    font-size: 18px;
    text-align: center;
  }

  // Media query for tablets
  @media (max-width: 768px) {
    padding: 30px 15px;

    .content {
      font-size: 22px;
    }

    .founder {
      font-size: 18px;
    }

    .company {
      font-size: 16px;
    }
  }

  // Media query for mobile devices
  @media (max-width: 480px) {
    padding: 20px 10px;

    .content {
      font-size: 20px;
    }

    .founder {
      font-size: 17px;
    }

    .company {
      font-size: 15px;
    }
  }
`;

const quotes = [
  {
    id: "1",
    content:
      "Your time is limited, so don't waste it living someone else's life.",
    founder: "Steve Jobs",
    company: "Co-Founder of Apple Inc.",
  },
  {
    id: "2",
    content:
      "Build your own dreams, or someone else will hire you to build theirs.",
    founder: "Farrah Gray",
    company: "Entrepreneur and Author",
  },
  {
    id: "3",
    content: "Don't worry about failure. You only have to be right once.",
    founder: "Drew Houston",
    company: "Co-Founder and CEO of Dropbox",
  },
  {
    id: "4",
    content: "Growth and comfort do not coexist.",
    founder: "Ginni Rometty",
    company: "Former CEO of IBM",
  },
  {
    id: "5",
    content: "Control your own destiny or someone else will.",
    founder: "Jack Welch",
    company: "Former CEO of General Electric",
  },
  {
    id: "6",
    content:
      "A small business is an amazing way to serve and leave an impact on the world you live in.",
    founder: "Nicole Snow",
    company: "Entrepreneur and Founder of Darn Good Yarn",
  },
  {
    id: "7",
    content:
      "I knew that if I failed, I wouldn't regret that. But I knew the one thing I might regret is not trying.",
    founder: "Jeff Bezos",
    company: "Founder of Amazon",
  },
  {
    id: "8",
    content:
      "Timing, perseverance, and ten years of trying will eventually make you look like an overnight success.",
    founder: "Biz Stone",
    company: "Co-Founder of Twitter",
  },
  {
    id: "9",
    content:
      "It is not necessary to do extraordinary things to get extraordinary results.",
    founder: "Warren Buffett",
    company: "CEO of Berkshire Hathaway",
  },
  {
    id: "10",
    content:
      "Chase the vision, not the money; the money will end up following you.",
    founder: "Tony Hsieh",
    company: "Former CEO of Zappos",
  },
  {
    id: "11",
    content:
      "You don't need to have a 100-person company to develop that idea.",
    founder: "Larry Page",
    company: "Co-Founder of Google",
  },
  {
    id: "12",
    content:
      "Do what you love and success will follow. Passion is the fuel behind a successful career.",
    founder: "Meg Whitman",
    company: "Former CEO of eBay",
  },
  {
    id: "13",
    content:
      "If you do what you've always done – you'll get what you've always gotten.",
    founder: "Tony Robbins",
    company: "Motivational Speaker and Author",
  },
  {
    id: "14",
    content:
      "It's better to hang out with people better than you. Pick out associates whose behavior is better than yours, and you'll drift in that direction.",
    founder: "Warren Buffett",
    company: "CEO of Berkshire Hathaway",
  },
  {
    id: "15",
    content:
      "All humans are entrepreneurs not because they should start companies but because the will to create is encoded in human DNA.",
    founder: "Reid Hoffman",
    company: "Co-Founder of LinkedIn",
  },
  {
    id: "16",
    content:
      "I'm convinced that about half of what separates the successful entrepreneurs from the non-successful ones is pure perseverance.",
    founder: "Steve Jobs",
    company: "Co-Founder of Apple Inc.",
  },
  {
    id: "1",
    content:
      "Your time is limited, so don't waste it living someone else's life.",
    founder: "Steve Jobs",
    company: "Co-Founder of Apple Inc.",
  },
  {
    id: "2",
    content:
      "Build your own dreams, or someone else will hire you to build theirs.",
    founder: "Farrah Gray",
    company: "Entrepreneur and Author",
  },
  {
    id: "3",
    content: "Don't worry about failure. You only have to be right once.",
    founder: "Drew Houston",
    company: "Co-Founder and CEO of Dropbox",
  },
  {
    id: "4",
    content: "Growth and comfort do not coexist.",
    founder: "Ginni Rometty",
    company: "Former CEO of IBM",
  },
  {
    id: "5",
    content: "Control your own destiny or someone else will.",
    founder: "Jack Welch",
    company: "Former CEO of General Electric",
  },
  {
    id: "6",
    content:
      "A small business is an amazing way to serve and leave an impact on the world you live in.",
    founder: "Nicole Snow",
    company: "Entrepreneur and Founder of Darn Good Yarn",
  },
  {
    id: "7",
    content:
      "I knew that if I failed, I wouldn't regret that. But I knew the one thing I might regret is not trying.",
    founder: "Jeff Bezos",
    company: "Founder of Amazon",
  },
  {
    id: "8",
    content:
      "Timing, perseverance, and ten years of trying will eventually make you look like an overnight success.",
    founder: "Biz Stone",
    company: "Co-Founder of Twitter",
  },
  {
    id: "9",
    content:
      "It is not necessary to do extraordinary things to get extraordinary results.",
    founder: "Warren Buffett",
    company: "CEO of Berkshire Hathaway",
  },
  {
    id: "10",
    content:
      "Chase the vision, not the money; the money will end up following you.",
    founder: "Tony Hsieh",
    company: "Former CEO of Zappos",
  },
  {
    id: "11",
    content:
      "You don't need to have a 100-person company to develop that idea.",
    founder: "Larry Page",
    company: "Co-Founder of Google",
  },
  {
    id: "12",
    content:
      "Do what you love and success will follow. Passion is the fuel behind a successful career.",
    founder: "Meg Whitman",
    company: "Former CEO of eBay",
  },
  {
    id: "13",
    content:
      "If you do what you've always done – you'll get what you've always gotten.",
    founder: "Tony Robbins",
    company: "Motivational Speaker and Author",
  },
  {
    id: "14",
    content:
      "It's better to hang out with people better than you. Pick out associates whose behavior is better than yours, and you'll drift in that direction.",
    founder: "Warren Buffett",
    company: "CEO of Berkshire Hathaway",
  },
  {
    id: "15",
    content:
      "All humans are entrepreneurs not because they should start companies but because the will to create is encoded in human DNA.",
    founder: "Reid Hoffman",
    company: "Co-Founder of LinkedIn",
  },
];

function Quotes() {
  const currentIndex = new Date().getDate();
  const currentQuote = quotes[currentIndex - 1];

  return (
    <div className="mx-lg-4 mx-md-4 mx-sm-3 mx-2 mt-3 mt-lg-1">
      <div className="d-flex justify-content-center align-items-center w-100">
        <DashboardItemBox>
          <QuoteContainer>
            <TextStyles.FontSize20px className="mb-3 content">
              “{currentQuote && currentQuote.content}”
            </TextStyles.FontSize20px>

            <TextStyles.FontSize16px className="mb-2 founder">
              {currentQuote && currentQuote.founder}
            </TextStyles.FontSize16px>

            <TextStyles.GreyFont16px className="company">
              {currentQuote && currentQuote.company}
            </TextStyles.GreyFont16px>
          </QuoteContainer>
        </DashboardItemBox>
      </div>
    </div>
  );
}

export default Quotes;
