import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import { useDropzone } from "react-dropzone";

import LoaderSpin from "../../../../Commons/LoaderSpin";
import ClearIcon from "@mui/icons-material/Clear";
import fileBtn from "../../../../../../Assets/assetsnew/file-add-symbol-new.svg";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";

import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../AddSectionStyles";

import { updateAddSectionFilePC } from "../../../../../../store/service/proposalAndContractService";
import { getFileFromUrl } from "../../../../Commons/utils";
import Colors from "../../../../../../Constants/Colors";

const EditAddSectionFileModal = ({
  show,
  handleClose,
  editData,
  pc_id,
  position,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.proposalAndContract.isLoading);
  const [selectedImage, setSelectedImage] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileErrors, setfileErrors] = useState([]);
  const MAX_SIZE = 2000000;

  useEffect(() => {
    const filesData =
      editData &&
      editData.files &&
      editData.files.map(async (item) => {
        let filename = item.file_name;
        let indexofUnderscore = filename.indexOf("_");
        let file_name = filename.substring(indexofUnderscore + 1);
        let file = await getFileFromUrl(item.file_url, file_name);
        return file;
      });
    Promise.all(filesData)
      .then((results) => {
        setFiles(results);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [show, handleClose]);

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      file_title: editData && editData.file_title,
    },
  });

  const { getRootProps, getInputProps } = useDropzone({
    // accept: {
    //   "*": [],
    // },
    maxSize: MAX_SIZE,
    multiple: true,
    // maxFiles: 5,
    onDrop: (acceptedFiles, rejectedFiles) => {
      setFiles((prev) => [...prev, ...acceptedFiles]);
      setfileErrors(rejectedFiles.length > 0 ? rejectedFiles[0].errors : []);
    },
    onDragEnter: () => {
      setFiles([]);
      setfileErrors([]);
    },
  });

  const removeFile = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const imageErrors = {
    FILESIZE: "More than 2MB in size",
    FILETYPE: "Not an image file",
    MAXFILE: "Not more than 5 files",
  };

  const getErrorMessage = () => {
    switch (fileErrors[0].code) {
      case "file-invalid-type":
        return (
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
              color: "red",
            }}
          >
            {imageErrors.FILETYPE}
          </p>
        );
      case "file-too-large":
        return (
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
              color: "red",
            }}
          >
            {imageErrors.FILESIZE}
          </p>
        );
      case "too-many-files":
        return (
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
              color: "red",
            }}
          >
            {imageErrors.MAXFILE}
          </p>
        );
      default:
        return (
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
              color: "red",
            }}
          >
            File error
          </p>
        );
    }
  };

  const formsubmit = (data) => {
    let formData = new FormData();
    files.forEach((file, index) => {
      formData.append(`file${index}`, file);
    });
    formData.set(
      "deleteData",
      JSON.stringify(
        editData.files &&
          editData.files.map((d) => {
            return { file_name: d.file_name };
          })
      )
    );
    formData.append("_id", editData._id);
    formData.append("pc_id", pc_id);
    formData.append("file_title", data.file_title);
    formData.append("position", position);

    if (files.length > 0) {
      dispatch(updateAddSectionFilePC(formData)).then((data) => {
        if (data.meta.requestStatus === "fulfilled") {
          handleClose();
          setFiles([]);
          reset();
          setSelectedImage(false);
          message.success(data.payload.message);
        } else {
          message.error(`${data.error.message}!`);
        }
      });
    } else {
      setSelectedImage(true);
      message.error("Please select image before submit!");
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      contentClassName="ToMakeModalBorderRudius12px"
      scrollable={true}
      centered={true}
    >
      <form
        onSubmit={handleSubmit(formsubmit)}
        style={{ height: "100%" }}
        className="modal-content"
      >
        <Modal.Header
          className="d-flex justify-content-between align-items-center"
          style={{ borderBottom: "0px" }}
        >
          <TextStyles.FontSize20px>Edit File</TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </Modal.Header>

        <Modal.Body className="py-0">
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="file_title" className="mb-2">
              File title
            </label>

            <TextStyles.InputRectangle
              id="file_title"
              invalid={errors.file_title}
              type="text"
              placeholder="Write title"
              name="file_title"
              {...register("file_title", {
                required: "Title is required.",
                validate: (value) => {
                  let trimedLength = value.trim().length;
                  if (trimedLength === 0) {
                    return "Title is required.";
                  }
                  return true;
                },
              })}
            />
            {errors.file_title && (
              <TextStyles.InValidFeedback>
                {errors.file_title.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px className="mb-2">
            <label>Upload file</label>
          </TextStyles.FontSize14px>

          <Styled.ImageBox
            style={{
              cursor: "pointer",
            }}
            {...getRootProps()}
            fileErrors={
              (files.length < 1 && selectedImage) || fileErrors.length > 0
            }
          >
            <input {...getInputProps()} />

            <img
              src={fileBtn}
              alt="fileBtn"
              style={{ width: 48, height: 48 }}
            />
            <TextStyles.GreyFont14px className="mt-2 text-center">
              Drag an image here or
              <span style={{ color: Colors.primary }}> select a file </span>
              from your device.
            </TextStyles.GreyFont14px>
            <TextStyles.GreyFont14px>
              PNG, JPG, PDF, GIF, SVG (Max 2 MB)
            </TextStyles.GreyFont14px>

            {fileErrors.length > 0 && getErrorMessage()}
          </Styled.ImageBox>

          <TextStyles.FontSize14px className="mt-3">
            {files &&
              files.map((file, index) => {
                return (
                  <div
                    className="d-flex gap-1 gap-sm-2 gap-md-3 justify-content-between align-items-center"
                    key={index}
                    style={{
                      background: "#e5eaf0",
                      border: "1px solid #e9e9e9",
                      borderRadius: 12,
                      padding: "0 1rem",
                      height: "3.15rem",
                      marginBottom: "0.4rem",
                    }}
                  >
                    <div
                      className="d-flex justify-content-start align-items-center"
                      style={{ minWidth: 100 }}
                    >
                      <span className="three-dots">
                        <InsertDriveFileOutlinedIcon className="pe-2" />
                        {file.name} - {file.size / 1000000} MB
                      </span>
                    </div>
                    <div
                      className="d-flex gap-2 justify-content-end align-items-center"
                      style={{ minWidth: 30, maxWidth: 50 }}
                    >
                      <DeleteForeverRoundedIcon
                        onClick={() => {
                          removeFile(index);
                        }}
                        sx={{
                          color: Colors.font2,
                          fontSize: 22,
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </div>
                );
              })}
          </TextStyles.FontSize14px>
        </Modal.Body>

        <Modal.Footer
          className="p-3 border-0"
          style={{ display: "inline-block" }}
        >
          <div className="d-flex gap-3 justify-content-around align-items-center">
            <TextStyles.CancelButton onClick={handleClose}>
              Cancel
            </TextStyles.CancelButton>
            <TextStyles.Button
              onClick={() => setSelectedImage(true)}
              disabled={fileErrors.length > 0 || isLoading}
            >
              {isLoading ? <LoaderSpin /> : "Save"}
            </TextStyles.Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditAddSectionFileModal;
