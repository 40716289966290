import React from "react";
import TextStyles from "../../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";

const Acceptance = (props) => {
  const selectedData = props && props.selectedData;

  const heading =
    selectedData && selectedData.acceptance && selectedData.acceptance.heading
      ? selectedData &&
        selectedData.acceptance &&
        selectedData.acceptance.heading
      : "Acceptance";
  const paragraph =
    selectedData && selectedData.acceptance && selectedData.acceptance.paragraph
      ? selectedData &&
        selectedData.acceptance &&
        selectedData.acceptance.paragraph
      : "The Client's authorized representative possesses the authority to enter into this Agreement on behalf of the Client. The Client commits to cooperating and providing the Contractor with all necessary resources to complete the Services as requested, including the specified format and timeline. The Contractor affirms their expertise and capability to fulfill all agreed-upon tasks for the Client in a professional and timely manner. They will strive to meet all established deadlines and exceed the Client's expectations for the Services to the best of their abilities.";

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.acceptance &&
          selectedData.acceptance.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox className="mt-4">
        <TextStyles.FontSize20px className="mb-3 fw-medium">
          {heading}
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px className="lh-lg">{paragraph}</TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
    </div>
  );
};

export default Acceptance;
