import styled from "styled-components";
import Colors from "../../../../../../Constants/Colors";

const Text = styled.div`
  width: 100%;
  min-height: 3.15rem;
  overflow-wrap: anywhere;
  border: 1px solid #a9abb3;
  border-radius: 0.75rem;
  padding: 1rem;
  border: 1px solid ${(props) => (props.invalid ? "red" : "#e4e4e4")};
  background-color: ${(props) => (props.invalid ? "#e7d5d5" : Colors.white)};
  @media (max-width: 576px) {
    padding: 0.6rem;
  }
`;

const ReviewBox = styled.div`
  width: 100%;
  height: auto;
  padding: 1.5rem;
  border: 1px solid #a9abb3;
  border-radius: 0.75rem;
  @media (max-width: 576px) {
    padding: 1rem;
  }
`;

const ImageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 12.5rem;
  padding: 1.125rem;
  border-radius: 0.75rem;
  border: dashed 1px #a9abb3;

  @media (max-width: 576px) {
    padding: 0.5rem;
  }
`;

const FileDownloadContainer = styled.div`
  cursor: pointer;
  background: #e5eaf0;
  border: 1px solid #e9e9e9;
  border-radius: 0.75rem;
  padding: 1rem;
  margin: 0px 0px 6px 0px;
  font-weight: 500;
  color: ${Colors.primary};
  transition: all 0.3s ease;

  &:hover {
    text-decoration: underline 1.5px;
  }

  @media (max-width: 576px) {
    padding: 0.5rem;
  }
`;

export default {
  ReviewBox,
  ImageBox,
  Text,
  FileDownloadContainer,
};
