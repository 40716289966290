import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { message } from "antd";
import LoaderSpin from "../../../Commons/LoaderSpin";
import ClearIcon from "@mui/icons-material/Clear";
import TextStyles from "../../../../../Constants/TextStyles";
import { updateAddSexualHarassmentCluasePC } from "../../../../../store/service/proposalAndContractService";
import Colors from "../../../../../Constants/Colors";

const AppropriateConductModal = ({
  show,
  handleClose,
  heading,
  selectedData,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.proposalAndContract.isLoading);

  const paragraph =
    selectedData.add_sexual_harassment_clause &&
    selectedData.add_sexual_harassment_clause.paragraph
      ? selectedData.add_sexual_harassment_clause.paragraph
      : `Client and Contractor are committed to maintaining a professional relationship free from any form of harassment or offensive conduct. If Contractor experiences any harassing behavior from Client or Client's staff during the term of this Agreement, Contractor will promptly notify Client and request appropriate corrective action. If the reported behavior persists after Contractor's second notice, it will be considered a breach of this Agreement, allowing Contractor to terminate the Agreement in accordance with the Term and Termination Clause. Contractor will be entitled to receive full payment for the ordered project or planned monthly service hours, without prejudice to any other rights or remedies available to Contractor under the law.`;

  const { register, reset, handleSubmit } = useForm({
    defaultValues: {
      heading: heading,
      paragraph: paragraph,
    },
  });

  const formsubmit = (item) => {
    const Obj = {
      _id: selectedData && selectedData._id,
      add_sexual_harassment_clause: {
        required: true,
        heading: item.heading,
        paragraph: item.paragraph,
      },
    };

    dispatch(updateAddSexualHarassmentCluasePC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        reset();
        message.success("Clause updated successfully!");
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="ToMakeModalWidth600px"
      contentClassName="ToMakeModalBorderRudius12px"
      scrollable={true}
      centered={true}
    >
      <form
        onSubmit={handleSubmit(formsubmit)}
        style={{ height: "100%" }}
        className="modal-content"
      >
        <Modal.Header className="d-flex justify-content-between align-items-center">
          <TextStyles.FontSize20px>Edit contract</TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="headline" className="mb-2">
              Headline
            </label>
            <TextStyles.InputRectangle
              placeholder={heading}
              id="headline"
              name="heading"
              {...register("heading")}
            />
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="headline" className="mb-2">
              Paragraph
            </label>
            <TextStyles.Textarea
              rows={8}
              placeholder="Add text here"
              id="paragraph"
              name="paragraph"
              {...register("paragraph")}
            />
          </TextStyles.FontSize14px>
        </Modal.Body>
        <Modal.Footer style={{ display: "inline-block" }}>
          <div className="d-flex gap-3 justify-content-between">
            <TextStyles.CancelButton onClick={handleClose}>
              Cancel
            </TextStyles.CancelButton>
            <TextStyles.Button disabled={isLoading}>
              {isLoading ? <LoaderSpin /> : "Update"}
            </TextStyles.Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AppropriateConductModal;
