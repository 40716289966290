import React, { useState } from "react";
import { useSelector } from "react-redux";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

// for page one

import ContractSetting from "./ContactSetting.jsx";
import ProjectPageOne from "./PageOne/Project.jsx";
import ContractDateDetails from "./PageOne/ContractDateDetails.jsx";
import ClientAndContractor from "./PageOne/ClientAndContractor.jsx";
import RestOne from "./PageOne/RestOne.jsx";

// for page two

import ProjectPageTwo from "./PageTwo/Project.jsx";
import Services from "./PageTwo/Services.jsx";
import BillingSchedules from "./PageTwo/BillingSchedules.jsx";

// for page three

import PageThree from "./PageThree/PageThree.jsx";

// for page four

import PageFour from "./PageFour/PageFour.jsx";

// for page five
import PageFive from "./PageFive/PageFive.jsx";

// for page six
import PageSix from "./PageSix/PageSix.jsx";

import TextStyles from "../../../../Constants/TextStyles";
import Styled from "./ReviewStyles";

import SignProposal from "../ProposalReviews/Signatures/SignProposal";
import AddClauseModal from "./PageModals/AddClauseModal";
import AddClause from "./AddClause/AddClause.jsx";
import ListAddClause from "./AddClause/components/ListAddClause";
import Colors from "../../../../Constants/Colors.js";
import { useHistory, useLocation } from "react-router-dom";

const Review = () => {
  const history = useHistory();
  const location = useLocation();
  const pathname =
    location && location.pathname.split("/").slice(0, -1).join("/");

  const { selectedData } = useSelector((state) => state.proposalAndContract);
  const [showSetting, setShowSetting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showAddClauseModal, setShowAddClauseModal] = useState(false);

  const nextPageHandler = () => {
    if (
      (selectedData && selectedData.signature && selectedData.signature.text) ||
      (selectedData &&
        selectedData.signature &&
        selectedData.signature.image.image_name &&
        selectedData.signature.image.image_url)
    ) {
      history.push(`${pathname}/send`);
    } else {
      setShowModal(true);
    }
  };

  return (
    <div className="px-lg-4 px-md-4 px-sm-3 px-2">
      <div
        className="d-flex justify-content-center w-100"
        style={{ contain: "content" }}
      >
        <div
          style={{
            width: "100%",
            maxWidth: 800,
            backgroundColor: Colors.white,
            padding: "1.5rem",
            marginBottom: "1rem",
          }}
        >
          <Styled.ReviewBox className="mb-4">
            <div
              className="d-flex justify-content-end align-items-center gap-1"
              onClick={() => setShowSetting(true)}
              role="button"
            >
              <SettingsOutlinedIcon
                sx={{ color: Colors.font2, fontSize: 24 }}
              />
              <span style={{ color: Colors.font2 }}>Contract settings</span>
            </div>
            <div>
              <TextStyles.FontSize27px className="mt-2">
                Service contract
              </TextStyles.FontSize27px>
              <ProjectPageOne />
              <ContractDateDetails />
              <ClientAndContractor />
              <RestOne />
            </div>
          </Styled.ReviewBox>
          <Styled.ReviewBox className="mb-4">
              <TextStyles.FontSize27px
                className="d-flex w-100"
                style={{ minWidth: 100 }}
              >
                <span className="three-dots"> Attachment A : Services</span>
              </TextStyles.FontSize27px>
              <ProjectPageTwo />
              <Services />
              <BillingSchedules />
          </Styled.ReviewBox>
          <Styled.ReviewBox className="mb-4">
              <TextStyles.FontSize27px
                className="d-flex w-100"
                style={{ minWidth: 100 }}
              >
                <span className="three-dots">
                  Attachment B : Terms and Conditions
                </span>
              </TextStyles.FontSize27px>
              <PageThree />
          </Styled.ReviewBox>
          <Styled.ReviewBox className="mb-4">
              <PageFour />
          </Styled.ReviewBox>
          <Styled.ReviewBox className="mb-4">
              <PageFive />
          </Styled.ReviewBox>
          <Styled.ReviewBox className="mb-4">
              <PageSix />
          </Styled.ReviewBox>

          {selectedData &&
            selectedData.add_clause &&
            selectedData.add_clause.filter(
              (clause) => clause.required !== false
            ).length > 0 && (
              <Styled.ReviewBox className="mb-4">
                  {selectedData &&
                    selectedData.add_clause &&
                    selectedData.add_clause.map((data, index) => {
                      return (
                        <ListAddClause
                          key={index}
                          data={data}
                          pc_id={selectedData._id}
                        />
                      );
                    })}
              </Styled.ReviewBox>
            )}

            <div onClick={() => setShowAddClauseModal(true)}>
              <AddClause  />
            </div>
        </div>
      </div>
      <Styled.SubmitButton onClick={nextPageHandler}>
        Continue
      </Styled.SubmitButton>
      {showSetting && (
        <ContractSetting
          show={showSetting}
          handleClose={() => setShowSetting(false)}
          selectedData={selectedData}
        />
      )}
      {showModal && (
        <SignProposal
          show={showModal}
          handleClose={() => setShowModal(false)}
          selectedData={selectedData}
        />
      )}
      {showAddClauseModal && (
        <AddClauseModal
          selectedData={selectedData}
          show={showAddClauseModal}
          handleClose={() => setShowAddClauseModal(false)}
        />
      )}
    </div>
  );
};

export default Review;
