import React from "react";

import TextStyles from "../../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";

import Colors from "../../../../../../../Constants/Colors";

const ExpenseReimbursement = (props) => {
  const selectedData = props && props.selectedData;

  const heading = "Expense Reimbursement";

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.reimbursement &&
          selectedData.reimbursement.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox className="mt-4">
        <TextStyles.FontSize20px className="mb-3 fw-medium">
          {heading}
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px className="lh-lg">
          Client shall reimburse all reasonable expenses that have been
          pre-authorized in writing by the Client in advance. Payment for such
          expenses shall be made within
          <span
            className="mx-1"
            style={{ color: Colors.primary, fontWeight: 500 }}
          >
            {selectedData &&
              selectedData.payment_terms &&
              selectedData.payment_terms.payment_due}
          </span>
          of receipt of an itemized invoice. .
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
    </div>
  );
};

export default ExpenseReimbursement;
