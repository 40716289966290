import React from "react";
import TextStyles from "../../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";

const OwnershipRights = (props) => {
  const selectedData = props && props.selectedData;

  const heading =
    selectedData &&
    selectedData.ownership_right &&
    selectedData.ownership_right.heading
      ? selectedData &&
        selectedData.ownership_right &&
        selectedData.ownership_right.heading
      : "Ownership and Licenses";

  const paragraphMadeForHire =
    selectedData &&
    selectedData.ownership_right &&
    selectedData.ownership_right.paragraph &&
    selectedData.ownership_right.ownership === "Made for hire"
      ? selectedData &&
        selectedData.ownership_right &&
        selectedData.ownership_right.paragraph
      : `The Contractor agrees that all deliverables and materials created under this Agreement, including presentations, writings, ideas, concepts, designs, text, plans, and other materials, shall be treated as if they were a "work for hire" and shall be owned by the Client upon full payment of all fees due to the Contractor as per this Agreement. In the event that any material is not considered a "work made for hire," the Contractor hereby assigns all ownership, including any patent, copyright, trade secret, and other proprietary rights, title, and interest in such deliverables and materials to the Client. The Contractor agrees to execute any documents reasonably requested by the Client to facilitate the acquisition and protection of such rights. The Contractor acknowledges that they have no interest in any materials submitted to the Client, including any security interest therein. The Contractor hereby releases any interest they may have (if any) in such materials that could arise by operation of law. Unless otherwise agreed upon in writing and solely as necessary for the performance of this Agreement, the Contractor shall have no rights to license, sell, or use the deliverables or materials developed under this Agreement, or any part thereof.`;

  const paragraphLicensed =
    selectedData &&
    selectedData.ownership_right &&
    selectedData.ownership_right.paragraph &&
    selectedData.ownership_right.ownership === "Licensed"
      ? selectedData &&
        selectedData.ownership_right &&
        selectedData.ownership_right.paragraph
      : `As per this Agreement, the Client acknowledges that the Contractor shall retain full ownership rights to all deliverables and materials produced, encompassing presentations, writings, ideas, concepts, designs, text, plans, and any other materials developed while providing services for the Client. Whether created individually or jointly, on or off the Client's premises, during or after working hours, all ownership rights shall remain vested with the Contractor. Upon the Client's complete payment of all fees owed to the Contractor in accordance with this Agreement, the Contractor hereby grants the Client a license to use the work product. This license allows the Client to utilize the deliverables in the specified manner(s) without any requirement for attribution.
`;

  const paragraph =
    selectedData &&
    selectedData.ownership_right &&
    selectedData.ownership_right.ownership === "Licensed"
      ? paragraphLicensed
      : paragraphMadeForHire;

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.ownership_right &&
          selectedData.ownership_right.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox className="mt-4">
        <TextStyles.FontSize20px className="mb-3 fw-medium">
          {heading}
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px className="lh-lg">
          <div>{paragraph}</div>
          <div>
            {selectedData &&
            selectedData.ownership_right &&
            selectedData.ownership_right.ownership === "Licensed"
              ? selectedData.ownership_right.ownership_text
              : ""}
          </div>
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
    </div>
  );
};

export default OwnershipRights;
