import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { message } from "antd";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateCancellationFeePC } from "../../../../../../store/service/proposalAndContractService";
import CancellationFeeModal from "../../PageModals/CancellationFeeModal";
import Colors from "../../../../../../Constants/Colors";

const CancellationFee = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const heading = "Cancellation Fee";

  const isCancellationFeeRequired =
    selectedData &&
    selectedData.cancellation_fee &&
    selectedData.cancellation_fee.required
      ? true
      : false;

  const toggleCancellationFeeViewHandler = () => {
    const Obj = {
      _id: selectedData._id,
      cancellation_fee: {
        required: isCancellationFeeRequired ? false : true,
        cancellation_fee_deposit_require: isCancellationFeeRequired
          ? false
          : true,
        cancellation_fee_amount: isCancellationFeeRequired
          ? ""
          : selectedData.cancellation_fee.cancellation_fee_amount,
      },
    };
    dispatch(updateCancellationFeePC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        message.success(
          isCancellationFeeRequired
            ? "Clause hidden successfully!"
            : "Clause unhidden successfully!"
        );
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div>
      <Styled.ReviewContentBox className="mt-4">
        <div className="edit-delete-icons">
          <span onClick={toggleCancellationFeeViewHandler}>
            {isCancellationFeeRequired ? (
              <VisibilityOffOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            ) : (
              <VisibilityOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            )}
          </span>

          {isCancellationFeeRequired && (
            <BorderColorOutlinedIcon
              sx={{
                cursor: "pointer",
                color: Colors.font2,
                opacity: 0.8,
                marginLeft: 2,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={() => setShowModal(true)}
            />
          )}
        </div>

        <TextStyles.FontSize20px className="mb-3">
          <span
            className={`fw-medium ${
              isCancellationFeeRequired ? "" : "disabled"
            }`}
          >
            {heading}
          </span>
        </TextStyles.FontSize20px>

        <TextStyles.FontSize14px
          className={`lh-lg ${isCancellationFeeRequired ? "" : "disabled"}`}
        >
          If the Client terminates this Agreement earlier without cause,
          Contractor will charge a cancellation fee of
          <span
            className="ms-2"
            style={{ color: Colors.primary, fontWeight: 500 }}
          >
            {selectedData.currency.symbol}
            {parseFloat(
              selectedData.cancellation_fee.cancellation_fee_amount || 0
            ).toFixed(2)}
          </span>
          .
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
      {showModal && (
        <CancellationFeeModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          heading={heading}
          selectedData={selectedData}
        />
      )}
    </div>
  );
};

export default CancellationFee;
