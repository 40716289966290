import React, { useState, useEffect, useMemo } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";

import { useDispatch, useSelector } from "react-redux";

import {
  deleteTask,
  // fetchTasks,
  updateTaskStatus,
  editTaskDueDate,
  createQuickTask,
  updateOrderingOfTasks,
} from "../../../../../store/service/TaskService";

import { message } from "antd";

import NoTaskImage from "../../../../../Assets/assetsnew/no-task.svg";

import EditTask from "../../../TasksAndNotes/Tasks/Components/EditTask";

import DeleteModal from "../../../Commons/DeleteModal";

import Pagination from "../../../Commons/Pagination";

import Styled from "./TodoStyles";
import TextStyles from "../../../../../Constants/TextStyles";
import Colors from "../../../../../Constants/Colors";
import {
  createActivityList,
  fetchAllActivityLists,
  start_timer_activity,
} from "../../../../../store/service/timeTrackingService";
import { timeTrackingActions } from "../../../../../store/storage/timetrackingSlice";
import LoaderSpin from "../../../Commons/LoaderSpin";

import { postRequest } from "../../../../../axios/axios";
import URL from "../../../../../axios/constant";
import moment from "moment";
import TaskList from "../../../TasksAndNotes/Tasks/Components/TaskList";
import QuickTask from "../../../TasksAndNotes/Tasks/Components/QuickTask";

import {
  closestCorners,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { restrictToVerticalAxis } from "@dnd-kit/modifiers";

const Todo = ({
  showInput,
  setShowInput,
  selectedSortingOne,
  selectedSortingTwo,
}) => {
  const dispatch = useDispatch();
  const [selectedTaskData, setSelectedTaskData] = useState(null);
  const [isTaskLoading, setIsTaskLoading] = useState(false);
  const [taskData, setTaskData] = useState(null);
  const [taskTotalCount, setTaskTotalCount] = useState(null);

  const c_data_position = useSelector((state) => state.clients.c_data_position);
  const isLoading = useSelector((state) => state.tasks.isLoading);
  const isTTLoading = useSelector((state) => state.timeTracking.isLoading);

  const { register, reset, handleSubmit } = useForm();

  const defaultData = useMemo(() => {
    return (
      taskData &&
      taskData.map((item) => {
        const repeat_task_id = item.repeat_task_id;
        const _id = item._id;
        const due_date = item.due_date ? moment(item.due_date).toDate() : null;
        const hide_until_date = item.hide_until_date
          ? moment(item.hide_until_date).toDate()
          : null;
        const client_id = item.client_id ? item.client_id : null;
        const project_id = item.project_id ? item.project_id : null;
        const status = item.status;
        const task_name = item.task_name ? item.task_name : null;
        const repeat_task = item.repeat_task ? item.repeat_task : null;
        const createdAt = item.createdAt
          ? moment(item.createdAt).toDate()
          : null;

        return {
          _id,
          due_date,
          hide_until_date,
          client_id,
          project_id,
          status,
          task_name,
          repeat_task,
          repeat_task_id,
          createdAt,
        };
      })
    );
  }, [taskData]);

  const { control: control2, reset: reset2 } = useForm({
    defaultValues: {
      update_task: defaultData && defaultData,
    },
  });

  const { fields: fields2 } = useFieldArray({
    control: control2,
    name: "update_task",
  });

  useEffect(() => {
    reset2({
      update_task: defaultData && defaultData,
    });
  }, [taskData]);

  const RemoveInputHandler = (e) => {
    if (e.keyCode === 8 && e.target.value === "") {
      setShowInput(false);
    }
  };

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const [updateRefresh, setUpdateRefresh] = useState(false);

  //pagination
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    setPage(1);
  }, [itemsPerPage]);

  useEffect(() => {
    if (taskData && taskData.length === 0) {
      if (page > 1) {
        setPage(page - 1);
      }
    }
  }, [taskData]);

  // Invoke when user click to request another page.
  const handlePageClick = (event, value) => {
    setPage(value);
  };

  const fetchTaskHandler = async (data) => {
    const token = localStorage.getItem("token");
    setIsTaskLoading(true);
    postRequest(URL.FETCH_TASKS.url, token, data)
      .then((data) => {
        if (data.data.success) {
          setIsTaskLoading(false);
          setTaskData(data.data.data.data);
          setTaskTotalCount(data.data.data.taskTotalCount);
        }
      })
      .catch((err) => {
        setIsTaskLoading(false);
        if (err && err.response && err.response.data) {
          return message.error(`${err.response.data.message}`);
        }
        if (err.isAxiosError) {
          return message.error(`${err.message}`);
        }
      });
  };

  useEffect(() => {
    const Obj = {
      client_id: c_data_position._id,
      status: "todo",
      page: page,
      perPage: itemsPerPage,
      sortBy: selectedSortingOne,
      orderBy: selectedSortingTwo,
    };
    fetchTaskHandler(Obj);
    // dispatch(fetchTasks(Obj));
  }, [
    updateRefresh,
    itemsPerPage,
    page,
    selectedSortingOne,
    selectedSortingTwo,
  ]);

  console.log(taskData);

  const formsubmit = (data) => {
    const Obj = {
      assign_to_client_id: c_data_position._id,
      task_name: data.task_name,
    };
    dispatch(createQuickTask(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setTaskData([data.payload.data, ...taskData]);
        setUpdateRefresh(!updateRefresh);
        // setShowInput(false);
        reset();
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}`);
      }
    });
  };

  const doneTaskCallApi = (data) => {
    let Obj = {
      task_id: data._id,
      status: "done",
    };
    dispatch(updateTaskStatus(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
        message.success("Task completed successfully!");
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  const setDueDateApiCall = (newDate, data) => {
    const Obj = {
      _id: data._id,
      due_date: moment(newDate).toDate(),
    };
    dispatch(editTaskDueDate(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  const deleteTaskHandler = (data) => {
    const Obj = {
      task_id: data && data._id,
    };
    dispatch(deleteTask(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
        handleCloseDeleteModal();
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  const startTimerHandler = (data) => {
    const Obj = {
      client_id: c_data_position._id,
      activity_name: data.task_name,
      start_time: moment().toDate(),
    };

    dispatch(
      timeTrackingActions.addNewActivityList({ activity_name: data.task_name })
    );
    dispatch(timeTrackingActions.startTimer({ project_id: null, ...Obj }));
    dispatch(createActivityList({ activity_name: data.task_name })).then(
      (data) => {
        if (data.meta.requestStatus === "fulfilled") {
          dispatch(fetchAllActivityLists());
        }
      }
    );
    dispatch(start_timer_activity(Obj));
  };

  const onDragEndhandler = (event) => {
    const { active, over } = event;
    const items = fields2;

    if (over && active.id !== over.id) {
      const sourceIndex = items.findIndex((item) => item.id === active.id);
      const targetIndex = items.findIndex((item) => item.id === over.id);
      const data = arrayMove(items, sourceIndex, targetIndex);
      setTaskData(() => data);

      const updateTasks = data.map((data) => data._id);
      dispatch(updateOrderingOfTasks({ tasks: updateTasks })).then((data) => {
        if (data.meta.requestStatus === "fulfilled") {
          setUpdateRefresh(!updateRefresh);
          // message.success(`${data.payload.message}`);
        } else {
          message.error(`${data.error.message}!`);
        }
      });
    }
  };

  // mobile device
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <div>
      {showInput && (
        <QuickTask
          showInput={showInput}
          taskData={taskData}
          handleSubmit={handleSubmit}
          formsubmit={formsubmit}
          register={register}
          RemoveInputHandler={RemoveInputHandler}
          isLoading={isLoading}
        />
      )}

      <div className="position-relative w-100 h-100">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCorners}
          modifiers={[restrictToVerticalAxis]}
          onDragStart={({ active }) => {
            console.log("starting drag", active);
          }}
          onDragEnd={onDragEndhandler}
          // onDragCancel={() => {
          //   setActive(null);
          // }}
        >
          {" "}
          <SortableContext
            items={fields2 ? fields2 : []}
            strategy={verticalListSortingStrategy}
          >
            {fields2 && fields2.length > 0 ? (
              fields2.map((data, index) => {
                return (
                  <TaskList
                    key={index}
                    index={index}
                    data={data}
                    taskData={taskData}
                    filteredTaskStatus={"todo"}
                    doneTaskCallApi={doneTaskCallApi}
                    // todoTaskCallApi={todoTaskCallApi}
                    deleteTaskHandler={deleteTaskHandler}
                    startTimerHandler={startTimerHandler}
                    setDueDateApiCall={setDueDateApiCall}
                    setSelectedTaskData={setSelectedTaskData}
                    setShowDeleteModal={setShowDeleteModal}
                    setShow={setShow}
                    showInput={showInput}
                    isTTLoading={isTTLoading}
                    isLoading={isLoading}
                    control2={control2}
                    Controller={Controller}
                    setConfirmDialog={setConfirmDialog}
                  />
                );
              })
            ) : isTaskLoading && taskData === null ? (
              <Styled.ListBox>
                <LoaderSpin color={Colors.primary} />
              </Styled.ListBox>
            ) : (
              !isTaskLoading &&
              !showInput && (
                <Styled.ListBox>
                  <img src={NoTaskImage} alt="no-task-img" />
                  <TextStyles.GreyFont18px className="mt-2">
                    There are no tasks created yet.
                  </TextStyles.GreyFont18px>
                </Styled.ListBox>
              )
            )}
          </SortableContext>
        </DndContext>
      </div>

      <div className="d-flex justify-content-center pt-4">
        {taskTotalCount > itemsPerPage && (
          <Pagination
            handlePageClick={handlePageClick}
            pageCount={Math.ceil(taskTotalCount / itemsPerPage)}
            page={page}
          />
        )}
      </div>

      {show && (
        <EditTask
          setUpdateRefresh={setUpdateRefresh}
          updateRefresh={updateRefresh}
          selectedTaskData={selectedTaskData}
          handleClose={handleClose}
          show={show}
        />
      )}

      <DeleteModal
        title="task"
        confirmDialog={confirmDialog}
        showDeleteModal={showDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
      />
    </div>
  );
};

export default Todo;
