import React, { useState } from "react";
import imageBtn from "../../../../../Assets/assetsnew/image-new.svg";
import fileBtn from "../../../../../Assets/assetsnew/file-add-symbol-new.svg";
import textBtn from "../../../../../Assets/assetsnew/text-image-new.svg";
import tableBtn from "../../../../../Assets/assetsnew/table-image-new.svg";
import columnBtn from "../../../../../Assets/assetsnew/column-image-new.svg";

import ControlPointRoundedIcon from "@mui/icons-material/ControlPointRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";

import TextStyles from "../../../../../Constants/TextStyles";
import Colors from "../../../../../Constants/Colors";
import Styled from "./AddSectionStyles";

import AddSectionTextModal from "./Text/AddSectionTextModal";
import AddSectionImageModal from "./Image/AddSectionImageModal";
import AddSectionTableModal from "./Table/AddSectionTableModal";
import AddSectionColumnModal from "./Column/AddSectionColumnModal";
import AddSectionFileModal from "./File/AddSectionFileModal";

const iconStyles = { color: Colors.primary };

const AddSection = ({ selectedData, position }) => {
  const [showContainer, setShowContainer] = useState(false);
  const [textShowModal, setTextShowModal] = useState(false);
  const [imageShowModal, setImageShowModal] = useState(false);
  const [tableShowModal, setTableShowModal] = useState(false);
  const [columnShowModal, setColumnShowModal] = useState(false);
  const [fileShowModal, setFileShowModal] = useState(false);

  return (
    <>
      {showContainer && (
        <Styled.AddSectionBox>
          <Styled.IconsBox onClick={() => setTextShowModal(true)}>
            <img src={textBtn} alt="textImageBtn" />
            <TextStyles.FontSize14px className="mt-2">
              Text
            </TextStyles.FontSize14px>
          </Styled.IconsBox>

          <Styled.IconsBox onClick={() => setImageShowModal(true)}>
            <img src={imageBtn} alt="imageBtn" />
            <TextStyles.FontSize14px className="mt-2">
              Image
            </TextStyles.FontSize14px>
          </Styled.IconsBox>

          <Styled.IconsBox
            onClick={() => setTableShowModal(true)}
            className="d-none d-sm-flex"
          >
            <img src={tableBtn} alt="tableImageBtn" />
            <TextStyles.FontSize14px className="mt-2">
              Table
            </TextStyles.FontSize14px>
          </Styled.IconsBox>

          <Styled.IconsBox
            onClick={() => setColumnShowModal(true)}
            className="d-none d-sm-flex"
          >
            <img src={columnBtn} alt="columnBtn" />
            <TextStyles.FontSize14px className="mt-2">
              Columns
            </TextStyles.FontSize14px>
          </Styled.IconsBox>

          <Styled.IconsBox onClick={() => setFileShowModal(true)}>
            <img src={fileBtn} alt="fileBtn" />
            <TextStyles.FontSize14px className="mt-2">
              File
            </TextStyles.FontSize14px>
          </Styled.IconsBox>
        </Styled.AddSectionBox>
      )}

      <Styled.ButtonAddSection
        onClick={() => setShowContainer((show) => !show)}
      >
        {showContainer ? (
          <RemoveCircleOutlineRoundedIcon sx={iconStyles} />
        ) : (
          <ControlPointRoundedIcon sx={iconStyles} />
        )}
        <span>{showContainer ? "Close" : "Add a section"}</span>
      </Styled.ButtonAddSection>

      {textShowModal && (
        <AddSectionTextModal
          position={position}
          selectedData={selectedData}
          show={textShowModal}
          handleClose={() => setTextShowModal(false)}
        />
      )}
      {imageShowModal && (
        <AddSectionImageModal
          position={position}
          selectedData={selectedData}
          show={imageShowModal}
          handleClose={() => setImageShowModal(false)}
        />
      )}

      {tableShowModal && (
        <AddSectionTableModal
          position={position}
          selectedData={selectedData}
          show={tableShowModal}
          handleClose={() => setTableShowModal(false)}
        />
      )}

      {columnShowModal && (
        <AddSectionColumnModal
          position={position}
          selectedData={selectedData}
          show={columnShowModal}
          handleClose={() => setColumnShowModal(false)}
        />
      )}
      {fileShowModal && (
        <AddSectionFileModal
          position={position}
          selectedData={selectedData}
          show={fileShowModal}
          handleClose={() => setFileShowModal(false)}
        />
      )}
    </>
  );
};

export default AddSection;
