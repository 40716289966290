import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { message } from "antd";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateContractorTaxIdPC } from "../../../../../../store/service/proposalAndContractService";
import Colors from "../../../../../../Constants/Colors";

import ContractorTaxIdModal from "../../PageModals/ContractorTaxIdModal.jsx";

const ContractorTaxId = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const heading =
    selectedData &&
    selectedData.contractor_tax_id &&
    selectedData.contractor_tax_id.heading
      ? selectedData &&
        selectedData.contractor_tax_id &&
        selectedData.contractor_tax_id.heading
      : "Contractor's Taxpayer I.D. Number";
  const paragraph =
    selectedData &&
    selectedData.contractor_tax_id &&
    selectedData.contractor_tax_id.paragraph
      ? selectedData &&
        selectedData.contractor_tax_id &&
        selectedData.contractor_tax_id.paragraph
      : "The taxpayer I.D. number of the Contractor is [_________] (SSN/BIN if you are a Canadian citizen). The Contractor is licensed to perform the agreed-upon services enumerated herein and covenants that it maintains all valid licenses, permits, and registrations to perform the same as required by law.";

  const isContractTaxIDRequired =
    selectedData &&
    selectedData.contractor_tax_id &&
    selectedData.contractor_tax_id.required
      ? true
      : false;

  const toggleContractTaxIDViewHandler = () => {
    const Obj = {
      _id: selectedData._id,
      contractor_tax_id: {
        required: isContractTaxIDRequired ? false : true,
        heading: isContractTaxIDRequired ? heading : null,
        paragraph: isContractTaxIDRequired ? paragraph : null,
      },
    };
    dispatch(updateContractorTaxIdPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        message.success(
          isContractTaxIDRequired
            ? "Clause hidden successfully!"
            : "Clause unhidden successfully!"
        );
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div>
      <Styled.ReviewContentBox className="mt-4">
        <div className="edit-delete-icons">
          <span onClick={toggleContractTaxIDViewHandler}>
            {isContractTaxIDRequired ? (
              <VisibilityOffOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            ) : (
              <VisibilityOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            )}
          </span>

          {isContractTaxIDRequired && (
            <BorderColorOutlinedIcon
              sx={{
                cursor: "pointer",
                color: Colors.font2,
                opacity: 0.8,
                marginLeft: 2,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={() => setShowModal(true)}
            />
          )}
        </div>

        <TextStyles.FontSize20px className="mb-3">
          <span
            className={`fw-medium ${isContractTaxIDRequired ? "" : "disabled"}`}
          >
            {heading}
          </span>
        </TextStyles.FontSize20px>

        <TextStyles.FontSize14px
          className={`lh-lg ${isContractTaxIDRequired ? "" : "disabled"}`}
        >
          {paragraph}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
      {showModal && (
        <ContractorTaxIdModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          heading={heading}
          paragraph={paragraph}
          selectedData={selectedData}
        />
      )}
    </div>
  );
};

export default ContractorTaxId;
