import styled from "styled-components";

const ReviewBox = styled.div`
  width: 100%;
  height: auto;
  padding: 1.5rem;
  border: 1px solid #a9abb3;
  border-radius: 0.75rem;

  @media (max-width: 576px) {
    padding: 1.5rem 1rem;
  }
`;
const EditServiceContainer = styled.div`
  .edit_services_imgBtn {
    visibility: hidden;
  }
  :hover .edit_services_imgBtn {
    visibility: visible;
  }
`;

export default {
  ReviewBox,
  EditServiceContainer,
};
