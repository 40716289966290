import styled from "styled-components";
import Colors from "../../../../../Constants/Colors";

const TableHead = styled.div`
  display: grid;
  grid-template-columns: minmax(50px, 1fr) minmax(50px, auto) minmax(60px, 3fr) 70px 86px;
  align-items: center;
  gap: 3rem;
  border-radius: 0.75rem 0.75rem 0 0;
  padding: 1rem;
  background-color: ${Colors.tableHeadColor};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  font-weight: 500;
  font-size: 0.875rem;
  color: ${Colors.font1};

  & > p {
    margin: 0;
  }

  @media (max-width: 768px) {
    gap: 1rem;
  }

  @media (max-width: 575px) {
    grid-template-columns:
      minmax(50px, 1fr) minmax(50px, auto) minmax(60px, 3fr)
      70px 35px;
  }

  .visible-title,
  .visible-note,
  .visible-createdAt,
  .visible-priority {
    visibility: hidden;
  }

  .hover-title:hover .visible-title,
  .hover-note:hover .visible-note,
  .hover-createdAt:hover .visible-createdAt,
  .hover-priority:hover .visible-priority {
    visibility: visible;
  }
`;

const TableRow = styled.li`
  height: 4rem;
  display: grid;
  grid-template-columns: minmax(50px, 1fr) minmax(50px, 104px) minmax(60px, 3fr) 70px auto;
  align-items: center;
  gap: 3rem;
  padding: 0 1rem;
  margin: 0 0 2px 0;
  background-color: ${Colors.white};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  font-size: 0.875rem;
  color: ${Colors.font1};
  cursor: pointer;
  border-bottom: 2px solid ${Colors.tableHeadColor};

  &:hover {
    background: ${Colors.hoverTableRow};
  }

  &:last-child {
    border-radius: 0 0 0.75rem 0.75rem;
    border-bottom: none;
  }

  & > p {
    margin: 0;
  }

  @media (max-width: 768px) {
    gap: 1rem;
  }
`;

const ListBox = styled.div`
  width: 100%;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.white};
  border-radius: 0.75rem;
  padding: 1rem;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
`;

export default { TableHead, TableRow, ListBox };
