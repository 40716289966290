import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Popover } from "antd";
import { message } from "antd";
import moment from "moment";

import FolderSharedTwoToneIcon from "@mui/icons-material/FolderSharedTwoTone";
import EventIcon from "@mui/icons-material/Event";
import FolderTwoToneIcon from "@mui/icons-material/FolderTwoTone";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ArrowUpwardTwoToneIcon from "@mui/icons-material/ArrowUpwardTwoTone";
import ArrowDownwardTwoToneIcon from "@mui/icons-material/ArrowDownwardTwoTone";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import NoReminderImage from "../../../../Assets/assetsnew/no-remainder.svg";

import Styled from "./RemindersStyles";
import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";
import DismissModal from "../../Commons/DismissModal";
import Pagination from "../../Commons/Pagination";

import SelectClients from "../../Commons/SelectClients";
import SelectProjects from "../../Commons/SelectProjects";
import SelectReminderStatus from "../../Commons/SelectReminderStatus";
import SelectDate from "../../Commons/SelectDate";

import { reminderActions } from "../../../../store/storage/reminderSlice";

import {
  dismissedReminder,
  doneReminder,
  fetchReminders,
  updateOrderingOfReminder,
} from "../../../../store/service/reminderService";
import { paidInvoice } from "../../../../store/service/invoiceService";
import LoaderSpin from "../../Commons/LoaderSpin";
import ReminderList from "./Components/ReminderList";

import {
  closestCorners,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { ReactTooltip } from "../../Commons/ReactTooltip";

const Reminders = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const remindersData = useSelector((state) => state.reminders.reminders);
  const remindersTotalCount = useSelector(
    (state) => state.reminders.remindersTotalCount
  );

  const [updateRefresh, setUpdateRefresh] = useState(false);

  const isLoading = useSelector((state) => state.reminders.isLoading);

  const clientOptionData = useSelector(
    (state) => state.clients.clientOptionData
  );

  const projectOptionsData = useSelector(
    (state) => state.project.projectOptionsData
  );

  const toggleFilter = useSelector((state) => state.reminders.toggleFilter);

  const filteredData = useSelector((state) => state.reminders.filteredData);

  const filterdDateRange =
    filteredData && filteredData.filterdDateRange
      ? filteredData.filterdDateRange
      : null;
  const filteredProject =
    filteredData && filteredData.filteredProject
      ? filteredData.filteredProject
      : null;

  const filteredClient =
    filteredData && filteredData.filteredClient
      ? filteredData.filteredClient
      : null;

  const filteredReminderStatus =
    filteredData && filteredData.filteredReminderStatus
      ? filteredData.filteredReminderStatus
      : "todo";

  const sortingData = useSelector((state) => state.reminders.sortingData);

  const selectedSortingOne =
    sortingData && sortingData.selectedSortingOne
      ? sortingData.selectedSortingOne
      : "orders";
  const selectedSortingTwo =
    sortingData && sortingData.selectedSortingTwo
      ? sortingData.selectedSortingTwo
      : "desc";

  const [showDismissModal, setShowDismissModal] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const handleCloseDismissModal = () => setShowDismissModal(false);

  const toggleShowFilter = () => {
    dispatch(reminderActions.toggleAction());
  };

  // sorting reminders
  const [openSorting, setOpenSorting] = useState(false);

  const hideSortingHandler = () => {
    setOpenSorting(false);
  };

  const handleOpenSortingChange = (newOpen) => {
    setOpenSorting(newOpen);
  };

  const sortingOptionChangeHandler = (event) => {
    dispatch(
      reminderActions.sortingAction({
        selectedSortingOne: event.target.value,
      })
    );
  };

  const sortingOrderingChangeHandler = (event) => {
    dispatch(
      reminderActions.sortingAction({
        selectedSortingTwo: event.target.value,
      })
    );
  };

  //pagination
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;

  // Invoke when user click to request another page.
  const handlePageClick = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    let start_time = null;
    let end_time = null;

    if (filterdDateRange && typeof filterdDateRange === "object") {
      start_time = moment(filterdDateRange[1]).endOf("days").toDate();
      end_time = moment(filterdDateRange[0]).startOf("days").toDate();
    } else {
      if (filterdDateRange === "Last 7 days") {
        start_time = moment().endOf("days").toDate();
        end_time = moment(start_time).subtract(6, "d").startOf("days").toDate();
      } else if (filterdDateRange === "Last 15 days") {
        start_time = moment().endOf("days").toDate();
        end_time = moment(start_time)
          .subtract(14, "d")
          .startOf("days")
          .toDate();
      } else if (filterdDateRange === "Last 30 days") {
        start_time = moment().endOf("days").toDate();
        end_time = moment(start_time)
          .subtract(29, "d")
          .startOf("days")
          .toDate();
      } else if (filterdDateRange === "Last 45 days") {
        start_time = moment().endOf("days").toDate();
        end_time = moment(start_time)
          .subtract(44, "d")
          .startOf("days")
          .toDate();
      } else if (filterdDateRange === "Last 60 days") {
        start_time = moment().endOf("days").toDate();
        end_time = moment(start_time)
          .subtract(59, "d")
          .startOf("days")
          .toDate();
      } else if (filterdDateRange === "Last 90 days") {
        start_time = moment().endOf("days").toDate();
        end_time = moment(start_time)
          .subtract(89, "d")
          .startOf("days")
          .toDate();
      } else if (filterdDateRange === "This month") {
        start_time = moment().endOf("month").toDate();
        end_time = moment().startOf("month").toDate();
      } else if (filterdDateRange === "Last month") {
        start_time = moment().subtract(1, "months").endOf("month").toDate();
        end_time = moment().subtract(1, "months").startOf("month").toDate();
      } else if (filterdDateRange === "This year") {
        start_time = moment().endOf("year").toDate();
        end_time = moment().startOf("year").toDate();
      } else if (filterdDateRange === "Last year") {
        start_time = moment().subtract(1, "years").endOf("year").toDate();
        end_time = moment().subtract(1, "years").startOf("year").toDate();
      }
    }

    const Obj = {
      page: page,
      perPage: itemsPerPage,
      start_time,
      end_time,
      client_id: filteredClient !== 0 ? filteredClient : null,
      project_id: filteredProject !== 0 ? filteredProject : null,
      status: filteredReminderStatus ? filteredReminderStatus : "todo",
      sortBy: selectedSortingOne,
      orderBy: selectedSortingTwo,
    };
    dispatch(fetchReminders(Obj));
  }, [filteredData, updateRefresh, page, itemsPerPage, sortingData]);

  const dismissReminderHandler = (data) => {
    const Obj = {
      _id: data._id,
    };
    dispatch(dismissedReminder(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  const doneReminderHandler = (data) => {
    const Obj = {
      _id: data._id,
    };

    dispatch(doneReminder(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  const sendInvoiceHandler = (data) => {
    history.push({
      pathname: "/db/invoices/create",
      state: { data: data && data.project_id, reminder_id: data._id },
    });
  };

  const resendInvoiceHandler = (data) => {
    history.push({
      pathname: `/db/invoices/resend/${data._id}/send`,
      state: { _id: data && data.invoice_id },
    });
  };

  const recordPaymentHandler = (data) => {
    const Obj = {
      _id: data && data.invoice_id,
      reminder_id: data && data._id,
    };
    dispatch(paidInvoice(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  const onDragEndhandler = (event) => {
    const { active, over } = event;
    // console.log({ event });
    const items = remindersData;

    if (over && active.id !== over.id) {
      const sourceIndex = items.findIndex((item) => item.id === active.id);
      const targetIndex = items.findIndex((item) => item.id === over.id);
      const data = arrayMove(items, sourceIndex, targetIndex);
      dispatch(reminderActions.dragAndDropAction(data));
      const updateReminders = data.map((data) => data._id);
      dispatch(updateOrderingOfReminder({ reminders: updateReminders })).then(
        (data) => {
          if (data.meta.requestStatus === "fulfilled") {
            setUpdateRefresh(!updateRefresh);
            // message.success(`${data.payload.message}`);
          } else {
            message.error(`${data.error.message}!`);
          }
        }
      );
    }
  };

  // mobile device
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const sortingContent = () => {
    return (
      <TextStyles.FontSize14px>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="sorting_task_name">
            <AssignmentIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Task name</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="sorting_task_name"
              type="radio"
              name="sorting"
              value="task_name"
              checked={selectedSortingOne === "task_name"}
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="sorting_company_name">
            <FolderSharedTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">Client name</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="sorting_company_name"
              type="radio"
              name="sorting"
              value="company_name"
              checked={selectedSortingOne === "company_name"}
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="sorting_project_name">
            <FolderTwoToneIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Project name</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="sorting_project_name"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "project_name"}
              value="project_name"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="sorting_due_date">
            <EventIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Due date</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="sorting_due_date"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "due_date"}
              value="due_date"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="sorting_createdAt">
            <EventIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">CreatedAt</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="sorting_createdAt"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "createdAt"}
              value="createdAt"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="orders">
            <DragIndicatorIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Custom</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="orders"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "orders"}
              value="orders"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <hr
          style={{
            borderColor: "f9f9f9",
            marginTop: 10,
            marginBottom: 10,
            borderTop: "none",
            opacity: 0.1,
          }}
        />

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="asc">
            <ArrowUpwardTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">Ascending (A-Z)</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="asc"
              type="radio"
              name="ordering"
              checked={selectedSortingTwo === "asc"}
              value="asc"
              onChange={sortingOrderingChangeHandler}
            />
          </div>
        </Styled.Sorting>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="desc">
            <ArrowDownwardTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">Descending (Z-A)</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="desc"
              type="radio"
              name="ordering"
              checked={selectedSortingTwo === "desc"}
              value="desc"
              onChange={sortingOrderingChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting className="mt-3">
          <TextStyles.GreyFont16px
            onClick={hideSortingHandler}
            style={{
              width: "100%",
              fontWeight: 500,
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            Done
          </TextStyles.GreyFont16px>
        </Styled.Sorting>
      </TextStyles.FontSize14px>
    );
  };

  const setSortingActiveHandler = (selectedSort) => {
    dispatch(
      reminderActions.sortingAction({
        selectedSortingOne: selectedSort,
        selectedSortingTwo: selectedSortingTwo === "desc" ? "asc" : "desc",
      })
    );
  };

  return (
    <div className="pt-3">
      <div className="d-flex flex-wrap gap-3 justify-content-sm-between mb-4">
        <div>
          <TextStyles.FontSize20px className="font-weight-500">
            Reminders
          </TextStyles.FontSize20px>
        </div>
        <div
          className="d-flex flex-wrap gap-3 align-items-center justify-content-center"
          style={{ marginLeft: "auto" }}
        >
          <div className="d-flex align-items-center">
            <TextStyles.HoverEffectSpan onClick={toggleShowFilter}>
              Filter
            </TextStyles.HoverEffectSpan>

            <TextStyles.GreyFont20px className="mx-2">
              |
            </TextStyles.GreyFont20px>

            <TextStyles.FontSize16px className="me-2">
              <Popover
                placement="bottomRight"
                content={sortingContent}
                trigger="click"
                open={openSorting}
                onOpenChange={handleOpenSortingChange}
              >
                <TextStyles.HoverEffectSpan sort={true}>
                  Sort
                  <ArrowDropDownOutlinedIcon
                    sx={{
                      color: Colors.font2,
                      fontSize: 27,
                      transform: openSorting ? "rotate(180deg)" : "",
                    }}
                  />
                </TextStyles.HoverEffectSpan>
              </Popover>
            </TextStyles.FontSize16px>
          </div>
        </div>
      </div>

      {toggleFilter && (
        <div className="d-flex flex-wrap justify-content-start mb-3">
          <div className="me-3 mb-2">
            <TextStyles.FontSize14px className="d-flex mb-2">
              Date range
            </TextStyles.FontSize14px>
            <SelectDate
              defaultValue={filterdDateRange && filterdDateRange}
              onChange={(value) => {
                dispatch(
                  reminderActions.filterAction({
                    filterdDateRange: value,
                  })
                );
              }}
            />
          </div>
          <div className="me-3 mb-2">
            <TextStyles.FontSize14px className="d-flex mb-2">
              Project
            </TextStyles.FontSize14px>
            <SelectProjects
              defaultValue={filteredProject && filteredProject}
              options={projectOptionsData}
              onChange={(e) =>
                dispatch(
                  reminderActions.filterAction({
                    filteredProject: e.target.value,
                  })
                )
              }
            />
          </div>
          <div className="me-3 mb-2">
            <TextStyles.FontSize14px className="d-flex mb-2">
              Client
            </TextStyles.FontSize14px>
            <SelectClients
              options={clientOptionData}
              defaultValue={filteredClient && filteredClient}
              onChange={(e) =>
                dispatch(
                  reminderActions.filterAction({
                    filteredClient: e.target.value,
                  })
                )
              }
            />
          </div>
          <div className="me-3 mb-2">
            <TextStyles.FontSize14px className="d-flex mb-2">
              Reminder status
            </TextStyles.FontSize14px>
            <SelectReminderStatus
              defaultValue={filteredReminderStatus && filteredReminderStatus}
              onChange={(e) =>
                dispatch(
                  reminderActions.filterAction({
                    filteredReminderStatus: e.target.value,
                  })
                )
              }
            />
          </div>
        </div>
      )}

      <Styled.TodoRectangle className="mt-4">
        <TextStyles.FontSize14px
          className="d-flex gap-2 align-items-center w-100"
          style={{ fontWeight: 500 }}
        >
          <div
            className="d-flex justify-content-center align-items-center w-100"
            style={{ minWidth: 25, maxWidth: 25 }}
          />
          <div
            className="d-flex justify-content-start w-100"
            style={{ minWidth: 130 }}
          >
            <ReactTooltip anchorId="task_name" content="Sort by task name" />
            <span
              className="three-dots hover-task-name"
              id="task_name"
              role="button"
              onClick={() => setSortingActiveHandler("task_name")}
            >
              Task name
              {selectedSortingOne === "task_name" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-task-name"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-task-name"
                    />
                  )}
                </>
              )}
            </span>
          </div>
          <div
            className="d-none d-md-flex justify-content-start w-100"
            style={{ maxWidth: 150, minWidth: 30 }}
          >
            <ReactTooltip anchorId="projects" content="Sort by project name" />
            <span
              className="three-dots hover-projects"
              id="projects"
              role="button"
              onClick={() => setSortingActiveHandler("project_name")}
            >
              Project name
              {selectedSortingOne === "project_name" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-projects"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-projects"
                    />
                  )}
                </>
              )}
            </span>
          </div>

          <div
            className="d-none d-md-flex justify-content-start w-100"
            style={{ maxWidth: 140, minWidth: 30 }}
          >
            <ReactTooltip
              anchorId="client_name"
              content="Sort by client name"
            />
            <span
              className="three-dots hover-clients"
              id="client_name"
              role="button"
              onClick={() => setSortingActiveHandler("company_name")}
            >
              Client name
              {selectedSortingOne === "company_name" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-clients"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-clients"
                    />
                  )}
                </>
              )}
            </span>
          </div>

          <div
            className="d-none d-md-flex justify-content-center w-100"
            style={{ maxWidth: 105, minWidth: 30 }}
          >
            <ReactTooltip anchorId="createdAt" content="Sort by created date" />
            <span
              className="three-dots hover-createdAt"
              id="createdAt"
              role="button"
              onClick={() => setSortingActiveHandler("createdAt")}
            >
              CreatedAt
              {selectedSortingOne === "createdAt" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-createdAt"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-createdAt"
                    />
                  )}
                </>
              )}
            </span>
          </div>
          <div
            className="d-flex justify-content-center w-100"
            style={{ maxWidth: 105, minWidth: 30 }}
          >
            <ReactTooltip anchorId="due-date" content="Sort by due date" />
            <span
              className="three-dots hover-due-date"
              id="due-date"
              role="button"
              onClick={() => setSortingActiveHandler("due_date")}
            >
              Due date
              {selectedSortingOne === "due-date" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-due-date"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-due-date"
                    />
                  )}
                </>
              )}
            </span>
          </div>
          <div
            className="d-flex justify-content-end position-relative w-100"
            style={{ maxWidth: 120, minWidth: 80 }}
          />
        </TextStyles.FontSize14px>
      </Styled.TodoRectangle>

      <div className="position-relative w-100 h-100">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCorners}
          modifiers={[restrictToVerticalAxis]}
          // onDragStart={({ active }) => {
          //   console.log("starting drag", active);
          // }}
          onDragEnd={onDragEndhandler}
          // onDragCancel={() => {
          //   setActive(null);
          // }}
        >
          <SortableContext
            items={remindersData ? remindersData : []}
            strategy={verticalListSortingStrategy}
          >
            {remindersData && remindersData.length > 0 ? (
              remindersData.map((data, index) => {
                return (
                  <ReminderList
                    key={index}
                    index={index}
                    data={data}
                    remindersData={remindersData}
                    setShowDismissModal={setShowDismissModal}
                    setConfirmDialog={setConfirmDialog}
                    sendInvoiceHandler={sendInvoiceHandler}
                    recordPaymentHandler={recordPaymentHandler}
                    dismissReminderHandler={dismissReminderHandler}
                    doneReminderHandler={doneReminderHandler}
                    resendInvoiceHandler={resendInvoiceHandler}
                  />
                );
              })
            ) : isLoading && remindersData === null ? (
              <Styled.ListBox>
                <LoaderSpin color={Colors.primary} />
              </Styled.ListBox>
            ) : (
              <Styled.ListBox>
                <img src={NoReminderImage} alt="no-reminder-img" />
                <TextStyles.GreyFont18px className="text-center mt-2">
                  There are no reminders yet.
                  <br />
                  (Zodot will remind you to send invoices and follow up on
                  invoices)
                </TextStyles.GreyFont18px>
              </Styled.ListBox>
            )}
          </SortableContext>
        </DndContext>
      </div>
      <div className="d-flex justify-content-center py-5">
        {remindersTotalCount > itemsPerPage && (
          <Pagination
            handlePageClick={handlePageClick}
            pageCount={Math.ceil(remindersTotalCount / itemsPerPage)}
            page={page}
          />
        )}
      </div>

      <DismissModal
        title="reminder"
        confirmDialog={confirmDialog}
        showDismissModal={showDismissModal}
        handleCloseDismissModal={handleCloseDismissModal}
      />
    </div>
  );
};

export default Reminders;
