import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";

import Colors from "../../../Constants/Colors";
import sizes from "../../../Constants/sizes";

const useStyles = makeStyles({
  root: {
    border: `1px solid ${Colors.border}`,
    borderRadius: `${sizes.formInputBorderRadius} !important`,
    fontSize: "0.875rem !important",
    maxHeight: "3.15rem !important",
    fontFamily: "Poppins !important",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },

    "&:hover": {
      border: "none",
    },

    "&.Mui-focused": {
      border: "none",
    },
  },

  select: {
    display: "flex !important",
    alignItems: "center !important",
  },

  listMenu: {
    color: Colors.font2,
    fontWeight: "400 !important",
    marginBottom: "2px !important",
    fontSize: "0.875rem !important",
    fontFamily: "Poppins !important",
    "&:hover": {
      backgroundColor: `${Colors.backgroundPink} !important`,
      border: "none",
    },
    '&[aria-selected="true"]': {
      backgroundColor: `${Colors.backgroundPink} !important`,
    },
  },
});

const priorityOptions = [
  {
    id: "1",
    value: "high",
    label: "High",
  },
  {
    id: "2",
    value: "normal",
    label: "Normal",
  },
  {
    id: "3",
    value: "low",
    label: "Low",
  },
];

export default function SelectPriority(props) {
  const classes = useStyles();

  return (
    <div>
      <Select
        classes={{
          select: classes.select,
        }}
        className={classes.root}
        value={props.value ? props.value : priorityOptions[1].value}
        id="grouped-select"
        sx={{ width: "100%" }}
        onChange={props.onChange}
        MenuProps={{
          PaperProps: { sx: { maxHeight: 250 } },
        }}
      >
        {priorityOptions &&
          priorityOptions.map((data, index) => (
            <MenuItem
              key={index}
              value={data.value}
              className={classes.listMenu}
            >
              {data.label}
            </MenuItem>
          ))}
      </Select>
    </div>
  );
}
