import React from "react";

import TextStyles from "../../../../../../../Constants/TextStyles";
import Styled from "../AddSectionStyles";
import textBtn from "../../../../../../../Assets/assetsnew/text-image-new.svg";

const Text = ({ data }) => {
  return (
    <Styled.ReviewBox className="mt-4">
      <div className="d-flex align-items-center gap-3 mb-4">
        <img src={textBtn} alt="textImageBtn" style={{ maxWidth: "2.5rem" }} />

        <TextStyles.FontSize18px className="fw-medium">
          Text
        </TextStyles.FontSize18px>
      </div>

      <TextStyles.FontSize14px>
        <div className="mb-2 fw-medium">Text title</div>
        <Styled.Text className="three-dots mb-3">
          {data.text_title}
        </Styled.Text>

        <div className="mb-2 fw-medium">Text description</div>
        <Styled.Text className="three-dots">
          {data.textarea}
        </Styled.Text>
      </TextStyles.FontSize14px>
    </Styled.ReviewBox>
  );
};

export default Text;
