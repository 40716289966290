import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import ClearOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { message } from "antd";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch } from "react-redux";
import { updateAddClausePC } from "../../../../../../store/service/proposalAndContractService";
import EditAddClauseModal from "../../PageModals/EditAddClauseModal";
import Colors from "../../../../../../Constants/Colors";

const ListAddClause = ({ data, pc_id }) => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const heading = data && data.heading ? data.heading : "";

  const paragraph = data && data.paragraph ? data.paragraph : "";

  const hideClauseHandler = () => {
    const Obj = {
      pc_id: pc_id,
      _id: data._id,
      add_clause: {
        required: false,
        heading: null,
        paragraph: null,
      },
    };
    dispatch(updateAddClausePC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        message.success("Clause hidden successfully!");
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div
      style={{
        display: data && data.required === true ? "inline" : "none",
      }}
    >
      <Styled.ReviewContentBox className="mt-4">
        <div className="edit-delete-icons">
          <BorderColorOutlinedIcon
            sx={{
              cursor: "pointer",
              color: Colors.font2,
              opacity: 0.8,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={() => setShowModal(true)}
          />

          <ClearOutlinedIcon
            onClick={hideClauseHandler}
            sx={{
              cursor: "pointer",
              color: Colors.font2,
              opacity: 0.8,
              marginLeft: 1.5,
              "&:hover": {
                opacity: 1,
              },
            }}
          />
        </div>

        <TextStyles.FontSize20px className="mb-3 fw-medium three-dots" style={{maxWidth: 580}}>
          {heading}
        </TextStyles.FontSize20px>

        <TextStyles.FontSize14px
          className="lh-lg"
          style={{ whiteSpace: "break-spaces" }}
        >
          {paragraph}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>

      {showModal && (
        <EditAddClauseModal
          pc_id={pc_id}
          data={data}
          show={showModal}
          handleClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default ListAddClause;
