import styled from "styled-components";
import Colors from "../../../../Constants/Colors";

const SendBox = styled.div`
  width: 100%;
  height: auto;
  padding: 1.875rem;
  border-radius: 0.75rem;
  border: solid 1px #b2b6c5;
  background-color: ${Colors.white};
  .textarea_padding_right {
    padding-right: 4rem;
  }
  @media (max-width: 576px) {
    padding: 1rem;
    .textarea_padding_right {
      padding-right: 1rem;
    }
  }
`;

const ChipsRectangle = styled.div`
  width: 100%;
  min-height: 3.375rem;
  display: flex;
  flex-flow: wrap;
  margin: 6px 0 9px;
  border-radius: 0.75rem;
  border: 1px solid ${(props) => (props.invalid ? Colors.red : Colors.font2)};
  background-color: ${(props) =>
    props.invalid ? Colors.inputErrorBg : Colors.white};
`;

const ChipRectangle = styled.div`
  margin: 9px;
  padding: 0.5rem 0.875rem 0.5rem 0.75rem;
  border-radius: 0.5rem;
  cursor: pointer;
  opacity: ${(props) => (props.selected ? 0.4 : 1)};
  background-color: ${Colors.backgroundPink};
`;

const Template = styled.div`
  width: 100%;
  min-width: 7.5rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-top: 8px;
  cursor: pointer;
  border-radius: 0.75rem;
  background-color: ${Colors.backgroundPink};
  color: ${Colors.primary};
`;
const TemplateLi = styled.div`
  width: 100%;
  min-width: 10rem;
  display: flex;
  align-items: center;
  padding: 6px 8px;
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    background: ${Colors.backgroundPink};
  }
`;

export default {
  SendBox,
  ChipsRectangle,
  ChipRectangle,
  Template,
  TemplateLi,
};
