import { createSlice } from "@reduxjs/toolkit";

import {
  fetchPC,
  createPC,
  updateProjectDetailPC,
  updateClientDetailPC,
  updateServiceListPC,
  updateBillingSchedulePC,
  createAddSectionTextPC,
  deleteAddSectionTextPC,
  updateAddSectionTextPC,
  createAddSectionImagePC,
  deleteAddSectionImagePC,
  updateAddSectionImagePC,
  createAddSectionTablePC,
  deleteAddSectionTablePC,
  updateAddSectionTablePC,
  createAddSectionColumnPC,
  deleteAddSectionColumnPC,
  updateAddSectionColumnPC,
  createAddSectionFilePC,
  updateAddSectionFilePC,
  deleteAddSectionFilePC,
  createContact,
  updateSignaturePC,
  emailSendPC,
  contractSettingsPC,
  contractExpireDatePC,
  updateAcceptancePC,
  updateResponsibilityPC,
  updateCompensationPC,
  updateWarrantyPC,
  updateConfidentialPC,
  updateOwnershipRightPC,
  updateNonSolicitPC,
  updateAddNonCompletePC,
  updateRelationshipOfPartiesPC,
  updateProjectTerminationNoticePeriodPC,
  updateCancellationFeePC,
  updatePaymentTermsPC,
  updateContractorTaxIdPC,
  updateHealthInsurancePC,
  updateLatePaymentPC,
  updateDepositPC,
  updateReimbursementPC,
  updateTerminationRequestPC,
  updateChangesPC,
  updateLiabilityLimitationPC,
  updateRetainRightsPC,
  updateGoverningLawPC,
  updateForceMejeurePC,
  updateNoticesPC,
  updateAddSexualHarassmentCluasePC,
  updatePrivacyDataPC,
  updateMiscellaneousPC,
  updateEntireContractPC,
  createAddClausePC,
  updateAddClausePC,
  deletePC,
  dismissedPcByUser,
  approvedPcByUser,
  fetchPCCounts,
} from "../service/proposalAndContractService";

const proposalInitialState = {
  pcData: null,
  totalPcCount: null,
  isLoading: false,
  error: null,
  createDocType: null,
  selectedData: null,
  filteredData: {},
  toggleFilter: false,
  sortingData: {},
};

export const proposalSlice = createSlice({
  name: "proposals",
  initialState: proposalInitialState,
  reducers: {
    createPcDocType: (state, action) => {
      state.createDocType = action.payload;
    },
    resetPcDataAction: (state) => {
      state.createDocType = null;
      state.selectedData = null;
    },
    editPositionPcData: (state, action) => {
      state.selectedData = action.payload;
    },

    filterAction: (state, action) => {
      state.filteredData = { ...state.filteredData, ...action.payload };
    },
    toggleAction: (state) => {
      state.toggleFilter = !state.toggleFilter;
    },
    sortingAction: (state, action) => {
      state.sortingData = { ...state.sortingData, ...action.payload };
    },
  },

  extraReducers: (builder) => {
    builder
      // fetch proposal and contract
      .addCase(fetchPC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPC.fulfilled, (state, action) => {
        state.isLoading = false;
        state.pcData = action.payload.data.resData;
        state.totalPcCount = action.payload.data.totalPcCount;
      })
      .addCase(fetchPC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // create proposal contract
      .addCase(createPC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createPC.fulfilled, (state, action) => {
        state.selectedData = action.payload.data;
        state.isLoading = false;
      })
      .addCase(createPC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // update project details in PC
      .addCase(updateProjectDetailPC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProjectDetailPC.fulfilled, (state, action) => {
        state.selectedData.client_id = action.payload.data.client_id;
        state.selectedData.project_name = action.payload.data.project_name;
        state.selectedData.currency = action.payload.data.currency;
        state.selectedData.project_start_date =
          action.payload.data.project_start_date;
        state.selectedData.project_end_date =
          action.payload.data.project_end_date;
        state.selectedData.client_email = action.payload.data.client_email;
        state.isLoading = false;
      })
      .addCase(updateProjectDetailPC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // update client details in PC
      .addCase(updateClientDetailPC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateClientDetailPC.fulfilled, (state, action) => {
        state.selectedData.client_id = action.payload.data.client_id;
        state.selectedData.client_email = action.payload.data.client_email;
        state.isLoading = false;
      })
      .addCase(updateClientDetailPC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // update service list in PC
      .addCase(updateServiceListPC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateServiceListPC.fulfilled, (state, action) => {
        state.selectedData.service_list = action.payload.data.service_list;
        state.isLoading = false;
      })
      .addCase(updateServiceListPC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // update billing schedule in PC
      .addCase(updateBillingSchedulePC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateBillingSchedulePC.fulfilled, (state, action) => {
        state.selectedData.billing_scheduled =
          action.payload.data.billing_scheduled;
        state.isLoading = false;
      })
      .addCase(updateBillingSchedulePC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // create add section text
      .addCase(createAddSectionTextPC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createAddSectionTextPC.fulfilled, (state, action) => {
        state.selectedData.add_section_text_one =
          action.payload.data.add_section_text_one;
        state.selectedData.add_section_text_two =
          action.payload.data.add_section_text_two;
        state.isLoading = false;
      })
      .addCase(createAddSectionTextPC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // delete add section text
      .addCase(deleteAddSectionTextPC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAddSectionTextPC.fulfilled, (state, action) => {
        state.selectedData.add_section_text_one =
          action.payload.data.add_section_text_one;
        state.selectedData.add_section_text_two =
          action.payload.data.add_section_text_two;
        state.isLoading = false;
      })
      .addCase(deleteAddSectionTextPC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // update add section text
      .addCase(updateAddSectionTextPC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAddSectionTextPC.fulfilled, (state, action) => {
        state.selectedData.add_section_text_one =
          action.payload.data.add_section_text_one;
        state.selectedData.add_section_text_two =
          action.payload.data.add_section_text_two;
        state.isLoading = false;
      })
      .addCase(updateAddSectionTextPC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // create add section image
      .addCase(createAddSectionImagePC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createAddSectionImagePC.fulfilled, (state, action) => {
        state.selectedData.add_section_image_one =
          action.payload.data.add_section_image_one;
        state.selectedData.add_section_image_two =
          action.payload.data.add_section_image_two;
        state.isLoading = false;
      })
      .addCase(createAddSectionImagePC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // delete add section image
      .addCase(deleteAddSectionImagePC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAddSectionImagePC.fulfilled, (state, action) => {
        state.selectedData.add_section_image_one =
          action.payload.data.add_section_image_one;
        state.selectedData.add_section_image_two =
          action.payload.data.add_section_image_two;
        state.isLoading = false;
      })
      .addCase(deleteAddSectionImagePC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // update add section image
      .addCase(updateAddSectionImagePC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAddSectionImagePC.fulfilled, (state, action) => {
        state.selectedData.add_section_image_one =
          action.payload.data.add_section_image_one;
        state.selectedData.add_section_image_two =
          action.payload.data.add_section_image_two;
        state.isLoading = false;
      })
      .addCase(updateAddSectionImagePC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // create add section table
      .addCase(createAddSectionTablePC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createAddSectionTablePC.fulfilled, (state, action) => {
        state.selectedData.add_section_table_one =
          action.payload.data.add_section_table_one;
        state.selectedData.add_section_table_two =
          action.payload.data.add_section_table_two;
        state.isLoading = false;
      })
      .addCase(createAddSectionTablePC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // delete add section table
      .addCase(deleteAddSectionTablePC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAddSectionTablePC.fulfilled, (state, action) => {
        state.selectedData.add_section_table_one =
          action.payload.data.add_section_table_one;
        state.selectedData.add_section_table_two =
          action.payload.data.add_section_table_two;
        state.isLoading = false;
      })
      .addCase(deleteAddSectionTablePC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // update add section table
      .addCase(updateAddSectionTablePC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAddSectionTablePC.fulfilled, (state, action) => {
        state.selectedData.add_section_table_one =
          action.payload.data.add_section_table_one;
        state.selectedData.add_section_table_two =
          action.payload.data.add_section_table_two;
        state.isLoading = false;
      })
      .addCase(updateAddSectionTablePC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // create add section column
      .addCase(createAddSectionColumnPC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createAddSectionColumnPC.fulfilled, (state, action) => {
        state.selectedData.add_section_column_one =
          action.payload.data.add_section_column_one;
        state.selectedData.add_section_column_two =
          action.payload.data.add_section_column_two;
        state.isLoading = false;
      })
      .addCase(createAddSectionColumnPC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // delete add section column
      .addCase(deleteAddSectionColumnPC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAddSectionColumnPC.fulfilled, (state, action) => {
        state.selectedData.add_section_column_one =
          action.payload.data.add_section_column_one;
        state.selectedData.add_section_column_two =
          action.payload.data.add_section_column_two;
        state.isLoading = false;
      })
      .addCase(deleteAddSectionColumnPC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // update add section column
      .addCase(updateAddSectionColumnPC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAddSectionColumnPC.fulfilled, (state, action) => {
        state.selectedData.add_section_column_one =
          action.payload.data.add_section_column_one;
        state.selectedData.add_section_column_two =
          action.payload.data.add_section_column_two;
        state.isLoading = false;
      })
      .addCase(updateAddSectionColumnPC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // create add section file
    builder
      .addCase(createAddSectionFilePC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createAddSectionFilePC.fulfilled, (state, action) => {
        state.selectedData.add_section_file_one =
          action.payload.data.add_section_file_one;
        state.selectedData.add_section_file_two =
          action.payload.data.add_section_file_two;
        state.isLoading = false;
      })
      .addCase(createAddSectionFilePC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // update add section file
    builder
      .addCase(updateAddSectionFilePC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAddSectionFilePC.fulfilled, (state, action) => {
        state.selectedData.add_section_file_one =
          action.payload.data.add_section_file_one;
        state.selectedData.add_section_file_two =
          action.payload.data.add_section_file_two;
        state.isLoading = false;
      })
      .addCase(updateAddSectionFilePC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // delete add section file
    builder
      .addCase(deleteAddSectionFilePC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAddSectionFilePC.fulfilled, (state, action) => {
        state.selectedData.add_section_file_one =
          action.payload.data.add_section_file_one;
        state.selectedData.add_section_file_two =
          action.payload.data.add_section_file_two;
        state.isLoading = false;
      })
      .addCase(deleteAddSectionFilePC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // create contact for a specific client
    builder
      .addCase(createContact.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createContact.fulfilled, (state, action) => {
        state.selectedData.client_id.contacts = action.payload.data.contacts;
        state.isLoading = false;
      })
      .addCase(createContact.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // update signature
    builder
      .addCase(updateSignaturePC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateSignaturePC.fulfilled, (state, action) => {
        state.selectedData.signature = action.payload.data;
        state.isLoading = false;
      })
      .addCase(updateSignaturePC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // send email PC
    builder
      .addCase(emailSendPC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(emailSendPC.fulfilled, (state, action) => {
        state.selectedData.send_email = action.payload.data.send_email;
        state.selectedData.status = action.payload.data.status;
        state.isLoading = false;
      })
      .addCase(emailSendPC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
    // update contract settings
    builder
      .addCase(contractSettingsPC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(contractSettingsPC.fulfilled, (state, action) => {
        state.selectedData.contract_expire_date =
          action.payload.data.contract_expire_date;
        state.selectedData.payment_terms = action.payload.data.payment_terms;
        state.selectedData.term_and_termination =
          action.payload.data.term_and_termination;
        state.selectedData.number_of_termination_request =
          action.payload.data.number_of_termination_request;
        state.selectedData.ownership_right =
          action.payload.data.ownership_right;
        state.selectedData.rightToAuthorship =
          action.payload.data.rightToAuthorship;
        state.selectedData.late_payment_fee =
          action.payload.data.late_payment_fee;
        state.selectedData.cancellation_fee =
          action.payload.data.cancellation_fee;
        state.selectedData.add_non_complete =
          action.payload.data.add_non_complete;
        state.selectedData.add_sexual_harassment_clause =
          action.payload.data.add_sexual_harassment_clause;
        state.isLoading = false;
      })
      .addCase(contractSettingsPC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // update client contract
    builder
      .addCase(contractExpireDatePC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(contractExpireDatePC.fulfilled, (state, action) => {
        state.selectedData.contract_expire_date =
          action.payload.data.contract_expire_date;
        state.isLoading = false;
      })
      .addCase(contractExpireDatePC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // update responsibility
    builder
      .addCase(updateResponsibilityPC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateResponsibilityPC.fulfilled, (state, action) => {
        state.selectedData.responsibility = action.payload.data.responsibility;
        state.isLoading = false;
      })
      .addCase(updateResponsibilityPC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // update compensation
    builder
      .addCase(updateCompensationPC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateCompensationPC.fulfilled, (state, action) => {
        state.selectedData.compensation = action.payload.data.compensation;
        state.isLoading = false;
      })
      .addCase(updateCompensationPC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // update acceptance
    builder
      .addCase(updateAcceptancePC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAcceptancePC.fulfilled, (state, action) => {
        state.selectedData.acceptance = action.payload.data.acceptance;
        state.isLoading = false;
      })
      .addCase(updateAcceptancePC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
    // update warranty
    builder
      .addCase(updateWarrantyPC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateWarrantyPC.fulfilled, (state, action) => {
        state.selectedData.warranty = action.payload.data.warranty;
        state.isLoading = false;
      })
      .addCase(updateWarrantyPC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // update confidential
    builder
      .addCase(updateConfidentialPC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateConfidentialPC.fulfilled, (state, action) => {
        state.selectedData.confidential = action.payload.data.confidential;
        state.isLoading = false;
      })
      .addCase(updateConfidentialPC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // update ownership right
    builder
      .addCase(updateOwnershipRightPC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateOwnershipRightPC.fulfilled, (state, action) => {
        state.selectedData.ownership_right =
          action.payload.data.ownership_right;
        state.isLoading = false;
      })
      .addCase(updateOwnershipRightPC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // update non solicit
    builder
      .addCase(updateNonSolicitPC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateNonSolicitPC.fulfilled, (state, action) => {
        state.selectedData.non_solicit = action.payload.data.non_solicit;
        state.isLoading = false;
      })
      .addCase(updateNonSolicitPC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // update add non complete clause
    builder
      .addCase(updateAddNonCompletePC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAddNonCompletePC.fulfilled, (state, action) => {
        state.selectedData.add_non_complete =
          action.payload.data.add_non_complete;
        state.isLoading = false;
      })
      .addCase(updateAddNonCompletePC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // update relationship of parties
    builder
      .addCase(updateRelationshipOfPartiesPC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateRelationshipOfPartiesPC.fulfilled, (state, action) => {
        state.selectedData.relationship_of_parties =
          action.payload.data.relationship_of_parties;
        state.isLoading = false;
      })
      .addCase(updateRelationshipOfPartiesPC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // update project termination notice period
    builder
      .addCase(updateProjectTerminationNoticePeriodPC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        updateProjectTerminationNoticePeriodPC.fulfilled,
        (state, action) => {
          state.selectedData.term_and_termination =
            action.payload.data.term_and_termination;
          state.isLoading = false;
        }
      )
      .addCase(
        updateProjectTerminationNoticePeriodPC.rejected,
        (state, action) => {
          state.isLoading = false;
          state.error = action.error.message;
        }
      );

    // update contractor tax payer id
    builder
      .addCase(updateContractorTaxIdPC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateContractorTaxIdPC.fulfilled, (state, action) => {
        state.selectedData.contractor_tax_id =
          action.payload.data.contractor_tax_id;
        state.isLoading = false;
      })
      .addCase(updateContractorTaxIdPC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // update health and medical insurance
    builder
      .addCase(updateHealthInsurancePC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateHealthInsurancePC.fulfilled, (state, action) => {
        state.selectedData.health_insurance =
          action.payload.data.health_insurance;
        state.isLoading = false;
      })
      .addCase(updateHealthInsurancePC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // update cancellation fee
    builder
      .addCase(updateCancellationFeePC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateCancellationFeePC.fulfilled, (state, action) => {
        state.selectedData.cancellation_fee =
          action.payload.data.cancellation_fee;
        state.isLoading = false;
      })
      .addCase(updateCancellationFeePC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
    // update payment terms
    builder
      .addCase(updatePaymentTermsPC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePaymentTermsPC.fulfilled, (state, action) => {
        state.selectedData.payment_terms = action.payload.data.payment_terms;
        state.isLoading = false;
      })
      .addCase(updatePaymentTermsPC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // update late payment fee
    builder
      .addCase(updateLatePaymentPC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateLatePaymentPC.fulfilled, (state, action) => {
        state.selectedData.late_payment_fee =
          action.payload.data.late_payment_fee;
        state.isLoading = false;
      })
      .addCase(updateLatePaymentPC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // update deposit
    builder
      .addCase(updateDepositPC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateDepositPC.fulfilled, (state, action) => {
        state.selectedData.deposit = action.payload.data.deposit;
        state.isLoading = false;
      })
      .addCase(updateDepositPC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
    // update reimbursement
    builder
      .addCase(updateReimbursementPC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateReimbursementPC.fulfilled, (state, action) => {
        state.selectedData.reimbursement = action.payload.data.reimbursement;
        state.isLoading = false;
      })
      .addCase(updateReimbursementPC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // update termination request
    builder
      .addCase(updateTerminationRequestPC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateTerminationRequestPC.fulfilled, (state, action) => {
        state.selectedData.number_of_termination_request =
          action.payload.data.number_of_termination_request;
        state.isLoading = false;
      })
      .addCase(updateTerminationRequestPC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // update changes in contracts
    builder
      .addCase(updateChangesPC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateChangesPC.fulfilled, (state, action) => {
        state.selectedData.changes = action.payload.data.changes;
        state.isLoading = false;
      })
      .addCase(updateChangesPC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // update liability and limitation
    builder
      .addCase(updateLiabilityLimitationPC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateLiabilityLimitationPC.fulfilled, (state, action) => {
        state.selectedData.liabilityLimitation =
          action.payload.data.liabilityLimitation;
        state.isLoading = false;
      })
      .addCase(updateLiabilityLimitationPC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // update retain rights
    builder
      .addCase(updateRetainRightsPC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateRetainRightsPC.fulfilled, (state, action) => {
        state.selectedData.rightToAuthorship =
          action.payload.data.rightToAuthorship;
        state.isLoading = false;
      })
      .addCase(updateRetainRightsPC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // update governing law
    builder
      .addCase(updateGoverningLawPC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateGoverningLawPC.fulfilled, (state, action) => {
        state.selectedData.governingLaw = action.payload.data.governingLaw;
        state.isLoading = false;
      })
      .addCase(updateGoverningLawPC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
    // update force majeure
    builder
      .addCase(updateForceMejeurePC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateForceMejeurePC.fulfilled, (state, action) => {
        state.selectedData.forceMejeure = action.payload.data.forceMejeure;
        state.isLoading = false;
      })
      .addCase(updateForceMejeurePC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // update notices
    builder
      .addCase(updateNoticesPC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateNoticesPC.fulfilled, (state, action) => {
        state.selectedData.notices = action.payload.data.notices;
        state.isLoading = false;
      })
      .addCase(updateNoticesPC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // update sexual harassment clause
    builder
      .addCase(updateAddSexualHarassmentCluasePC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAddSexualHarassmentCluasePC.fulfilled, (state, action) => {
        state.selectedData.add_sexual_harassment_clause =
          action.payload.data.add_sexual_harassment_clause;
        state.isLoading = false;
      })
      .addCase(updateAddSexualHarassmentCluasePC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // update privacy data
    builder
      .addCase(updatePrivacyDataPC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePrivacyDataPC.fulfilled, (state, action) => {
        state.selectedData.privacy_data = action.payload.data.privacy_data;
        state.isLoading = false;
      })
      .addCase(updatePrivacyDataPC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // update miscellaneous
    builder
      .addCase(updateMiscellaneousPC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateMiscellaneousPC.fulfilled, (state, action) => {
        state.selectedData.miscellaneous = action.payload.data.miscellaneous;
        state.isLoading = false;
      })
      .addCase(updateMiscellaneousPC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
    // update entire contract
    builder
      .addCase(updateEntireContractPC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateEntireContractPC.fulfilled, (state, action) => {
        state.selectedData.entire_contract =
          action.payload.data.entire_contract;
        state.isLoading = false;
      })
      .addCase(updateEntireContractPC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // create add clause
    builder
      .addCase(createAddClausePC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createAddClausePC.fulfilled, (state, action) => {
        state.selectedData.add_clause = action.payload.data.add_clause;
        state.isLoading = false;
      })
      .addCase(createAddClausePC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // update add clause
    builder
      .addCase(updateAddClausePC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAddClausePC.fulfilled, (state, action) => {
        state.selectedData.add_clause = action.payload.data.add_clause;
        state.isLoading = false;
      })
      .addCase(updateAddClausePC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
    // delete PC
    builder
      .addCase(deletePC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletePC.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deletePC.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // approved PC by user
    builder
      .addCase(approvedPcByUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(approvedPcByUser.fulfilled, (state, action) => {
        const index = state.pcData.findIndex(
          (pc) => pc._id === action.payload.data._id
        );
        state.pcData[index].status = action.payload.data.status;
        state.isLoading = false;
      })
      .addCase(approvedPcByUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // dismissed PC
    builder
      .addCase(dismissedPcByUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(dismissedPcByUser.fulfilled, (state, action) => {
        const index = state.pcData.findIndex(
          (pc) => pc._id === action.payload.data._id
        );
        state.pcData[index].status = action.payload.data.status;
        state.isLoading = false;
      })
      .addCase(dismissedPcByUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // fetch PC counts
    builder
      .addCase(fetchPCCounts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPCCounts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.totalPcCount = action.payload.data.totalPcCount;
      })
      .addCase(fetchPCCounts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const pcActions = proposalSlice.actions;
export default proposalSlice.reducer;
