import styled from "styled-components";
import Colors from "../../../../../Constants/Colors";

const AddSectionContainer = styled.div`
  /* width: 100%; */
  /* position: relative; */
  .add_section_show_feature {
    /* display: none; */
    /* z-index: 1; */
    /* position: absolute; */
    /* margin-top: -9.5rem; */
    /* justify-content: space-between; */
  }
  :hover .add_section_show_feature {
    /* display: flex; */
  }

  @media (max-width: 576px) {
    .add_section_show_feature {
      /* margin-top: -7.5rem; */
    }
  }
`;

const AddSectionBox = styled.div`
  margin: 1rem;
  padding: 1.5rem 2rem;
  border-radius: 0.75rem;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  color: ${Colors.primary};
  box-shadow: 0 0 4px 2px #e5e0e0;

  @media (max-width: 768px) {
    margin: 1rem 0;
    padding: 1.5rem 1rem;
    justify-content: space-around;
  }
`;

const ButtonAddSection = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background-color: ${Colors.backgroundPink};
  color: ${Colors.primary};
  padding: 0.75rem;
  border-radius: 0.75rem;
  font-weight: 500;
  margin: 1rem 0 1.5rem;
`;

const Text = styled.div`
  min-width: 100px;
  border-radius: 0.75rem;
  padding: 1rem;
  border: 1px solid ${(props) => (props.invalid ? "red" : "#e4e4e4")};
  background-color: ${(props) => (props.invalid ? "#e7d5d5" : Colors.white)};
  @media (max-width: 576px) {
    padding: 0.6rem;
  }
`;

const IconsBox = styled.div`
  width: 6rem;
  height: 7rem;
  cursor: pointer;
  border: 1px solid #e4e4e4;
  border-radius: 0.75rem;
  background-color: ${Colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &:hover {
    border: 1px solid ${Colors.primary};
  }
  img {
    max-width: 3rem;
    max-height: 3rem;
  }
  @media (max-width: 576px) {
    width: 5rem;
    height: 6rem;
    img {
      max-width: 2rem;
      max-height: 2rem;
    }
  }
`;

const ReviewBox = styled.div`
  padding: 1.5rem;
  border: 1px solid #a9abb3;
  border-radius: 0.75rem;
  position: relative;

  .edit-delete-wrapper {
    display: none;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  &:hover .edit-delete-wrapper {
    display: block;
  }

  @media (max-width: 576px) {
    padding: 1rem;
  }
`;

const ImageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 12.5rem;
  padding: 1.125rem;
  border-radius: 0.75rem;
  border: dashed 1px #a9abb3;
  position: relative;

  svg {
    display: none;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  &:hover svg {
    display: flex;
  }

  @media (max-width: 576px) {
    padding: 0.5rem;
  }
`;

const EditContainer = styled.div`
  .edit_imgBtn {
    visibility: hidden;
  }
  :hover .edit_imgBtn {
    visibility: visible;
  }
`;

const FileDownloadContainer = styled.div`
  cursor: pointer;
  background: #e5eaf0;
  border: 1px solid #e9e9e9;
  border-radius: 0.75rem;
  padding: 1rem;
  margin: 0px 0px 6px 0px;
  font-weight: 500;
  color: ${Colors.primary};
  transition: all 0.3s ease;

  &:hover {
    text-decoration: underline 1.5px;
  }

  @media (max-width: 576px) {
    padding: 0.5rem;
  }
`;

export default {
  AddSectionContainer,
  AddSectionBox,
  ButtonAddSection,
  IconsBox,
  ReviewBox,
  ImageBox,
  Text,
  EditContainer,
  FileDownloadContainer,
};
