import styled from "styled-components";
import Colors from "../../../../../Constants/Colors";

const ReviewBox = styled.div`
  width: 100%;
  height: auto;
  padding: 1.5rem;
  border: 1px solid #a9abb3;
  border-radius: 0.75rem;
  position: relative;

  svg {
    display: none;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  &:hover svg {
    display: block;
  }

  @media (max-width: 576px) {
    padding: 1.5rem 1rem;
  }
`;

const inputBox = styled.div`
  height: 3.15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(props) => (props.invalid ? "red" : Colors.borderInput)};
  border-radius: 0.75rem;
  padding: 0 1rem;
  background: ${Colors.white};

  &:hover {
    border: 1px solid ${Colors.primary};
  }
`;

const EditServiceContainer = styled.div`
  .edit_services_imgBtn {
    visibility: hidden;
  }
  :hover .edit_services_imgBtn {
    visibility: visible;
  }
`;

export default {
  ReviewBox,
  inputBox,
  EditServiceContainer,
};
