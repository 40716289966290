import styled from "styled-components";
import Colors from "../../../../Constants/Colors";

const ReviewContainer = styled.div`
  width: 100%;
  padding: 1rem 1.5rem;
  boxshadow: 0px 0px 9px 0px lightgrey;
  background: ${Colors.white};
  @media (max-width: 576px) {
    padding: 1rem 0.5rem;
  }
`;

const BrandLogoBox = styled.div`
  margin-top: 1rem;
  padding: 1.5rem;
  height: 9.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props) =>
    props.logoAlignment === "left"
      ? "start"
      : props.logoAlignment === "right"
      ? "end"
      : props.logoAlignment};
  border-radius: 0.75rem;
  border: 1px solid #a9abb3;
  cursor: pointer;
  position: relative;

  svg {
    display: none;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  &:hover svg {
    display: block;
  }
`;

const ReviewBox = styled.div`
  padding: 1.5rem;
  border: 1px solid #a9abb3;
  border-radius: 0.75rem;

  .edit_client_info .edit_client_infoBtn {
    visibility: hidden;
  }
  .edit_client_info:hover .edit_client_infoBtn {
    visibility: visible;
  }

  .edit_your_info .edit_your_infoBtn {
    visibility: hidden;
  }
  .edit_your_info:hover .edit_your_infoBtn {
    visibility: visible;
  }

  @media (max-width: 576px) {
    padding: 1.5rem 1rem;
  }
`;

const EditProject = styled.div`
  position: relative;

  svg {
    display: none;
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
  }

  &:hover svg {
    display: block;
  }
`;

const TableHead = styled.div`
  border-radius: 0.75rem;
  padding: 1rem;
  background-color: ${Colors.tableHeadColor};
`;

const TableCell = styled.div`
  border-radius: 0.75rem;
  padding: 1rem;
  border-bottom: 2px solid #f4efef;
`;

const SubmitButton = styled.button`
  min-width: 9.375rem;
  position: fixed;
  height: 3.15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 3px;
  bottom: 3px;
  /* padding: 1rem 1.25rem; */
  font-family: Poppins, serif;
  font-size: 0.875rem;
  color: ${(props) => props.color || Colors.white};
  border-radius: 0.75rem;
  background-color: ${(props) => props.backgroundColor || Colors.primary};
  transition: background-color 0.4s ease;
  &:hover {
    background-color: ${(props) =>
      props.backgroundColor || Colors.hoverButtonColor};
  }

  &:active {
    background-color: ${(props) => props.backgroundColor || Colors.primary};
  }
`;

export default {
  ReviewContainer,
  BrandLogoBox,
  ReviewBox,
  EditProject,
  TableHead,
  TableCell,
  SubmitButton,
};
