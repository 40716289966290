import React from "react";
import TextStyles from "../../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";

const Compensation = (props) => {
  const selectedData = props && props.selectedData;

  const heading =
    selectedData &&
    selectedData.compensation &&
    selectedData.compensation.heading
      ? selectedData &&
        selectedData.compensation &&
        selectedData.compensation.heading
      : "Compensation";
  const paragraph =
    selectedData &&
    selectedData.compensation &&
    selectedData.compensation.paragraph
      ? selectedData &&
        selectedData.compensation &&
        selectedData.compensation.paragraph
      : `Client will pay the Contractor a compensation at the decided rate (Per hour, day, item or word), based on invoices submitted by Contractor to the Client on basis (Once, weekly, bi-weekly, monthly) within the due date of receipt of said invoices. Contractor acknowledges and agrees that Contractor must accurately and truthfully report to the Client the number of working hours per each pay period (the “Payroll Worksheet”).`;

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.compensation &&
          selectedData.compensation.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox className="mt-4">
        <TextStyles.FontSize20px className="mb-3 fw-medium">
          {heading}
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px className="lh-lg" style={{ whiteSpace: "break-spaces" }}>
          {paragraph}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
    </div>
  );
};

export default Compensation;
