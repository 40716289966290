import { createSlice } from "@reduxjs/toolkit";
import {
  fetchNewlyUsers,
  fetchUsersCount,
  fetchUsersByUserType,
  fetchUsersByCountry,
  fetchUsersData,
  fetchUsers,
  fetchDeletedUsers,
  deleteDeletedUser,
} from "../service/AdminService";

const initialAdminState = {
  isLoading: false,
  error: false,
  newlyUsers: [],
  usersCount: {},
  usersByUserType: {},
  usersByCountry: [],
  usersData: {},
  totalUsers: [],
  deletedUsers: [],
  totalUsersCount: null,
  totalDeletedUsers: null,
  toggleActiveUsersFilter: false,
  filteredActiveUsersData: {},
  activeUserSortingData: {},
  toggleDeletedUsersFilter: false,
  filteredDeletedUsersData: {},
  deletedUserSortingData: {},
};

const adminSlice = createSlice({
  name: "admin",
  initialState: initialAdminState,
  reducers: {
    filteredActiveUsersAction: (state, action) => {
      state.filteredActiveUsersData = {
        ...state.filteredActiveUsersData,
        ...action.payload,
      };
    },
    toggleActiveUsersAction: (state) => {
      state.toggleActiveUsersFilter = !state.toggleActiveUsersFilter;
    },
    sortingActiveUserAction: (state, action) => {
      state.activeUserSortingData = {
        ...state.activeUserSortingData,
        ...action.payload,
      };
    },
    filteredDeletedUsersAction: (state, action) => {
      state.filteredDeletedUsersData = {
        ...state.filteredDeletedUsersData,
        ...action.payload,
      };
    },
    toggleDeletedUsersAction: (state) => {
      state.toggleDeletedUsersFilter = !state.toggleDeletedUsersFilter;
    },
    sortingDeletedUserAction: (state, action) => {
      state.deletedUserSortingData = {
        ...state.deletedUserSortingData,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder

      // fetch newly users
      .addCase(fetchNewlyUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchNewlyUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.newlyUsers = action.payload.data;
      })
      .addCase(fetchNewlyUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // fetch users count
      .addCase(fetchUsersCount.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUsersCount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.usersCount = action.payload.data;
      })
      .addCase(fetchUsersCount.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // fetch users by usertype
      .addCase(fetchUsersByUserType.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUsersByUserType.fulfilled, (state, action) => {
        state.isLoading = false;
        state.usersByUserType = action.payload.data;
      })
      .addCase(fetchUsersByUserType.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // fetch users by country
      .addCase(fetchUsersByCountry.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUsersByCountry.fulfilled, (state, action) => {
        state.isLoading = false;
        state.usersByCountry = action.payload.data;
      })
      .addCase(fetchUsersByCountry.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // fetch users data
      .addCase(fetchUsersData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUsersData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.usersData = action.payload.data;
      })
      .addCase(fetchUsersData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // fetch users
      .addCase(fetchUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.totalUsers = action.payload.data.totalUsers;
        state.totalUsersCount = action.payload.data.totalUsersCount;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // fetch deleted users
      .addCase(fetchDeletedUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDeletedUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.deletedUsers = action.payload.data.deletedUsers;
        state.totalDeletedUsers = action.payload.data.totalDeletedUsers;
      })
      .addCase(fetchDeletedUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // delete deleted users
      .addCase(deleteDeletedUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteDeletedUser.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteDeletedUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const adminActions = adminSlice.actions;
export default adminSlice.reducer;
