import styled from "styled-components";
import Colors from "../../../../../Constants/Colors";

const ReviewContainer = styled.div`
  width: 100%;
  padding: 1rem 1.5rem;
  background: ${Colors.white};
  @media (max-width: 576px) {
    padding: 1rem 0.5rem;
  }
`;

const BrandLogoBox = styled.div`
  margin-top: 1rem;
  padding: 1.5rem;
  height: 9.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props) =>
    props.logoAlignment === "left"
      ? "start"
      : props.logoAlignment === "right"
      ? "end"
      : props.logoAlignment};
  border-radius: 0.75rem;
  border: 1px solid #a9abb3;
`;

const ReviewBox = styled.div`
  padding: 1.5rem;
  border: 1px solid #a9abb3;
  border-radius: 0.75rem;
`;

const TableHead = styled.div`
  border-radius: 0.75rem;
  padding: 1rem;
  background-color: ${Colors.tableHeadColor};
`;

const TableCell = styled.div`
  border-radius: 0.75rem;
  padding: 1rem;
  border-bottom: 2px solid #f4efef;
`;

export default {
  ReviewContainer,
  BrandLogoBox,
  ReviewBox,
  TableHead,
  TableCell,
};
