import React from "react";
import styles from "./SignupForm2.module.css";
import { useForm } from "react-hook-form";
import PasswordToggle from "../auth/PasswordToggle";
import { useHistory } from "react-router-dom";
import logo from "../../Assets/assetsnew/frontend_logo_with_name.webp";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import Colors from "../../Constants/Colors";

const SignupForm2 = () => {
  const history = useHistory();
  const [InputType, Icons] = PasswordToggle();

  const {
    register,
    reset,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const validateEmail = (value) => {
    const trimmedValue = value.trim();
    const emailPattern = /^[A-Za-z0-9+._-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2}/i;
    return (
      emailPattern.test(trimmedValue) || "Please enter a valid email address."
    );
  };

  const onSubmit = (data) => {
    const obj = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      password: data.password,
    };
    history.push({
      pathname: "/db/signup",
      selectedData: obj,
    });
    reset();
  };

  return (
    <div className={styles.content_box}>
      <div className="d-flex justify-content-center mb-2">
        <img
          src={logo}
          width="100%"
          style={{ maxWidth: "15rem" }}
          alt="site-logo"
        />
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-100 mb-3">
          <label htmlFor="firstName" className={`${styles.input_text} mb-2`}>
            First name
          </label>
          <div className="position-relative">
            <input
              className={`${styles.input_rectangle} ${
                errors.firstName ? `${styles.input_error}` : ""
              }`}
              style={{ paddingLeft: "2.75rem" }}
              type="text"
              id="firstName"
              placeholder="George"
              {...register("firstName", {
                required: "First name can not be empty.",
                validate: (value) => {
                  let trimedLength = value.trim().length;
                  if (trimedLength === 0) {
                    return "First name can not be empty.";
                  }
                  return true;
                },
              })}
            />
            <span
              style={{
                position: "absolute",
                top: "50%",
                left: "1rem",
                transform: "translateY(-50%)",
              }}
            >
              <PersonOutlineOutlinedIcon
                sx={{ fontSize: 24, color: Colors.frontendFont2 }}
              />
            </span>
          </div>

          {errors.firstName && (
            <div className={`${styles.error_text}`}>
              {errors.firstName.message}
            </div>
          )}
        </div>

        <div className="w-100 mb-3">
          <label htmlFor="lastName" className={`${styles.input_text} mb-2`}>
            Last name
          </label>
          <div className="position-relative">
            <input
              className={`${styles.input_rectangle} ${
                errors.lastName ? `${styles.input_error}` : ""
              }`}
              style={{ paddingLeft: "2.75rem" }}
              type="text"
              id="lastName"
              placeholder="Johnson"
              {...register("lastName", {
                required: "Last name can not be empty.",
                validate: (value) => {
                  let trimedLength = value.trim().length;
                  if (trimedLength === 0) {
                    return "Last name can not be empty.";
                  }
                  return true;
                },
              })}
            />
            <span
              style={{
                position: "absolute",
                top: "50%",
                left: "1rem",
                transform: "translateY(-50%)",
              }}
            >
              <PersonOutlineOutlinedIcon
                sx={{ fontSize: 24, color: Colors.frontendFont2 }}
              />
            </span>
          </div>

          {errors.lastName && (
            <div className={`${styles.error_text}`}>
              {errors.lastName.message}
            </div>
          )}
        </div>

        <div className="w-100 mb-3">
          <label htmlFor="email" className={`${styles.input_text} mb-2`}>
            Email
          </label>
          <div className="position-relative">
            <input
              className={`${styles.input_rectangle} ${
                errors.email ? `${styles.input_error}` : ""
              }`}
              style={{ paddingLeft: "2.75rem" }}
              type="text"
              name="email"
              id="email"
              placeholder="Email address"
              {...register("email", {
                required: "Email can not be empty.",
                validate: validateEmail,
                onBlur: (e) => setValue("email", e.target.value.trim()),
              })}
              // {...register("email", {
              //   required: "Email can not be empty.",
              //   pattern: {
              //     value: /^[A-Za-z0-9+._]+@[A-Za-z0-9]+\.[A-Za-z]{2}/i,
              //     message: "Please enter valid email address.",
              //   },
              // })}
            />
            <span
              style={{
                position: "absolute",
                top: "50%",
                left: "1rem",
                transform: "translateY(-50%)",
              }}
            >
              <MailOutlineIcon
                sx={{ fontSize: 22, color: Colors.frontendFont2 }}
              />
            </span>
          </div>

          {errors.email && (
            <div className={`${styles.error_text}`}>{errors.email.message}</div>
          )}
        </div>

        <div className="w-100 mb-3">
          <label htmlFor="password" className={`${styles.input_text} mb-2`}>
            Password
          </label>

          <div className="position-relative">
            <input
              className={`${styles.input_rectangle} ${
                errors.password ? `${styles.input_error}` : ""
              }`}
              style={{ paddingLeft: "2.75rem" }}
              type={InputType}
              name="password"
              id="password"
              placeholder="Password"
              {...register("password", {
                required: "Password can not be empty.",
                pattern: {
                  value:
                    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/i,
                  message:
                    "Please enter minimum six characters, at least one letter, one number and one special character",
                },
              })}
            />
            <span
              style={{
                position: "absolute",
                top: "50%",
                left: "1rem",
                transform: "translateY(-50%)",
              }}
            >
              <LockOutlinedIcon sx={{ fontSize: 22, color: Colors.font2 }} />
            </span>
            <span
              style={{
                position: "absolute",
                top: "50%",
                right: "1rem",
                transform: "translateY(-50%)",
                cursor: "pointer",
                filter: "invert(1)",
              }}
            >
              {Icons}
            </span>
          </div>

          {errors.password && (
            <div className={`${styles.error_text}`}>
              {errors.password.message}
            </div>
          )}
        </div>

        <div className={`${styles.button_width} w-100 mt-4 mb-3`}>
          <button className={styles.button}>Sign up for free!</button>
        </div>
      </form>

      <p className="text-white m-0" style={{ fontSize: "0.8rem" }}>
        By registering, you are accepting our terms of service, privacy policy,
        and agreeing to receive marketing communications from Zodot. You can
        unsubscribe at any time.
      </p>
    </div>
  );
};

export default SignupForm2;
