import styled from "styled-components";
import Colors from "../../../../../Constants/Colors";

const TodoRectangle = styled.div`
  width: 100%;
  height: 4.5rem;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  cursor: pointer;
  background-color: ${Colors.white};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  contain: inline-size;

  &:hover {
    background: ${Colors.backgroundPink};
  }
`;

const Input = styled.input`
  width: 100%;
  max-width: 500px;
  margin: 0 0.7rem;
  font-size: 14px;
  background-color: ${(props) => (props.invalid ? Colors.red : Colors.white)};
  caret-color: ${Colors.font2};
`;

export default {
  TodoRectangle,
  Input,
};
