import React from "react";
import Styled from "../PageOne/PageOne";
import TextStyles from "../../../../../../Constants/TextStyles";

const ListAddClause = ({ data }) => {
  const heading = data && data.heading ? data.heading : "";

  const paragraph = data && data.paragraph ? data.paragraph : "";

  return (
    <div
      style={{
        display: data && data.required === true ? "inline" : "none",
      }}
    >
      <Styled.ReviewContentBox className="mt-4">
        <TextStyles.FontSize20px className="mb-3 fw-medium three-dots" style={{maxWidth: 580}}>
          {heading}
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px className="lh-lg" style={{ whiteSpace: "break-spaces" }}>
          {paragraph}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
    </div>
  );
};

export default ListAddClause;
