import styled from "styled-components";

const ReviewContentBox = styled.div`
  padding: 1.25rem;
  border-radius: 0.75rem;
  border: 1px solid #b2b6c5;
`;

export default {
  ReviewContentBox,
};
