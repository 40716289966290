import styled from "styled-components";
import Colors from "../../../../Constants/Colors";

const ReviewBox = styled.div`
  width: 100%;
  max-width: 50rem;
  height: auto;
  padding: 1.5rem;
  border-radius: 0.75rem;
  border: solid 1px #b2b6c5;
  background-color: ${Colors.white};
  .review_content_padding {
    padding: 0 2rem;
  }

  @media (max-width: 576px) {
    padding: 1.5rem 0.5rem;
    .review_content_padding {
      padding: 0 0rem;
    }
  }
`;

const EditButtonToolTip = styled.div`
  display: ${(props) => (props.hover ? "block" : "none")};
  z-index: 105;
  position: absolute;
  width: 6.25rem;
  font-family: Poppins, serif;
  font-size: 0.875rem;
  cursor: pointer;
  color: ${(props) => props.color || Colors.white};
  background-color: ${(props) => props.backgroundColor || Colors.success};
  padding: 1rem;
  border-radius: 8px;
`;

const ArrowBox = styled.div`
  cursor: pointer;
  &:hover {
    border-radius: 6px;
    border: 1px solid #b2b6c5;
    background-color: ${Colors.tableHeadColor};
  }
`;

const inputBox = styled.div`
  border-radius: 0.75rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  margin-right: 3px;
  height: 3.437rem;
  border: 1px solid ${(props) => (props.invalid ? "red" : Colors.font2)};
  background-color: ${(props) => (props.invalid ? "#e7d5d5" : Colors.white)};
`;

const SubmitButton = styled.button`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 100;
  min-width: fit-content;
  min-width: 6rem;
  padding: 0 1.5rem;
  height: 3.15rem;
  font-family: Poppins, serif;
  font-size: 0.875rem;
  color: ${(props) => props.color || Colors.white};
  border-radius: 0.75rem;
  background-color: ${(props) => props.backgroundColor || Colors.primary};
  transition: background-color 0.4s ease;
  &:hover {
    background-color: ${(props) =>
      props.backgroundColor
        ? `${props.backgroundColor}`
        : `${Colors.hoverButtonColor}`};
  }

  &:active {
    background-color: ${(props) =>
      props.backgroundColor ? props.backgroundColor : `${Colors.primary}`};
  }

  &:disabled {
    opacity: 0.5 !important;
  }
`;

export default {
  ReviewBox,
  EditButtonToolTip,
  ArrowBox,
  inputBox,
  SubmitButton,
};
