import React, { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";

import TextStyles from "../../../../../Constants/TextStyles";
import Styled from "./PageOne";

import EditContractDateDetails from "./EditContractDateDetails.jsx";
import Colors from "../../../../../Constants/Colors.js";

const ContractDateDetails = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);

  return (
    <Styled.ReviewContentBox className="mt-4">
      <TextStyles.FontSize14px>
        This Agreement (the "Contract") is made and entered into by the parties
        listed below (the "Parties"). This offer is valid until the close of
        business on
        <span className="mx-2" style={{ color: Colors.primary }}>
          {selectedData &&
            selectedData.contract_expire_date &&
            moment(selectedData.contract_expire_date).format("DD MMM YYYY")}
        </span>
        and it must be accepted in writing by counter-signing this Agreement by
        the specified date to be considered valid.
      </TextStyles.FontSize14px>
      <BorderColorOutlinedIcon
        className="edit-icon"
        onClick={() => setShowModal(true)}
        sx={{
          cursor: "pointer",
          color: Colors.font2,
          opacity: 0.8,
          "&:hover": { opacity: 1 },
        }}
      />
      {showModal && (
        <EditContractDateDetails
          show={showModal}
          handleClose={() => setShowModal(false)}
          selectedData={selectedData}
        />
      )}
    </Styled.ReviewContentBox>
  );
};

export default ContractDateDetails;
