import styled from "styled-components";
import Colors from "../../../../../Constants/Colors";

const ReviewBox = styled.div`
  width: 100%;
  height: auto;
  padding: 1.5rem;
  border: 1px solid #a9abb3;
  border-radius: 0.75rem;
  position: relative;

  svg {
    display: none;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  &:hover svg {
    display: block;
  }

  @media (max-width: 576px) {
    padding: 1.5rem 1rem;
  }
`;

const inputBox = styled.div`
  width: 100%;
  border-radius: 0.75rem;
  padding: 1rem;
  background: ${Colors.white};
  height: 3.15rem;
  border: 1px solid ${(props) => (props.invalid ? "red" : Colors.font2)};

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

const Selected = styled.div`
  padding: 1rem 1.25rem;
  border-radius: 0.75rem;
  display: flex;
  justify-content: center;
  cursor: pointer;
  background-color: ${Colors.backgroundPink};
  color: ${Colors.primary};
`;

const NotSelected = styled(Selected)`
  color: ${Colors.font2} !important;
  background-color: ${Colors.tableHeadColor};
`;

const EditContainer = styled.div`
  /* cursor: pointer;
  .edit_imgBtn {
    visibility: hidden;
  }
  :hover .edit_imgBtn {
    visibility: visible;
  } */
`;

export default {
  ReviewBox,
  inputBox,
  Selected,
  NotSelected,
  EditContainer,
};
