import React, { useState } from "react";
import { useSelector } from "react-redux";

import EditServices from "../../ProposalReviews/Services/EditServices";

import TextStyles from "../../../../../Constants/TextStyles";
import Styled from "../PageOne/PageOne";
import servicesIcon from "../../../../../Assets/assetsnew/servicesIcon.png";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import Colors from "../../../../../Constants/Colors";

const Services = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="mt-4">
      <Styled.ReviewContentBox>
        <BorderColorOutlinedIcon
          className="edit-icon"
          onClick={() => setShowModal(true)}
          sx={{
            cursor: "pointer",
            color: Colors.font2,
            opacity: 0.8,
            "&:hover": {
              opacity: 1,
            },
          }}
        />
        <div className="d-flex align-items-center gap-2 gap-md-3">
          <div style={{ maxWidth: 42 }}>
            <img className="w-100" src={servicesIcon} alt="servicesIcon" />
          </div>
          <TextStyles.FontSize20px className="fw-medium">Services summary</TextStyles.FontSize20px>
        </div>

        {selectedData &&
          selectedData.service_list &&
          selectedData.service_list.map((item, index) => {
            return (
              <Styled.EditServiceContainer key={index}>
                <div
                  className="my-3"
                  style={{
                    width: "100%",
                    height: "0.5px",
                    backgroundColor: "#b2b6c5",
                  }}
                />

                <div className="d-flex justify-content-between">
                  <div>
                    <TextStyles.FontSize16px
                      className="d-flex mb-2"
                      style={{ minWidth: 100 }}
                    >
                      <span className="three-dots fw-medium"> {item.service_name}</span>
                    </TextStyles.FontSize16px>
                    <TextStyles.GreyFont14px
                      className="d-flex mb-2"
                      style={{ minWidth: 100 }}
                    >
                      <span className="three-dots">
                        {selectedData &&
                          selectedData.currency &&
                          selectedData.currency.symbol}
                        {item.service_rate
                          ? parseFloat(item.service_rate).toFixed(2)
                          : "00.00"}
                        {item.service_rate_type !== "Flat fee"
                          ? ` ${item.service_rate_type.toLocaleLowerCase()}`
                          : ` ${item.service_rate_type.toLocaleLowerCase()}`}
                      </span>
                    </TextStyles.GreyFont14px>
                    <TextStyles.GreyFont14px
                      className="d-flex"
                      style={{ maxWidth: 500 }}
                    >
                      {item.desc}
                    </TextStyles.GreyFont14px>
                  </div>
                </div>
              </Styled.EditServiceContainer>
            );
          })}
        {showModal && (
          <EditServices
            show={showModal}
            handleClose={() => setShowModal(false)}
            selectedData={selectedData}
          />
        )}
      </Styled.ReviewContentBox>
    </div>
  );
};

export default Services;
