import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { message } from "antd";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateMiscellaneousPC } from "../../../../../../store/service/proposalAndContractService";
import MiscellaneousModal from "../../PageModals/MiscellaneousModal";
import Colors from "../../../../../../Constants/Colors";

const Miscellaneous = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const heading =
    selectedData &&
    selectedData.miscellaneous &&
    selectedData.miscellaneous.heading
      ? selectedData.miscellaneous.heading
      : "Miscellaneous";

  const paragraph =
    selectedData &&
    selectedData.miscellaneous &&
    selectedData.miscellaneous.paragraph
      ? selectedData.miscellaneous.paragraph
      : `1. Section and subsection headings in this Agreement are for convenience only and will not be used in interpreting the Agreement. Neither party will be considered the drafter of this Agreement for interpreting any ambiguity.\n\n2.This Agreement may be signed with manual or facsimile signatures and in counterparts, each of which will be considered an original, and together they will constitute one and the same instrument. If any provision is found illegal or unenforceable, it will be revised to give maximum effect to its original intent or eliminated if not permissible, while the rest of the Agreement remains enforceable.\n\nSeverability: If any provision of this Agreement is held invalid or unenforceable by a court of competent jurisdiction, such invalidity shall not affect the validity or operation of any other provision, and the invalid provision shall be severed from the Agreement.\n\nAssignability: This Agreement and the rights and obligations with respect to Contractor are personal and may not be assigned by Contractor without prior written consent from Client. Clients may assign this Agreement to a successor or purchaser of a portion of its assets.`;

  const isMiscellaneousRequired =
    selectedData &&
    selectedData.miscellaneous &&
    selectedData.miscellaneous.required
      ? true
      : false;

  const toggleMiscellaneousViewHandler = () => {
    const Obj = {
      _id: selectedData._id,
      miscellaneous: {
        required: isMiscellaneousRequired ? false : true,
        heading: isMiscellaneousRequired ? heading : null,
        paragraph: isMiscellaneousRequired ? paragraph : null,
      },
    };
    dispatch(updateMiscellaneousPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        message.success(
          isMiscellaneousRequired
            ? "Clause hidden successfully!"
            : "Clause unhidden successfully!"
        );
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div>
      <Styled.ReviewContentBox className="mt-4">
        <div className="edit-delete-icons">
          <span onClick={toggleMiscellaneousViewHandler}>
            {isMiscellaneousRequired ? (
              <VisibilityOffOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            ) : (
              <VisibilityOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            )}
          </span>

          {isMiscellaneousRequired && (
            <BorderColorOutlinedIcon
              sx={{
                cursor: "pointer",
                color: Colors.font2,
                opacity: 0.8,
                marginLeft: 2,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={() => setShowModal(true)}
            />
          )}
        </div>

        <TextStyles.FontSize20px className="mb-2">
          <span
            className={`fw-medium ${isMiscellaneousRequired ? "" : "disabled"}`}
          >
            {heading}
          </span>
        </TextStyles.FontSize20px>

        <TextStyles.FontSize14px
          className={`lh-lg ${isMiscellaneousRequired ? "" : "disabled"}`}
          style={{ whiteSpace: "break-spaces" }}
        >
          {paragraph}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
      {showModal && (
        <MiscellaneousModal
          selectedData={selectedData}
          heading={heading}
          paragraph={paragraph}
          show={showModal}
          handleClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default Miscellaneous;
