import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { message } from "antd";
import { deleteAddSectionImagePC } from "../../../../../../store/service/proposalAndContractService";

import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../AddSectionStyles";
import RemoveModal from "../../../../Commons/RemoveModal";
import EditAddSectionImageModal from "./EditAddSectionImageModal";
import Colors from "../../../../../../Constants/Colors";

import imageBtn from "../../../../../../Assets/assetsnew/image-new.svg";

const Image = ({ data, pc_id, position }) => {
  const dispatch = useDispatch();
  const [editModal, setEditModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const handleCloseRemoveModal = () => setShowRemoveModal(false);

  const deleteHandler = () => {
    const Obj = {
      pc_id: pc_id,
      _id: data._id,
      image_name: data.image_name,
      position: position,
    };
    dispatch(deleteAddSectionImagePC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div className="mt-4">
      <Styled.ReviewBox>
        <div className="edit-delete-wrapper">
          <BorderColorOutlinedIcon
            onClick={() => setEditModal(true)}
            sx={{
              cursor: "pointer",
              color: Colors.font2,
              opacity: 0.8,
              "&:hover": {
                opacity: 1,
              },
            }}
          />
          <CloseOutlinedIcon
            onClick={() => {
              setShowRemoveModal(true);
              setConfirmDialog({
                onConfirm: () => deleteHandler(),
              });
            }}
            sx={{
              ...{
                cursor: "pointer",
                color: Colors.font2,
                marginLeft: 1,
                opacity: 0.8,
                "&:hover": {
                  opacity: 1,
                },
              },
            }}
          />
        </div>

        <div className="d-flex align-items-center gap-2 gap-md-3 mb-4">
          <img src={imageBtn} alt="ImageBtn" style={{ maxWidth: "2.5rem" }} />

          <TextStyles.FontSize18px className="fw-medium">
            Image
          </TextStyles.FontSize18px>
        </div>

        <TextStyles.FontSize14px>
          <div className="mb-2 fw-medium">Image title</div>
          <Styled.Text className="three-dots mb-3">
            {data.image_title}
          </Styled.Text>

          <div className="mb-2 fw-medium">Image description</div>
          <Styled.Text className="three-dots">{data.image_text}</Styled.Text>
        </TextStyles.FontSize14px>

        <div className="my-3">
          <TextStyles.FontSize14px className="mb-2 fw-medium">
            Attached image
          </TextStyles.FontSize14px>

          <Styled.ImageBox>
            {data && data.image_name && data.image_url ? (
              <div style={{ maxWidth: 400 }}>
                <img
                  className="w-100"
                  src={data && data.image_name && data.image_url}
                  alt="imageBtn"
                />
              </div>
            ) : (
              <TextStyles.GreyFont16px>
                There is no image uploaded!
              </TextStyles.GreyFont16px>
            )}
          </Styled.ImageBox>
        </div>
      </Styled.ReviewBox>
      {editModal && (
        <EditAddSectionImageModal
          show={editModal}
          handleClose={() => setEditModal(false)}
          editData={data}
          position={position}
          pc_id={pc_id}
        />
      )}
      <RemoveModal
        title={"image"}
        confirmDialog={confirmDialog}
        showRemoveModal={showRemoveModal}
        handleCloseRemoveModal={handleCloseRemoveModal}
      />
    </div>
  );
};

export default Image;
