import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { message } from "antd";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateTerminationRequestPC } from "../../../../../../store/service/proposalAndContractService";
import Colors from "../../../../../../Constants/Colors";

import FeedbackModal from "../../PageModals/FeedbackModal.jsx";

const Feedback = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const heading = "Feedback";

  const isFeedbackRequired =
    selectedData &&
    selectedData.number_of_termination_request &&
    selectedData.number_of_termination_request.required
      ? true
      : false;

  const toggleFeedbackViewHandler = () => {
    const Obj = {
      _id: selectedData && selectedData._id,
      number_of_termination_request: {
        required: isFeedbackRequired ? false : true,
        num_of_termination_request_require: isFeedbackRequired ? false : true,
        num_request: "0",
      },
    };
    dispatch(updateTerminationRequestPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        message.success(
          isFeedbackRequired
            ? "Clause hidden successfully!"
            : "Clause unhidden successfully!"
        );
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div>
      <Styled.ReviewContentBox className="mt-4">
        <div className="edit-delete-icons">
          <span onClick={toggleFeedbackViewHandler}>
            {isFeedbackRequired ? (
              <VisibilityOffOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            ) : (
              <VisibilityOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            )}
          </span>

          {isFeedbackRequired && (
            <BorderColorOutlinedIcon
              sx={{
                cursor: "pointer",
                color: Colors.font2,
                opacity: 0.8,
                marginLeft: 2,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={() => setShowModal(true)}
            />
          )}
        </div>

        <TextStyles.FontSize20px className="mb-3">
          <span className={`fw-medium ${isFeedbackRequired ? "" : "disabled"}`}>
            {heading}
          </span>
        </TextStyles.FontSize20px>

        <TextStyles.FontSize14px
          className={`lh-lg ${isFeedbackRequired ? "" : "disabled"}`}
        >
          <div>
            <div>
              Client will have the opportunity to provide feedback up to
              <span
                className="mx-1"
                style={{ color: Colors.primary, fontWeight: 500 }}
              >
                {selectedData.number_of_termination_request.num_request}
              </span>{" "}
              times and Contractor will make revisions up to
              <span
                className="mx-1"
                style={{ color: Colors.primary, fontWeight: 500 }}
              >
                {selectedData.number_of_termination_request.num_request}
              </span>{" "}
              times based on the provided feedback.
            </div>
            <div>
              <div className="mt-3">
                a.Clients as well as contractors must establish a process for
                providing feedback, such as scheduled review meetings or
                periodic feedback sessions.
              </div>
              <div className="mt-3">
                b. Both the parties define the preferred method of feedback
                communication, whether it's in person, email, or through the
                project management system.
              </div>
              <div className="mt-3">
                c. Client is expected to provide feedback on deliverables or
                milestones within a specific time frame.
              </div>
            </div>
          </div>
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>

      {/* modal section below */}
      {showModal && (
        <FeedbackModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          heading={heading}
          selectedData={selectedData}
        />
      )}
    </div>
  );
};

export default Feedback;
