import React, { useEffect } from "react";

import HomePage from "./Components/HomePage/HomePage.jsx";
import TaskManagements from "./Components/Features/TaskManagements/TaskManagements.jsx";
import Invoicing from "./Components/Features/Invoices/Invoicing.jsx";
import Agreements from "./Components/Features/Agreements/Agreements.jsx";
import Transactions from "./Components/Features/Transactions/Transactions.jsx";
import Statements from "./Components/Features/Statements/Statements.jsx";
import TimeTracking from "./Components/Features/TimeTracking/TimeTracking.jsx";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import PageNotFound from "./Components/path404error/patherror.jsx";
import Layout from "./HOC/Layout/Layout";
import Reviews from "./Components/Reviews/Reviews.jsx";
import WhyZodot from "./Components/WhyZodot/WhyZodot.jsx";
import TermsOfService from "./Components/TermsOfService/TermsOfservice.jsx";
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy.jsx";
import ContactUs from "./Components/ContactUs/ContactUs.jsx";

import ScrollTop from "./HOC/ScrollTop/ScrollTop";
import "react-dev-utils/webpackHotDevClient";

import DashboardDrawer from "./Components/NewDashboard/DashboardDrawer";

import Login from "./Components/auth/Login";
import Signup from "./Components/auth/SignUp";
import EmailVerify from "./Components/auth/EmailVerify";
import ForgotPassword from "./Components/auth/ForgotPassword.jsx";
import resetPassword from "./Components/auth/ChangePassword.js";
import SuccessSignup from "./Components/auth/SuccessSignup.jsx";

import ProtectedRoute from "./Components/auth/ProtectedRoute";

import CreateAgreements from "./Components/NewDashboard/Agreements/CreateAgreements/CreateAgreements";
import EditAgreements from "./Components/NewDashboard/Agreements/EditAgreements/EditAgreement";

import CreateInvoice from "./Components/NewDashboard/invoices/CreateInvoice/CreateInvoice";
import EditInvoice from "./Components/NewDashboard/invoices/EditInvoice/EditInvoice";
import ResendInvoice from "./Components/NewDashboard/invoices/ResendInvoice/ResendInvoice.jsx";

// project related invoices
import CreateInvoiceInProject from "./Components/NewDashboard/projects/editProject/Invoices/CreateInvoice/CreateInvoice";
import EditInvoiceInProject from "./Components/NewDashboard/projects/editProject/Invoices/EditInvoice/EditInvoice";

// client realated invoices
import CreateInvoiceInClient from "./Components/NewDashboard/clients/editClient/Invoices/CreateInvoice/CreateInvoice.jsx";
import EditInvoiceInClient from "./Components/NewDashboard/clients/editClient/Invoices/EditInvoice/EditInvoice.jsx";

import LaunchToSetting from "./Components/NewDashboard/DB/LaunchToSetting";
import PublicViewInvoice from "./Components/NewDashboard/invoices/PublicViewInvoice/PublicViewInvoice.jsx";
import PublicViewAgreements from "./Components/NewDashboard/Agreements/PublicViewAgreements/ViewAgreements.jsx";
import ViewAgreements from "./Components/NewDashboard/Agreements/ViewAgreements/ViewAgreements.jsx";
import LaunchToTimers from "./Components/NewDashboard/DB/LaunchToTimers";
import LaunchToStatements from "./Components/NewDashboard/DB/LaunchToStatements.jsx";
import AOS from "aos";
import "aos/dist/aos.css";
import "./index.css";
import { useSelector } from "react-redux";
import LaunchToAdminDashboard from "./Components/NewDashboard/DB/LaunchToAdminDashboard.jsx";
import LaunchToUserInfo from "./Components/NewDashboard/DB/LaunchToUserInfo.jsx";
import LaunchToTasks from "./Components/NewDashboard/DB/LaunchToTasks.jsx";

const App = () => {
  const data = useSelector((state) => state.auth.data);
  window.addEventListener("storage", (event) => {
    if (event.key === "logout") {
      if (window.location.href.split("/")[3] === "db") {
        window.location.href = "/";
      }
    }
  });
  // window.addEventListener("storage", (event) => {
  //   if (event.key === "token") {
  //     window.location.reload();
  //   }
  // });

  useEffect(() => {
    AOS.init({
      delay: 20,
      duration: 500,
      easing: "linear",
      offset: 0, // Animation duration in milliseconds
    });

    return () => {
      AOS.refresh(); // Optionally refresh AOS on component unmount
    };
  }, []);

  const location = window.location.href.split("/")[3] === "db";
  useEffect(() => {
    if (location) {
      document.title = "Zodot";
    }
  }, [location]);

  return (
    <BrowserRouter>
      <Switch>
        {" "}
        <Layout>
          <ScrollTop />
          <Switch>
            <Route path="/" exact component={HomePage} />
            <Route path="/features/invoicing" exact component={Invoicing} />
            <Route path="/features/agreements" exact component={Agreements} />
            <Route
              path="/features/transactions"
              exact
              component={Transactions}
            />
            <Route
              path="/features/time-tracking"
              exact
              component={TimeTracking}
            />
            <Route
              path="/features/task-management"
              exact
              component={TaskManagements}
            />
            <Route path="/features/statements" exact component={Statements} />
            <Route path="/reviews" exact component={Reviews} />
            <Route path="/why-zodot" exact component={WhyZodot} />
            <Route path="/terms-of-service" exact component={TermsOfService} />
            <Route path="/privacy-policy" exact component={PrivacyPolicy} />
            <Route path="/contact" exact component={ContactUs} />
            <Route path="/db/signup" exact component={Signup} />
            <Route path="/db/signup-success" exact component={SuccessSignup} />
            <Route path="/db/login" exact component={Login} />
            <Route path="/db/forgot" exact component={ForgotPassword} />
            <Route
              path="/db/verify/:token/:email"
              exact
              component={EmailVerify}
            />
            <Route
              path="/db/reset-password/:resetPasswordToken"
              exact
              component={resetPassword}
            />
            <ProtectedRoute path="/db/home" exact component={DashboardDrawer} />
            <ProtectedRoute
              path="/db/tasks&notes"
              exact
              component={LaunchToTasks}
            />
            <ProtectedRoute
              path="/db/tasks&notes/tasks"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/tasks&notes/reminders"
              exact
              component={DashboardDrawer}
            />{" "}
            <ProtectedRoute
              path="/db/tasks&notes/notes"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/clients"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/clients/:id/tasks"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/clients/:id/address"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/clients/:id/projects"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/clients/:id/invoices"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/clients/:id/transactions"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/clients/:id/notes"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/clients/:id/invoices/create/quickInvoice"
              component={CreateInvoiceInClient}
            />
            <ProtectedRoute
              path="/db/clients/:id/invoices/create/review"
              component={CreateInvoiceInClient}
            />{" "}
            <ProtectedRoute
              path="/db/clients/:id/invoices/create/send"
              component={CreateInvoiceInClient}
            />
            <ProtectedRoute
              path="/db/clients/:id/invoices/:invoiceId/review"
              component={EditInvoiceInClient}
            />{" "}
            <ProtectedRoute
              path="/db/clients/:id/invoices/:invoiceId/send"
              component={EditInvoiceInClient}
            />
            <ProtectedRoute
              path="/db/projects"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/projects/:id/terms"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/projects/:id/tasks"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/projects/:id/timers"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/projects/:id/weekviews"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/projects/:id/reports"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/projects/:id/invoices"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/projects/:id/transactions"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/projects/:id/notes"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/projects/:id/invoices/create/setup"
              component={CreateInvoiceInProject}
            />
            <ProtectedRoute
              path="/db/projects/:id/invoices/create/review"
              component={CreateInvoiceInProject}
            />{" "}
            <ProtectedRoute
              path="/db/projects/:id/invoices/create/send"
              component={CreateInvoiceInProject}
            />
            <ProtectedRoute
              path="/db/projects/:id/invoices/:invoiceId/review"
              component={EditInvoiceInProject}
            />
            <ProtectedRoute
              path="/db/projects/:id/invoices/:invoiceId/send"
              component={EditInvoiceInProject}
            />
            <ProtectedRoute
              path="/db/transactions"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/timetracking"
              exact
              component={LaunchToTimers}
            />
            <ProtectedRoute
              path="/db/timetracking/timers"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/timetracking/weekview"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/timetracking/reports"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/agreements"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/agreements/create/setup"
              exact
              component={CreateAgreements}
            />
            <ProtectedRoute
              path="/db/agreements/create/reviewProposal"
              exact
              component={CreateAgreements}
            />{" "}
            <ProtectedRoute
              path="/db/agreements/create/reviewContract"
              exact
              component={CreateAgreements}
            />{" "}
            <ProtectedRoute
              path="/db/agreements/create/send"
              exact
              component={CreateAgreements}
            />
            <ProtectedRoute
              path="/db/agreements/:id/reviewProposal"
              exact
              component={EditAgreements}
            />
            <ProtectedRoute
              path="/db/agreements/:id/reviewContract"
              exact
              component={EditAgreements}
            />
            <ProtectedRoute
              path="/db/agreements/:id/send"
              exact
              component={EditAgreements}
            />
            <Route
              path="/db/agreements/view/public/:id/:token"
              exact
              component={PublicViewAgreements}
            />
            <ProtectedRoute
              path="/db/agreements/view/:id"
              exact
              component={ViewAgreements}
            />
            <ProtectedRoute
              path="/db/setting"
              exact
              component={LaunchToSetting}
            />
            <ProtectedRoute
              path="/db/setting/business"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/setting/business/business_info"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/setting/business/currency"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/setting/business/brand_logo"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/setting/templates"
              exact
              component={DashboardDrawer}
            />
            {/* <ProtectedRoute
              path="/db/setting/account"
              exact
              component={DashboardDrawer}
            /> */}
            <ProtectedRoute
              path="/db/setting/account/"
              exact
              component={LaunchToUserInfo}
            />
            <ProtectedRoute
              path="/db/setting/account/user_info"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/invoices"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/invoices/create"
              exact
              component={CreateInvoice}
            />
            <ProtectedRoute
              path="/db/invoices/create/setup"
              exact
              component={CreateInvoice}
            />
            <ProtectedRoute
              path="/db/invoices/create/setupProject"
              exact
              component={CreateInvoice}
            />
            <ProtectedRoute
              path="/db/invoices/create/setupClient"
              exact
              component={CreateInvoice}
            />
            <ProtectedRoute
              path="/db/invoices/create/quickInvoice"
              exact
              component={CreateInvoice}
            />
            <ProtectedRoute
              path="/db/invoices/create/createClient"
              exact
              component={CreateInvoice}
            />
            <ProtectedRoute
              path="/db/invoices/create/review"
              exact
              component={CreateInvoice}
            />
            <ProtectedRoute
              path="/db/invoices/create/send"
              exact
              component={CreateInvoice}
            />
            <ProtectedRoute
              path="/db/invoices/edit/:id/review"
              exact
              component={EditInvoice}
            />
            <ProtectedRoute
              path="/db/invoices/edit/:id/send"
              exact
              component={EditInvoice}
            />
            <ProtectedRoute
              path="/db/invoices/resend/:id/send"
              exact
              component={ResendInvoice}
            />
            <ProtectedRoute
              path="/db/invoices/view/:id"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/invoices/view/:id/details"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/invoices/view/:id/notes"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/statements"
              exact
              component={LaunchToStatements}
            />
            <ProtectedRoute
              path="/db/statements/incomes"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/statements/expenses"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/statements/taxes"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path={`${data && data.role === "admin" && "/db/admin"}`}
              exact
              component={LaunchToAdminDashboard}
            />
            <ProtectedRoute
              path={`${data && data.role === "admin" && "/db/admin/dashboard"}`}
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path={`${data && data.role === "admin" && "/db/admin/users"}`}
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path={`${
                data && data.role === "admin" && "/db/admin/deletedUsers"
              }`}
              exact
              component={DashboardDrawer}
            />
            <Route
              path="/db/invoices/view/public/:id/:token"
              exact
              component={PublicViewInvoice}
            />
            <Route path="*" component={PageNotFound} />
          </Switch>
        </Layout>
      </Switch>
    </BrowserRouter>
  );
};
export default App;
