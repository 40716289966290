import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { message } from "antd";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateForceMejeurePC } from "../../../../../../store/service/proposalAndContractService";
import ForceMejeureModal from "../../PageModals/ForceMejeureLawModal";
import Colors from "../../../../../../Constants/Colors";

const ForceMejeure = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const heading =
    selectedData &&
    selectedData.forceMejeure &&
    selectedData.forceMejeure.heading
      ? selectedData.forceMejeure.heading
      : "Force Majeure";

  const paragraph =
    selectedData &&
    selectedData.forceMejeure &&
    selectedData.forceMejeure.paragraph
      ? selectedData.forceMejeure.paragraph
      : `If a party's failure or omission in fulfilling any obligation under this Agreement is due to causes beyond their reasonable control, and such causes cannot be overcome through reasonable diligence, including but not limited to strikes, riots, war, acts of terrorism, acts of God, severe illness, invasion, fire, explosion, floods, and acts of government or governmental agencies or instrumentalities, it shall not be considered a breach of this Agreement or result in any liability for that party.`;

  const isForceMajeureRequired =
    selectedData &&
    selectedData.forceMejeure &&
    selectedData.forceMejeure.required
      ? true
      : false;

  const toggleForceMajeureViewHandler = () => {
    const Obj = {
      _id: selectedData._id,
      forceMejeure: {
        required: isForceMajeureRequired ? false : true,
        heading: isForceMajeureRequired ? heading : null,
        paragraph: isForceMajeureRequired ? paragraph : null,
      },
    };
    dispatch(updateForceMejeurePC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        message.success(
          isForceMajeureRequired
            ? "Clause hidden successfully!"
            : "Clause unhidden successfully!"
        );
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div>
      <Styled.ReviewContentBox className="mt-4">
        <div className="edit-delete-icons">
          <span onClick={toggleForceMajeureViewHandler}>
            {isForceMajeureRequired ? (
              <VisibilityOffOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            ) : (
              <VisibilityOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            )}
          </span>

          {isForceMajeureRequired && (
            <BorderColorOutlinedIcon
              sx={{
                cursor: "pointer",
                color: Colors.font2,
                opacity: 0.8,
                marginLeft: 2,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={() => setShowModal(true)}
            />
          )}
        </div>

        <TextStyles.FontSize20px className="mb-3">
          <span
            className={`fw-medium ${isForceMajeureRequired ? "" : "disabled"}`}
          >
            {heading}
          </span>
        </TextStyles.FontSize20px>

        <TextStyles.FontSize14px
          className={`lh-lg ${isForceMajeureRequired ? "" : "disabled"}`}
          style={{ whiteSpace: "break-spaces" }}
        >
          {paragraph}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>

      {showModal && (
        <ForceMejeureModal
          selectedData={selectedData}
          heading={heading}
          paragraph={paragraph}
          show={showModal}
          handleClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default ForceMejeure;
