import { createSlice } from "@reduxjs/toolkit";
import {
  createNote,
  deleteNote,
  fetchNotesForUser,
  updateNote,
} from "../service/notesService";

const initialState = {
  isLoading: false,
  totalUserNote: 0, // total notes for logged in user
  notes: [], // filtered notes default 10
  error: "",
  sortingNotesData: {
    selectedSortingOne: "createdAt",
    selectedSortingTwo: "desc",
  },
};

const noteSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    sort(state, action) {
      state.sortingNotesData = { ...state.sortingNotesData, ...action.payload };
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchNotesForUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchNotesForUser.fulfilled, (state, action) => {
        state.notes = action.payload.data.filteredNotes;
        state.totalUserNote = action.payload.data.totalNoteCount;
        state.isLoading = false;
      })
      .addCase(fetchNotesForUser.rejected, (state, action) => {
        state.error = action.error.message;
        state.isLoading = false;
      })

      // Create note
      .addCase(createNote.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createNote.fulfilled, (state, action) => {
        state.notes.push(action.payload.data);
        state.isLoading = false;
      })
      .addCase(createNote.rejected, (state, action) => {
        state.error = action.error.message;
        state.isLoading = false;
      })

      // Update note
      .addCase(updateNote.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateNote.fulfilled, (state, action) => {
        const note = state.notes[action.payload.index];
        note.title = action.payload.data.title;
        note.note = action.payload.data.note;
        note.priority = action.payload.data.priority;
        state.isLoading = false;
      })
      .addCase(updateNote.rejected, (state, action) => {
        state.error = action.error.message;
        state.isLoading = false;
      })

      // Delete note
      .addCase(deleteNote.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteNote.fulfilled, (state, action) => {
        state.notes = state.notes.filter((note) => note._id !== action.payload);
        state.isLoading = false;
      })
      .addCase(deleteNote.rejected, (state, action) => {
        state.error = action.error.message;
        state.isLoading = false;
      }),
});

export const noteActions = noteSlice.actions;
export default noteSlice.reducer;
