import styled from "styled-components";
import Colors from "../../../../../../Constants/Colors";
import sizes from "../../../../../../Constants/sizes";

const ReviewBox = styled.div`
  width: 100%;
  height: auto;
  padding: 1.5rem;
  border: 1px solid #a9abb3;
  border-radius: 0.75rem;

  @media (max-width: 576px) {
    padding: 1.5rem 1rem;
  }
`;

const SignatureBox = styled.div`
  width: 100%;
  max-width: 10.625rem;
  padding: 1rem;
  cursor: pointer;
  font-family: Poppins, serif;
  font-size: 0.875rem;
  color: ${(props) => props.color || Colors.white};
  border-radius: 0.75rem;
  background-color: ${(props) => props.backgroundColor || Colors.primary};
  transition: background-color 0.4s ease;
  &:hover {
    background-color: ${Colors.hoverButtonColor};
  }
`;

const SignatureImageBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 11.875rem;
  padding: 1rem;
  border-radius: 0.75rem;
  // border: solid 1px #b2b6c5;
  border: solid 1px ${(props) => (props.fileErrors ? "red" : "#b2b6c5")} !important;
  background-color: ${Colors.white} !important;
  box-sizing: border-box;
`;

const Button = styled.button`
  width: 100%;
  height: 3.15rem;
  font-family: Poppins, serif;
  font-size: 0.875rem;
  color: ${(props) => props.color || `${Colors.white}`};
  border-radius: ${sizes.formInputBorderRadius};
  border: 1px solid ${(props) => props.borderColor || "inherit"};
  background-color: ${(props) => props.backgroundColor || `${Colors.primary}`};
  transition: background-color 0.4s ease;
  &:hover {
    background-color: ${(props) =>
      props.backgroundColor
        ? `${props.backgroundColor}`
        : `${Colors.hoverButtonColor}`};
  }

  &:active {
    background-color: ${(props) =>
      props.backgroundColor ? props.backgroundColor : `${Colors.primary}`};
  }

  &:disabled {
    opacity: 0.5 !important;
  }
`;

export default {
  ReviewBox,
  SignatureBox,
  SignatureImageBox,
  Button,
};
