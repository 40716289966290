import React, { useEffect, useState } from "react";
import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";
import Styled from "./DashboardStyles";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import FolderSharedRoundedIcon from "@mui/icons-material/FolderSharedRounded";
import AccessAlarmsRoundedIcon from "@mui/icons-material/AccessAlarmsRounded";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useDispatch, useSelector } from "react-redux";
import NoUsersImage from "../../../../Assets/assetsnew/no-users.svg";
import LoaderSpin from "../../Commons/LoaderSpin";
import UserTypesGraph from "./UserTypesGraph";
import GoogleIcon from "@mui/icons-material/Google";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import {
  fetchNewlyUsers,
  fetchUsersCount,
  fetchUsersByUserType,
  fetchUsersByCountry,
  fetchUsersData,
} from "../../../../store/service/AdminService";
import FilterNewlyUsers from "../../Commons/FilterNewlyUsers";

const Dashboad = () => {
  const dispatch = useDispatch();
  const {
    newlyUsers,
    usersCount,
    usersByUserType,
    usersByCountry,
    usersData,
    isLoading,
  } = useSelector((state) => state.admin);

  const [filtered, setFiltered] = useState("Today");

  useEffect(() => {
    dispatch(fetchNewlyUsers({ filtered }));
  }, [filtered]);

  useEffect(() => {
    dispatch(fetchUsersCount());
    dispatch(fetchUsersByUserType());
    dispatch(fetchUsersByCountry({ limit: 10 }));
    dispatch(fetchUsersData());
  }, []);

  return (
    <div>
      <TextStyles.FontSize20px className="font-weight-500 my-3 my-md-4">
        Dashboard
      </TextStyles.FontSize20px>
      <div className="d-flex flex-wrap flex-lg-nowrap justify-content-center justify-content-lg-between gap-3 gap-lg-4 w-100">
        <Styled.UsersBox>
          <TextStyles.FontSize27px className="font-weight-600">
            {usersCount && usersCount.totalUsers}
          </TextStyles.FontSize27px>
          <TextStyles.GreyFont16px className="font-weight-500 mt-2 mb-3">
            Total Users
          </TextStyles.GreyFont16px>
          <PeopleAltIcon sx={{ fontSize: 60, color: Colors.primary }} />
        </Styled.UsersBox>
        <Styled.UsersBox>
          <TextStyles.FontSize27px className="font-weight-600">
            {usersCount && usersCount.totalActiveUsers}
          </TextStyles.FontSize27px>
          <TextStyles.GreyFont16px className="font-weight-500 mt-2 mb-3">
            <FiberManualRecordIcon
              sx={{ fontSize: 20, color: Colors.success }}
            />
            Active Users
          </TextStyles.GreyFont16px>
          <PeopleAltIcon sx={{ fontSize: 60, color: Colors.primary }} />
        </Styled.UsersBox>
        <Styled.UsersBox>
          <TextStyles.FontSize27px className="font-weight-600">
            {usersCount && usersCount.totalInactiveUsers}
          </TextStyles.FontSize27px>
          <TextStyles.GreyFont16px className="font-weight-500 mt-2 mb-3">
            <FiberManualRecordIcon
              sx={{ fontSize: 20, color: Colors.warning }}
            />
            Inactive Users
          </TextStyles.GreyFont16px>
          <PeopleAltIcon sx={{ fontSize: 60, color: Colors.primary }} />
        </Styled.UsersBox>
        <Styled.UsersBox>
          <TextStyles.FontSize27px className="font-weight-600">
            {usersCount && usersCount.totalDeletedUsers}
          </TextStyles.FontSize27px>
          <TextStyles.GreyFont16px className="font-weight-500 mt-2 mb-3">
            <FiberManualRecordIcon sx={{ fontSize: 20, color: Colors.red }} />
            Deleted Users
          </TextStyles.GreyFont16px>
          <PeopleAltIcon sx={{ fontSize: 60, color: Colors.primary }} />
        </Styled.UsersBox>
      </div>
      <div className="row g-4">
        {/* Left Section - Two-thirds width */}
        <div className="col-lg-8">
          <div className="d-flex justify-content-between align-items-center">
            <TextStyles.FontSize18px className="font-weight-500 my-3 my-md-4">
              New Users
            </TextStyles.FontSize18px>
            <TextStyles.HoverEffectSpan active={true}>
              <FilterNewlyUsers
                defaultValue={filtered}
                onChange={(e) => setFiltered(e.target.value)}
              />
            </TextStyles.HoverEffectSpan>
          </div>
        </div>

        {/* Right Section - One-third width */}
        <div className="col-lg-4 d-flex justify-content-center" />
      </div>

      <div className="row g-4">
        {/* Left Section - Two-thirds width */}
        <div className="col-lg-8">
          <Styled.TableHead>
            <div className="d-flex gap-2 align-items-center font-weight-500">
              <TextStyles.FontSize14px
                className="d-flex justify-content-start text-start"
                style={{ width: "100%", minWidth: 30 }}
              >
                <span className="three-dots">Name</span>
              </TextStyles.FontSize14px>

              <TextStyles.FontSize14px
                className="d-flex justify-content-start"
                style={{ width: "140%", minWidth: 30 }}
              >
                <span className="three-dots">Email</span>
              </TextStyles.FontSize14px>

              <TextStyles.FontSize14px
                className="d-flex justify-content-center"
                style={{ width: "80%", minWidth: 30 }}
              >
                <span className="three-dots">Country</span>
              </TextStyles.FontSize14px>
              <TextStyles.FontSize14px
                className="d-flex justify-content-center"
                style={{ width: "80%", minWidth: 30 }}
              >
                <span className="three-dots">User Type</span>
              </TextStyles.FontSize14px>
            </div>
          </Styled.TableHead>
          <div
            style={{
              overflow: "auto",
              background: Colors.white,
              height: 365,
              borderRadius: "0 0 12px 12px",
              boxShadow: "0px 1px 6px 0px rgba(0, 0, 0, 0.06)",
            }}
          >
            {newlyUsers && newlyUsers.length > 0 ? (
              newlyUsers.map((item, index) => {
                return (
                  <Styled.TableCell
                    key={index}
                    style={{
                      borderRadius:
                        newlyUsers.length - 1 === index ? "0 0 12px 12px" : "",
                    }}
                  >
                    <div className={`d-flex gap-2 align-items-center w-100`}>
                      <TextStyles.FontSize14px
                        className="d-flex justify-content-start text-start"
                        style={{ width: "100%", minWidth: 30 }}
                      >
                        <span className="three-dots">{`${item.firstName} ${item.lastName}`}</span>
                      </TextStyles.FontSize14px>

                      <TextStyles.FontSize14px
                        className="d-flex justify-content-start"
                        style={{ width: "140%", minWidth: 30 }}
                      >
                        <span className="three-dots">{item && item.email}</span>
                      </TextStyles.FontSize14px>

                      <TextStyles.FontSize14px
                        className="d-flex justify-content-center"
                        style={{ width: "80%", minWidth: 30 }}
                      >
                        <span className="three-dots">
                          {item &&
                            item.country &&
                            `${item.country.name}(${item.country.code})`}
                        </span>
                      </TextStyles.FontSize14px>

                      <TextStyles.FontSize14px
                        className="d-flex justify-content-center"
                        style={{ width: "80%", minWidth: 30 }}
                      >
                        <span className="three-dots">
                          {item && item.userType}
                        </span>
                      </TextStyles.FontSize14px>
                    </div>
                  </Styled.TableCell>
                );
              })
            ) : isLoading && newlyUsers && newlyUsers.length === 0 ? (
              <Styled.ListBox>
                <LoaderSpin color={Colors.primary} />
              </Styled.ListBox>
            ) : (
              !isLoading &&
              newlyUsers &&
              newlyUsers.length === 0 && (
                <Styled.ListBox>
                  <img src={NoUsersImage} alt="no-users-img" />
                  <TextStyles.GreyFont16px className="font-weight-500 mt-2">
                    There are no newly users yet.
                  </TextStyles.GreyFont16px>
                </Styled.ListBox>
              )
            )}
          </div>
        </div>

        {/* Right Section - One-third width */}
        <div className="col-lg-4 d-flex justify-content-center">
          <Styled.UsersTypeBox>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <TextStyles.FontSize18px className="font-weight-600">
                Total Signups
              </TextStyles.FontSize18px>
              <TextStyles.GreyFont18px className="font-weight-500">
                {usersByUserType && usersByUserType.totalUsers}
              </TextStyles.GreyFont18px>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <UserTypesGraph
                googleUsers={usersByUserType && usersByUserType.googleUsers}
                emailUsers={usersByUserType && usersByUserType.emailUsers}
                linkedinUsers={usersByUserType && usersByUserType.linkedinUsers}
              />
            </div>
            <div className="d-flex justify-content-between align-items-center font-weight-500 mb-2">
              <TextStyles.GreyFont14px>
                <GoogleIcon
                  sx={{
                    fontSize: 22,
                    color: Colors.white,
                    background: "#5b336f",
                    borderRadius: "3px",
                    padding: "2px",
                  }}
                />
                <span className="ps-2">Google</span>
              </TextStyles.GreyFont14px>
              <TextStyles.GreyFont14px>
                {usersByUserType && usersByUserType.googleUsers}
              </TextStyles.GreyFont14px>
            </div>
            <div className="d-flex justify-content-between align-items-center font-weight-500 mb-2">
              <TextStyles.GreyFont14px>
                <EmailIcon
                  sx={{
                    fontSize: 22,
                    color: Colors.white,
                    background: "#a770c3",
                    borderRadius: "3px",
                    padding: "2px",
                  }}
                />
                <span className="ps-2">Email</span>
              </TextStyles.GreyFont14px>
              <TextStyles.GreyFont14px>
                {usersByUserType && usersByUserType.emailUsers}
              </TextStyles.GreyFont14px>
            </div>
            <div className="d-flex justify-content-between align-items-center font-weight-500">
              <TextStyles.GreyFont14px>
                <LinkedInIcon
                  sx={{
                    fontSize: 22,
                    color: Colors.white,
                    background: "#c382e3",
                    borderRadius: "3px",
                    padding: "2px",
                  }}
                />
                <span className="ps-2">LinkedIn</span>
              </TextStyles.GreyFont14px>
              <TextStyles.GreyFont14px>
                {usersByUserType && usersByUserType.linkedinUsers}
              </TextStyles.GreyFont14px>
            </div>
          </Styled.UsersTypeBox>
        </div>
      </div>
      <div className="row g-4 my-2">
        {/* Left Section - Two-thirds width */}
        <div className="col-lg-8">
          <div className="d-flex flex-wrap justify-content-center justify-content-xl-evenly gap-3 gap-lg-4">
            <Styled.FeaturesBox1>
              <TextStyles.FontSize27px className="font-weight-600">
                {usersData && usersData.totalClients}
              </TextStyles.FontSize27px>
              <TextStyles.GreyFont16px className="font-weight-500 mt-2 mb-3">
                Total Clients
              </TextStyles.GreyFont16px>
              <PeopleAltIcon sx={{ fontSize: 60, color: Colors.primary }} />
            </Styled.FeaturesBox1>
            <Styled.FeaturesBox1>
              <TextStyles.FontSize27px className="font-weight-600">
                {usersData && usersData.totalProjects}
              </TextStyles.FontSize27px>
              <TextStyles.GreyFont16px className="font-weight-500 mt-2 mb-3">
                Total Projects
              </TextStyles.GreyFont16px>
              <FolderSharedRoundedIcon
                sx={{ fontSize: 60, color: Colors.primary }}
              />
            </Styled.FeaturesBox1>
            <Styled.FeaturesBox1>
              <TextStyles.FontSize27px className="font-weight-600">
                {usersData && usersData.totalTasks}
              </TextStyles.FontSize27px>
              <TextStyles.GreyFont16px className="font-weight-500 mt-2 mb-3">
                Total Tasks
              </TextStyles.GreyFont16px>
              <AssignmentIcon sx={{ fontSize: 60, color: Colors.primary }} />
            </Styled.FeaturesBox1>
            <Styled.FeaturesBox1>
              <TextStyles.FontSize27px className="font-weight-600">
                {usersData && usersData.totalTimerActivities}
              </TextStyles.FontSize27px>
              <TextStyles.GreyFont16px className="font-weight-500 mt-2 mb-3">
                Total Timer Activities
              </TextStyles.GreyFont16px>

              <AccessAlarmsRoundedIcon
                sx={{ fontSize: 60, color: Colors.primary }}
              />
            </Styled.FeaturesBox1>
          </div>
        </div>

        {/* Right Section - One-third width */}
        <div className="col-lg-4 d-flex justify-content-center">
          <Styled.TopCountriesBox>
            <TextStyles.FontSize18px className="font-weight-600 mb-4">
              Top {usersByCountry && usersByCountry.length} countries
            </TextStyles.FontSize18px>
            {usersByCountry &&
              usersByCountry.map((data, index) => {
                return (
                  <div
                    className={`d-flex justify-content-between align-items-center font-weight-500 ${
                      usersByCountry.length - 1 === index ? "" : "mb-3"
                    }`}
                    key={index}
                  >
                    <TextStyles.GreyFont14px>
                      <img
                        src={data.img}
                        alt={data.img}
                        style={{ width: 30 }}
                      />
                      <span className="ps-2">{`${data.country.name}(${data.country.code})`}</span>
                    </TextStyles.GreyFont14px>
                    <TextStyles.GreyFont14px>
                      {data.users}
                    </TextStyles.GreyFont14px>
                  </div>
                );
              })}
          </Styled.TopCountriesBox>
        </div>
      </div>
      <div className="d-flex flex-wrap flex-sm-nowrap justify-content-center justify-content-lg-between gap-4 gap-lg-5 w-100 mt-4">
        <Styled.FeaturesBox2 className="d-flex flex-column justify-content-between">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <TextStyles.FontSize18px className="font-weight-600">
              Total Invoices
            </TextStyles.FontSize18px>
            <TextStyles.GreyFont18px className="font-weight-500">
              {usersData && usersData.totalInvoices}
            </TextStyles.GreyFont18px>
          </div>
          <div>
            <div className="d-flex justify-content-between align-items-center font-weight-500 mb-1">
              <TextStyles.GreyFont14px>Quick Invoices</TextStyles.GreyFont14px>
              <TextStyles.GreyFont14px>
                {usersData && usersData.totalQuickInvoices}
              </TextStyles.GreyFont14px>
            </div>
            <div className="d-flex justify-content-between align-items-center font-weight-500 mb-1">
              <TextStyles.GreyFont14px>Others Invoices</TextStyles.GreyFont14px>
              <TextStyles.GreyFont14px>
                {usersData &&
                  usersData.totalInvoices - usersData.totalQuickInvoices}
              </TextStyles.GreyFont14px>
            </div>
          </div>
        </Styled.FeaturesBox2>
        <Styled.FeaturesBox2 className="d-flex flex-column justify-content-between">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <TextStyles.FontSize18px className="font-weight-600">
              Total Agreements
            </TextStyles.FontSize18px>
            <TextStyles.GreyFont18px className="font-weight-500">
              {usersData && usersData.totalAgreements}
            </TextStyles.GreyFont18px>
          </div>
          <div>
            <div className="d-flex justify-content-between align-items-center font-weight-500 mb-1">
              <TextStyles.GreyFont14px>Proposal</TextStyles.GreyFont14px>
              <TextStyles.GreyFont14px>
                {usersData && usersData.totalProposals}
              </TextStyles.GreyFont14px>
            </div>
            <div className="d-flex justify-content-between align-items-center font-weight-500 mb-1">
              <TextStyles.GreyFont14px>Contract</TextStyles.GreyFont14px>
              <TextStyles.GreyFont14px>
                {usersData && usersData.totalContracts}
              </TextStyles.GreyFont14px>
            </div>
            <div className="d-flex justify-content-between align-items-center font-weight-500 mb-1">
              <TextStyles.GreyFont14px>
                Proposal and contract
              </TextStyles.GreyFont14px>
              <TextStyles.GreyFont14px>
                {usersData && usersData.totalProposalAndContracts}
              </TextStyles.GreyFont14px>
            </div>
          </div>
        </Styled.FeaturesBox2>
      </div>
    </div>
  );
};

export default Dashboad;
