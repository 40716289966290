import React, { useState, useEffect } from "react";
import TextStyles from "../../../../Constants/TextStyles";
import Styles from "./ViewAgreementsStyles";

import { useParams } from "react-router-dom";
import { putRequest } from "../../../../axios/axios";

import ProposalReview from "../ViewAgreements/ViewProposals/Review";
import ContractReview from "../ViewAgreements/ViewContracts/Review";
import SignProposal from "../ViewAgreements/ViewProposals/Signatures/SignProposal";

import LoaderSpin from "../../Commons/LoaderSpin";
import Colors from "../../../../Constants/Colors";
import { message } from "antd";

const ViewAgreements = () => {
  const pcData = useParams();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [selectedData, setSelectedData] = useState(null);
  const [data, setData] = useState(null);
  const [businessInfoData, setBusinessInfoData] = useState(null);
  const [brandAndLogoData, setBrandAndLogoData] = useState(null);

  const [selector, setSelector] = useState("Proposal");

  useEffect(() => {
    viewPcCallHandler();
  }, [pcData]);

  const viewPcCallHandler = async () => {
    setIsLoading(true);
    putRequest(
      "pc/proposal_contract/public_view_pc",
      {},
      {
        _id: pcData.id,
        token: pcData.token,
      }
    )
      .then((data) => {
        if (data.data.success === true) {
          setIsLoading(false);
          setError(false);
          setSelectedData(data.data.data.selectedData);
          setData(data.data.data.data);
          setBusinessInfoData(data.data.data.businessInfoData);
          setBrandAndLogoData(data.data.data.brandAndLogoData);
        }
      })
      .catch((err) => {
        setError(true);
        setIsLoading(false);
        if (err && err.response && err.response.data) {
          return message.error(`${err.response.data.message}`);
        }
        if (err.isAxiosError) {
          return message.error(`${err.message}`);
        }
      });
  };

  return (
    <div>
      {isLoading ? (
        <TextStyles.FontSize14px
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoaderSpin color={Colors.primary} />
        </TextStyles.FontSize14px>
      ) : (
        <>
          <div
            className="fixed-top"
            style={{
              background: Colors.white,
              borderBottom: `2px solid ${
                brandAndLogoData && brandAndLogoData.brand_color
                  ? brandAndLogoData.brand_color
                  : Colors.primary
              }`,
              zIndex: 200,
              boxShadow: "0 2px 4px 2px rgba(0, 0, 0, .1)",
            }}
          >
            <TextStyles.FontSize27px
              className="d-flex justify-content-center"
              style={{
                minWidth: 100,
                fontWeight: 500,
                padding: "13px 50px 13px 13px",
              }}
            >
              <span className="three-dots">
                {selectedData &&
                selectedData.section &&
                selectedData.status !== "Approved"
                  ? `Approve ${selectedData.section.toLowerCase(1)}`
                  : selectedData &&
                    `View ${selectedData.section.toLowerCase(1)}`}
              </span>
            </TextStyles.FontSize27px>

            {selectedData &&
              selectedData.status &&
              selectedData.status !== "Approved" && (
                <TextStyles.FontSize16px
                  style={{
                    position: "absolute",
                    content: "",
                    right: 0,
                    top: 0,
                  }}
                >
                  <Styles.ApprovedBtn
                    bgColor={brandAndLogoData && brandAndLogoData.brand_color}
                    onClick={() => setShowModal(true)}
                  >
                    Sign and approve
                  </Styles.ApprovedBtn>
                </TextStyles.FontSize16px>
              )}
          </div>

          {error ? (
            <TextStyles.FontSize14px
              style={{
                width: "100vw",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextStyles.FontSize16px
                style={{
                  padding: "12px 20px",
                  borderRadius: 9,
                  border: `2px solid ${Colors.red}`,
                  color: Colors.red,
                }}
              >
                Invalid credential
              </TextStyles.FontSize16px>
            </TextStyles.FontSize14px>
          ) : (
            <div className="bg-light" style={{ paddingTop: 100 }}>
              {selectedData &&
                selectedData.section &&
                selectedData.section === "Proposal" && (
                  <ProposalReview
                    selectedData={selectedData}
                    businessInfoData={businessInfoData}
                    data={data}
                    brandAndLogoData={brandAndLogoData}
                  />
                )}

              {selectedData &&
                selectedData.section &&
                selectedData.section !== "Proposal" && (
                  <>
                    <div className="d-flex justify-content-center">
                      <Styles.SliderBox className="d-flex mt-2 mb-4">
                        <div
                          onClick={() => {
                            setSelector("Proposal");
                          }}
                        >
                          {selector === "Proposal" ? (
                            <Styles.SelectedItem
                              bgColor={
                                brandAndLogoData && brandAndLogoData.brand_color
                              }
                            >
                              {selectedData &&
                              selectedData.section === "Contract"
                                ? "Cover page"
                                : "Proposal"}
                            </Styles.SelectedItem>
                          ) : (
                            <Styles.NotSelectedItem
                              bgColor={
                                brandAndLogoData && brandAndLogoData.brand_color
                              }
                            >
                              {selectedData &&
                              selectedData.section === "Contract"
                                ? "Cover page"
                                : "Proposal"}
                            </Styles.NotSelectedItem>
                          )}
                        </div>
                        <div
                          onClick={() => {
                            setSelector("Contract");
                          }}
                        >
                          {selector === "Contract" ? (
                            <Styles.SelectedItem
                              bgColor={
                                brandAndLogoData && brandAndLogoData.brand_color
                              }
                            >
                              Contract
                            </Styles.SelectedItem>
                          ) : (
                            <Styles.NotSelectedItem
                              bgColor={
                                brandAndLogoData && brandAndLogoData.brand_color
                              }
                            >
                              Contract
                            </Styles.NotSelectedItem>
                          )}
                        </div>
                      </Styles.SliderBox>
                    </div>
                    {selector === "Proposal" && (
                      <ProposalReview
                        selectedData={selectedData}
                        businessInfoData={businessInfoData}
                        data={data}
                        brandAndLogoData={brandAndLogoData}
                      />
                    )}
                    {selector === "Contract" && (
                      <ContractReview
                        selectedData={selectedData}
                        businessInfoData={businessInfoData}
                        data={data}
                        brandAndLogoData={brandAndLogoData}
                      />
                    )}
                  </>
                )}
            </div>
          )}
        </>
      )}
      {showModal && (
        <SignProposal
          show={showModal}
          handleClose={() => setShowModal(false)}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          brandAndLogoData={brandAndLogoData}
          token={pcData.token}
        />
      )}
    </div>
  );
};

export default ViewAgreements;
