import { combineReducers, configureStore } from "@reduxjs/toolkit";

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import authReducer from "./storage/authSlice";
import clientReducer from "./storage/clientSlice";
import projectReducer from "./storage/projectSlice";
import transactionSlice from "./storage/transactionSlice";
import timeTrackingSlice from "./storage/timetrackingSlice";
// import dashboardSlice from "./storage/dashboardSlice";
import proposalAndContractSlice from "./storage/proposalAndContractSlice";
import statementSlice from "./storage/statementSlice";
import invoiceSlice from "./storage/invoiceSlice";
import reminderSlice from "./storage/reminderSlice";
import notificationSlice from "./storage/notificationSlice";
import deleteUserAcountSlice from "./storage/deleteUserAcountSlice";
import adminSlice from "./storage/adminSlice";
import noteReducer from "./storage/noteSlice";
import taskReducer from "./storage/TaskSlice";

const appReducer = combineReducers({
  auth: authReducer,
  tasks: taskReducer,
  clients: clientReducer,
  project: projectReducer,
  transaction: transactionSlice,
  timeTracking: timeTrackingSlice,
  proposalAndContract: proposalAndContractSlice,
  statements: statementSlice,
  invoices: invoiceSlice,
  reminders: reminderSlice,
  notifications: notificationSlice,
  deleteUserAccount: deleteUserAcountSlice,
  admin: adminSlice,
  notes: noteReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "authentication/logout") {
    storage.removeItem("persist:persist-root");
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const persistConfig = {
  key: "persist-root",
  storage,
  // whitelist: ["authReducer", "signUpReducer"],
  // blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: process.env.NODE_ENV !== "production",
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: process.env.NODE_ENV !== "production",
});

const persistor = persistStore(store);

export { store, persistor };
