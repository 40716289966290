import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { message } from "antd";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateHealthInsurancePC } from "../../../../../../store/service/proposalAndContractService";

import HealthInsuranceModal from "../../PageModals/HealthInsuranceModal.jsx";
import Colors from "../../../../../../Constants/Colors";

const HealthInsurance = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const heading =
    selectedData &&
    selectedData.health_insurance &&
    selectedData.health_insurance.heading
      ? selectedData &&
        selectedData.health_insurance &&
        selectedData.health_insurance.heading
      : "Health insurance and business liability insurance";
  const paragraph =
    selectedData &&
    selectedData.health_insurance &&
    selectedData.health_insurance.paragraph
      ? selectedData &&
        selectedData.health_insurance &&
        selectedData.health_insurance.paragraph
      : "In its capacity of an independent contractor, Contractor agrees that the Client is not obligated to procure business liability insurance for the Contractor and/or medical insurance for Contractor and/or Contractor’s family and that Contractor may procure such medical and/or business liability insurance at Contractor’s expense without any claim for reimbursement from the Client.";

  const isHealthInsuranceRequired =
    selectedData &&
    selectedData.health_insurance &&
    selectedData.health_insurance.required
      ? true
      : false;

  const toggleHealthInsuranceViewHandler = () => {
    const Obj = {
      _id: selectedData._id,
      health_insurance: {
        required: isHealthInsuranceRequired ? false : true,
        heading: isHealthInsuranceRequired ? heading : null,
        paragraph: isHealthInsuranceRequired ? paragraph : null,
      },
    };
    dispatch(updateHealthInsurancePC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        message.success(
          isHealthInsuranceRequired
            ? "Clause hidden successfully!"
            : "Clause unhidden successfully!"
        );
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div>
      <Styled.ReviewContentBox className="mt-4">
        <div className="edit-delete-icons">
          <span onClick={toggleHealthInsuranceViewHandler}>
            {isHealthInsuranceRequired ? (
              <VisibilityOffOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            ) : (
              <VisibilityOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            )}
          </span>

          {isHealthInsuranceRequired && (
            <BorderColorOutlinedIcon
              sx={{
                cursor: "pointer",
                color: Colors.font2,
                opacity: 0.8,
                marginLeft: 2,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={() => setShowModal(true)}
            />
          )}
        </div>

        <TextStyles.FontSize20px className="mb-3">
          <span
            className={`fw-medium ${
              isHealthInsuranceRequired ? "" : "disabled"
            }`}
          >
            {heading}
          </span>
        </TextStyles.FontSize20px>

        <TextStyles.FontSize14px
          className={`lh-lg ${isHealthInsuranceRequired ? "" : "disabled"}`}
        >
          {paragraph}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
      {showModal && (
        <HealthInsuranceModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          heading={heading}
          paragraph={paragraph}
          selectedData={selectedData}
        />
      )}
    </div>
  );
};

export default HealthInsurance;
