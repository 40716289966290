import React from "react";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import Styled from "./QuickTaskStyles";
import Colors from "../../../../../Constants/Colors";

const QuickTask = ({
  showInput,
  taskData,
  handleSubmit,
  formsubmit,
  register,
  RemoveInputHandler,
  isLoading,
}) => {
  return (
    <Styled.TodoRectangle
      style={{
        cursor: "auto",
        borderRadius:
          showInput && taskData && taskData.length === 0 ? "0 0 12px 12px" : "",
      }}
    >
      <form
        className="d-flex justify-content-start align-items-center w-100"
        onSubmit={handleSubmit(formsubmit)}
      >
        <RadioButtonUncheckedIcon
          className="radioButton"
          fontSize="medium"
          sx={{
            color: Colors.font2,
            cursor: "pointer",
          }}
        />
        <Styled.Input
          id="create_new_task"
          type="text"
          style={{ background: "none" }}
          placeholder="Write a to-do and hit enter..."
          name="task_name"
          {...register("task_name", {
            required: "Task name is required.",
          })}
          onKeyDownCapture={(event) => {
            if (!isLoading) {
              RemoveInputHandler(event);
            }
          }}
        />
      </form>
    </Styled.TodoRectangle>
  );
};

export default QuickTask;
