import React from "react";

import TextStyles from "../../../../../../../Constants/TextStyles";
import Styled from "../AddSectionStyles";
import imageBtn from "../../../../../../../Assets/assetsnew/image-new.svg";


const Image = ({ data }) => {
  return (
    <Styled.ReviewBox className="mt-4">
      <div className="d-flex align-items-center gap-3 mb-4">
        <img src={imageBtn} alt="ImageBtn" style={{ maxWidth: "2.5rem" }} />

        <TextStyles.FontSize18px className="fw-medium">
          Image
        </TextStyles.FontSize18px>
      </div>

      <TextStyles.FontSize14px>
        <div className="mb-2 fw-medium">Image title</div>
        <Styled.Text className="three-dots mb-3">
          {data.image_title}
        </Styled.Text>

        <div className="mb-2 fw-medium">Image description</div>
        <Styled.Text className="three-dots">{data.image_text}</Styled.Text>
      </TextStyles.FontSize14px>

      <div className="my-3">
        <TextStyles.FontSize14px className="mb-2 fw-medium">
          Attached image
        </TextStyles.FontSize14px>

        <Styled.ImageBox>
          {data && data.image_name && data.image_url ? (
            <div style={{ maxWidth: 400 }}>
              <img
                className="w-100"
                src={data && data.image_name && data.image_url}
                alt="imageBtn"
              />
            </div>
          ) : (
            <TextStyles.GreyFont16px>
              There is no image uploaded!
            </TextStyles.GreyFont16px>
          )}
        </Styled.ImageBox>
      </div>
    </Styled.ReviewBox>
  );
};

export default Image;
