import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { Modal } from "react-bootstrap";
import { message } from "antd";
import { contractExpireDatePC } from "../../../../../store/service/proposalAndContractService";
import LoaderSpin from "../../../Commons/LoaderSpin";
import ClearIcon from "@mui/icons-material/Clear";
import TextStyles from "../../../../../Constants/TextStyles";
import Colors from "../../../../../Constants/Colors";
import CustomDatePickerOne from "../../../Commons/DatePickers/CustomDatePickerOne";
import moment from "moment";

const EditContractDateDetails = ({ show, handleClose, selectedData }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.proposalAndContract.isLoading);

  const { reset, handleSubmit, control } = useForm({
    defaultValues: {
      contract_expire_date:
        selectedData &&
        selectedData.contract_expire_date &&
        moment(selectedData.contract_expire_date).toDate(),
    },
  });

  const formsubmit = (item) => {
    const Obj = {
      _id: selectedData._id,
      contract_expire_date: item.contract_expire_date
        ? moment(item.contract_expire_date).toDate()
        : null,
    };
    dispatch(contractExpireDatePC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        reset();
        message.success("Contract expire date updated successfully!");
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      contentClassName="ToMakeModalBorderRudius12px"
      centered={true}
      scrollable={true}
    >
      <form
        onSubmit={handleSubmit(formsubmit)}
        className="modal-content"
        style={{ height: "100%" }}
      >
        <Modal.Header
          className="d-flex justify-content-between align-items-center"
          style={{ borderBottom: "none" }}
        >
          <TextStyles.FontSize20px>Edit contract</TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="contract_expire_date" className="mb-2">
              Expiration date
            </label>
            <Controller
              name={"contract_expire_date"}
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <CustomDatePickerOne
                    onChange={onChange}
                    selected={value}
                    placeholder="Setect date"
                    allowClear={false}
                  />
                );
              }}
            />
          </TextStyles.FontSize14px>
        </Modal.Body>
        <Modal.Footer style={{ display: "inline-block" }}>
          <div className="d-flex gap-3 justify-content-between">
            <TextStyles.CancelButton onClick={handleClose}>
              Cancel
            </TextStyles.CancelButton>
            <TextStyles.Button disabled={isLoading}>
              {isLoading ? <LoaderSpin /> : "Save"}
            </TextStyles.Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditContractDateDetails;
