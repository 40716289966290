import React, { useState } from "react";
import { useSelector } from "react-redux";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";

import TextStyles from "../../../../../Constants/TextStyles";
import Styled from "./PageOne";

import PCEditProjectInfo from "../../ProposalReviews/PCEditProjectInfo";
import Colors from "../../../../../Constants/Colors";

const Project = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showProjectModal, setShowProjectModal] = useState(false);

  return (
    <Styled.ReviewContentBox className="mt-4">
      <TextStyles.FontSize16px>
        {selectedData && selectedData.project_name}
      </TextStyles.FontSize16px>
      <BorderColorOutlinedIcon
        className="edit-icon"
        onClick={() => setShowProjectModal(true)}
        sx={{
          cursor: "pointer",
          color: Colors.font2,
          opacity: 0.8,
          "&:hover": { opacity: 1 },
        }}
      />
      {showProjectModal && (
        <PCEditProjectInfo
          selectedData={selectedData}
          show={showProjectModal}
          handleClose={() => setShowProjectModal(false)}
        />
      )}
    </Styled.ReviewContentBox>
  );
};

export default Project;
