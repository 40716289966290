import React from "react";

import calendarsvg from "../../../../../../Assets/assetsnew/calendar.svg";
import moment from "moment";
import Styled from "./BillingSchedulesStyles";
import TextStyles from "../../../../../../Constants/TextStyles";

const BillingSchedules = ({ selectedData }) => {
  const billing_scheduled = selectedData && selectedData.billing_scheduled;

  return (
    <Styled.ReviewBox className="mt-4">
        <div className="d-flex gap-2 gap-md-3 align-items-center mb-3 w-100">
          <img src={calendarsvg} alt="calenderBtn" />
          <TextStyles.FontSize20px
            className="d-flex flex-column"
            style={{ minWidth: 50 }}
          >
            <TextStyles.FontSize20px className="three-dots fw-medium">
              Billing schedule
            </TextStyles.FontSize20px>
            <TextStyles.GreyFont14px className="three-dots">
              {billing_scheduled &&
              billing_scheduled.invoice_frequency &&
              billing_scheduled.invoice_frequency === "Once"
                ? "Contractor bills Client once"
                : billing_scheduled &&
                  billing_scheduled.invoice_frequency &&
                  billing_scheduled.invoice_frequency === "Weekly"
                ? "Contractor bills Client weekly"
                : billing_scheduled &&
                  billing_scheduled.invoice_frequency &&
                  billing_scheduled.invoice_frequency === "Bi-weekly"
                ? "Contractor bills Client Bi-weekly"
                : billing_scheduled &&
                  billing_scheduled.invoice_frequency &&
                  billing_scheduled.invoice_frequency === "Monthly"
                ? "Contractor bills Client monthly"
                : ""}
              {billing_scheduled &&
              billing_scheduled.deposit &&
              billing_scheduled.deposit.require_deposit === true
                ? ", plus deposit"
                : ""}
            </TextStyles.GreyFont14px>
          </TextStyles.FontSize20px>
        </div>
        <div
          className="my-3"
          style={{
            width: "100%",
            height: "0.5px",
            backgroundColor: "#a9abb3",
          }}
        />
        {billing_scheduled &&
          billing_scheduled.deposit &&
          billing_scheduled.deposit.require_deposit === true &&
          billing_scheduled.deposit.value && (
            <div>
              <div>
                <div className="d-flex gap-2 justify-content-between align-items-center">
                  <TextStyles.FontSize16px
                    className="d-flex"
                    style={{ minWidth: 50 }}
                  >
                    <span className="three-dots fw-medium">Deposit</span>
                  </TextStyles.FontSize16px>
                  <TextStyles.FontSize16px
                    className="d-flex justify-content-end"
                    style={{ minWidth: 50 }}
                  >
                    <span className="three-dots fw-medium">
                      {" "}
                      {selectedData &&
                        selectedData.currency &&
                        selectedData.currency.symbol}
                      {billing_scheduled &&
                        billing_scheduled.deposit &&
                        billing_scheduled.deposit.require_deposit === true &&
                        billing_scheduled.deposit.value &&
                        parseFloat(billing_scheduled.deposit.value).toFixed(2)}
                    </span>
                  </TextStyles.FontSize16px>
                </div>
                <TextStyles.GreyFont14px className="mt-2">
                  Deposit will be subtracted from subsequent invoices.
                </TextStyles.GreyFont14px>
              </div>
              <div
                className="my-3"
                style={{
                  width: "100%",
                  height: "0.5px",
                  backgroundColor: "#a9abb3",
                }}
              />
            </div>
          )}

        <div>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <TextStyles.FontSize16px className="fw-medium">
              {billing_scheduled &&
              billing_scheduled.invoice_frequency &&
              billing_scheduled.invoice_frequency === "Once"
                ? "Final invoice"
                : billing_scheduled &&
                  billing_scheduled.invoice_frequency &&
                  billing_scheduled.invoice_frequency === "Weekly"
                ? "Billed Weekly"
                : billing_scheduled &&
                  billing_scheduled.invoice_frequency &&
                  billing_scheduled.invoice_frequency === "Bi-weekly"
                ? "Billed Bi-weekly"
                : billing_scheduled &&
                  billing_scheduled.invoice_frequency &&
                  billing_scheduled.invoice_frequency === "Monthly"
                ? "Billed Monthly"
                : ""}
            </TextStyles.FontSize16px>
          </div>
          {billing_scheduled &&
            billing_scheduled.invoice_frequency === "Once" && (
              <TextStyles.GreyFont14px>
                {billing_scheduled.issue_invoice &&
                  moment(billing_scheduled.issue_invoice).format("DD MMM YYYY")}
              </TextStyles.GreyFont14px>
            )}

          {billing_scheduled &&
          billing_scheduled.invoice_frequency === "Weekly" &&
          billing_scheduled.weekly_invoice &&
          billing_scheduled.weekly_invoice.invoices_type === "Never" ? (
            <div className="d-flex flex-wrap gap-2">
              <TextStyles.FontSize14px>First invoice:</TextStyles.FontSize14px>
              <TextStyles.GreyFont14px>
                {billing_scheduled.weekly_invoice.start_date &&
                  moment(billing_scheduled.weekly_invoice.start_date).format(
                    "DD MMM YYYY"
                  )}
              </TextStyles.GreyFont14px>
            </div>
          ) : billing_scheduled &&
            billing_scheduled.invoice_frequency === "Weekly" &&
            billing_scheduled.weekly_invoice &&
            billing_scheduled.weekly_invoice.invoices_type === "After" ? (
            <div className="d-flex gap-1 flex-column">
              <div className="d-flex flex-wrap gap-2">
                <TextStyles.FontSize14px>
                  First invoice:
                </TextStyles.FontSize14px>
                <TextStyles.GreyFont14px>
                  {billing_scheduled.weekly_invoice.start_date &&
                    moment(billing_scheduled.weekly_invoice.start_date).format(
                      "DD MMM YYYY"
                    )}
                </TextStyles.GreyFont14px>
              </div>
              <div className="d-flex flex-wrap gap-2">
                <TextStyles.FontSize14px>Last invoice:</TextStyles.FontSize14px>
                <TextStyles.GreyFont14px>
                  {billing_scheduled.weekly_invoice.end_date &&
                    moment(billing_scheduled.weekly_invoice.end_date).format(
                      "DD MMM YYYY"
                    )}
                  {billing_scheduled.weekly_invoice.num_invoices &&
                    `(After ${billing_scheduled.weekly_invoice.num_invoices} invoices)`}
                </TextStyles.GreyFont14px>
              </div>
            </div>
          ) : billing_scheduled &&
            billing_scheduled.invoice_frequency === "Weekly" &&
            billing_scheduled.weekly_invoice &&
            billing_scheduled.weekly_invoice.invoices_type === "On" ? (
            <div className="d-flex gap-1 flex-column">
              <div className="d-flex flex-wrap gap-2">
                <TextStyles.FontSize14px>
                  First invoice:
                </TextStyles.FontSize14px>
                <TextStyles.GreyFont14px>
                  {billing_scheduled.weekly_invoice.start_date &&
                    moment(billing_scheduled.weekly_invoice.start_date).format(
                      "DD MMM YYYY"
                    )}
                </TextStyles.GreyFont14px>
              </div>
              <div className="d-flex flex-wrap gap-2">
                <TextStyles.FontSize14px>Last invoice:</TextStyles.FontSize14px>
                <TextStyles.GreyFont14px>
                  {billing_scheduled.weekly_invoice.end_date &&
                    moment(billing_scheduled.weekly_invoice.end_date).format(
                      "DD MMM YYYY"
                    )}
                  {billing_scheduled.weekly_invoice.num_invoices &&
                    `(After ${billing_scheduled.weekly_invoice.num_invoices} invoices)`}
                </TextStyles.GreyFont14px>
              </div>
            </div>
          ) : (
            ""
          )}

          {billing_scheduled &&
          billing_scheduled.invoice_frequency === "Bi-weekly" &&
          billing_scheduled.biWeekly_invoice &&
          billing_scheduled.biWeekly_invoice.invoices_type === "Never" ? (
            <div className="d-flex flex-wrap gap-2">
              <TextStyles.FontSize14px>First invoice:</TextStyles.FontSize14px>
              <TextStyles.GreyFont14px>
                {billing_scheduled.biWeekly_invoice.start_date &&
                  moment(billing_scheduled.biWeekly_invoice.start_date).format(
                    "DD MMM YYYY"
                  )}
              </TextStyles.GreyFont14px>
            </div>
          ) : billing_scheduled &&
            billing_scheduled.invoice_frequency === "Bi-weekly" &&
            billing_scheduled.biWeekly_invoice &&
            billing_scheduled.biWeekly_invoice.invoices_type === "After" ? (
            <div className="d-flex gap-1 flex-column">
              <div className="d-flex flex-wrap gap-2">
                <TextStyles.FontSize14px>
                  First invoice:
                </TextStyles.FontSize14px>
                <TextStyles.GreyFont14px>
                  {billing_scheduled.biWeekly_invoice.start_date &&
                    moment(
                      billing_scheduled.biWeekly_invoice.start_date
                    ).format("DD MMM YYYY")}
                </TextStyles.GreyFont14px>
              </div>
              <div className="d-flex flex-wrap gap-2">
                <TextStyles.FontSize14px>Last invoice:</TextStyles.FontSize14px>
                <TextStyles.GreyFont14px>
                  {billing_scheduled.biWeekly_invoice.end_date &&
                    moment(billing_scheduled.biWeekly_invoice.end_date).format(
                      "DD MMM YYYY"
                    )}
                  {billing_scheduled.biWeekly_invoice.num_invoices &&
                    `(After ${billing_scheduled.biWeekly_invoice.num_invoices} invoices)`}
                </TextStyles.GreyFont14px>
              </div>
            </div>
          ) : billing_scheduled &&
            billing_scheduled.invoice_frequency === "Bi-weekly" &&
            billing_scheduled.biWeekly_invoice &&
            billing_scheduled.biWeekly_invoice.invoices_type === "On" ? (
            <div className="d-flex gap-1 flex-column">
              <div className="d-flex flex-wrap gap-2">
                <TextStyles.FontSize14px>
                  First invoice:
                </TextStyles.FontSize14px>
                <TextStyles.GreyFont14px>
                  {billing_scheduled.biWeekly_invoice.start_date &&
                    moment(
                      billing_scheduled.biWeekly_invoice.start_date
                    ).format("DD MMM YYYY")}
                </TextStyles.GreyFont14px>
              </div>
              <div className="d-flex flex-wrap gap-2">
                <TextStyles.FontSize14px>Last invoice:</TextStyles.FontSize14px>
                <TextStyles.GreyFont14px>
                  {billing_scheduled.biWeekly_invoice.end_date &&
                    moment(billing_scheduled.biWeekly_invoice.end_date).format(
                      "DD MMM YYYY"
                    )}
                  {billing_scheduled.biWeekly_invoice.num_invoices &&
                    `(After ${billing_scheduled.biWeekly_invoice.num_invoices} invoices)`}
                </TextStyles.GreyFont14px>
              </div>
            </div>
          ) : (
            ""
          )}

          {billing_scheduled &&
          billing_scheduled.invoice_frequency === "Monthly" &&
          billing_scheduled.monthly_invoice &&
          billing_scheduled.monthly_invoice.invoices_type === "Never" ? (
            <div className="d-flex flex-wrap gap-2">
              <TextStyles.FontSize14px>First invoice:</TextStyles.FontSize14px>
              <TextStyles.GreyFont14px>
                {billing_scheduled.monthly_invoice.start_date &&
                  moment(billing_scheduled.monthly_invoice.start_date).format(
                    "DD MMM YYYY"
                  )}
              </TextStyles.GreyFont14px>
            </div>
          ) : billing_scheduled &&
            billing_scheduled.invoice_frequency === "Monthly" &&
            billing_scheduled.monthly_invoice &&
            billing_scheduled.monthly_invoice.invoices_type === "After" ? (
            <div className="d-flex gap-1 flex-column">
              <div className="d-flex flex-wrap gap-2">
                <TextStyles.FontSize14px>
                  First invoice:
                </TextStyles.FontSize14px>
                <TextStyles.GreyFont14px>
                  {billing_scheduled.monthly_invoice.start_date &&
                    moment(billing_scheduled.monthly_invoice.start_date).format(
                      "DD MMM YYYY"
                    )}
                </TextStyles.GreyFont14px>
              </div>
              <div className="d-flex flex-wrap gap-2">
                <TextStyles.FontSize14px>Last invoice:</TextStyles.FontSize14px>
                <TextStyles.GreyFont14px>
                  {billing_scheduled.monthly_invoice.end_date &&
                    moment(billing_scheduled.monthly_invoice.end_date).format(
                      "DD MMM YYYY"
                    )}
                  {billing_scheduled.monthly_invoice.num_invoices &&
                    `(After ${billing_scheduled.monthly_invoice.num_invoices} invoices)`}
                </TextStyles.GreyFont14px>
              </div>
            </div>
          ) : billing_scheduled &&
            billing_scheduled.invoice_frequency === "Monthly" &&
            billing_scheduled.monthly_invoice &&
            billing_scheduled.monthly_invoice.invoices_type === "On" ? (
            <div className="d-flex gap-1 flex-column">
              <div className="d-flex flex-wrap gap-2">
                <TextStyles.FontSize14px>
                  First invoice:
                </TextStyles.FontSize14px>
                <TextStyles.GreyFont14px>
                  {billing_scheduled.monthly_invoice.start_date &&
                    moment(billing_scheduled.monthly_invoice.start_date).format(
                      "DD MMM YYYY"
                    )}
                </TextStyles.GreyFont14px>
              </div>
              <div className="d-flex flex-wrap gap-2">
                <TextStyles.FontSize14px>Last invoice:</TextStyles.FontSize14px>
                <TextStyles.GreyFont14px>
                  {billing_scheduled.monthly_invoice.end_date &&
                    moment(billing_scheduled.monthly_invoice.end_date).format(
                      "DD MMM YYYY"
                    )}

                  {billing_scheduled.monthly_invoice.num_invoices &&
                    `(After ${billing_scheduled.monthly_invoice.num_invoices} invoices)`}
                </TextStyles.GreyFont14px>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </Styled.ReviewBox>
  );
};

export default BillingSchedules;
