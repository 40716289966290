import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { message } from "antd";

import ClearIcon from "@mui/icons-material/Clear";

import LoaderSpin from "../../../Commons/LoaderSpin";
import SelectPriority from "../../../Commons/SelectPriority";

import { createNote } from "../../../../../store/service/notesService";

import TextStyles from "../../../../../Constants/TextStyles";
import Colors from "../../../../../Constants/Colors";

function AddNote({ showAddNote, onCloseModal }) {
  const { isLoading } = useSelector((state) => state.notes);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [priority, setPriority] = useState("normal");

  const submitNoteHandler = (payload) => {
    dispatch(createNote({ ...payload, priority })).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        message.success(data.payload.message);
        onCloseModal();
      } else {
        message.error("Failed to add note");
      }
    });
  };

  return (
    <Modal
      dialogClassName="modal-width-1000"
      centered
      show={showAddNote}
      onHide={onCloseModal}
    >
      <form onSubmit={handleSubmit(submitNoteHandler)}>
        <Modal.Header className="d-flex justify-content-between align-items-center px-4">
          <TextStyles.FontSize20px>Add Note</TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": { opacity: 1 },
            }}
            onClick={onCloseModal}
          />
        </Modal.Header>

        <Modal.Body className="px-4">
          <TextStyles.FontSize14px className="d-flex gap-4 items-center justify-content-between">
            <div className="mb-3 w-100">
              <label htmlFor="title" className="mb-2">
                Title
              </label>

              <TextStyles.InputRectangle
                id="title"
                type="text"
                {...register("title", {
                  required: "Title is required.",
                })}
                disabled={isLoading}
              />

              {errors.title && errors.title.message && (
                <TextStyles.InValidFeedback>
                  {errors.title.message}
                </TextStyles.InValidFeedback>
              )}
            </div>

            <div>
              <label htmlFor="priority" className="mb-2">
                Select Priority
              </label>

              <div
                style={{
                  width: 110,
                  border: `1px solid ${Colors.borderInput}`,
                  borderRadius: "0.75rem",
                }}
              >
                <SelectPriority
                  value={priority}
                  onChange={(e) => setPriority(e.target.value)}
                />
              </div>
            </div>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px>
            <label htmlFor="note" className="mb-2">
              Note
            </label>

            <TextStyles.Textarea
              id="add-note"
              rows={10}
              {...register("note", {
                required: "Note is required.",
              })}
              disabled={isLoading}
            />

            {errors.note && errors.note.message && (
              <TextStyles.InValidFeedback>
                {errors.note.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>
        </Modal.Body>

        <Modal.Footer className="d-flex flex-nowrap gap-3 gap-md-4 justify-content-center align-items-center p-4 pt-1 border-0">
          <TextStyles.CancelButton
            className="fw-medium"
            onClick={onCloseModal}
            disabled={isLoading}
            style={{ maxWidth: "10rem" }}
          >
            Cancel
          </TextStyles.CancelButton>

          <TextStyles.Button
            className="fw-medium"
            type="submit"
            disabled={isLoading}
            style={{ maxWidth: "10rem" }}
          >
            {isLoading ? <LoaderSpin /> : "Add Note"}
          </TextStyles.Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default AddNote;
