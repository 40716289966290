import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import { Modal } from "react-bootstrap";
import styled from "styled-components";

import ClearIcon from "@mui/icons-material/Clear";

import { updateNote } from "../../../../../store/service/notesService";

import Colors from "../../../../../Constants/Colors";
import TextStyles from "../../../../../Constants/TextStyles";
import LoaderSpin from "../../../Commons/LoaderSpin";
import SelectPriority from "../../../Commons/SelectPriority";

const Input = styled.input`
  width: 100%;
  margin-bottom: 6px;
  background-color: ${Colors.white};
  border: 1px solid ${Colors.borderInput};
  padding: 0 1rem;
  height: 3.15rem;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border 0.4s ease;
  :focus {
    border: 1px solid ${Colors.primary};
  }

  &:hover {
    border: 1px solid ${Colors.primary};
  }
`;

function EditNote({ showEditNote, onCloseModal, selectedId }) {
  const { isLoading, notes } = useSelector((state) => state.notes);
  const dispatch = useDispatch();

  const selectedNote = notes.length ? notes[selectedId] : {};
  const [priority, setPriority] = useState(
    selectedNote ? selectedNote.priority : ""
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: selectedNote.title,
      note: selectedNote.note,
    },
  });

  const handleUpdateNote = (data) => {
    dispatch(
      updateNote({ index: selectedId, id: selectedNote._id, ...data, priority })
    ).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        message.success(data.payload.message);
        onCloseModal();
      } else {
        message.error("Failed to update note");
      }
    });
  };

  return (
    <Modal
      centered
      dialogClassName="modal-width-1000"
      show={showEditNote}
      onHide={onCloseModal}
    >
      <form onSubmit={handleSubmit(handleUpdateNote)}>
        <Modal.Header className="d-flex justify-content-between align-items-center px-4">
          <TextStyles.FontSize20px>Edit Note</TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": { opacity: 1 },
            }}
            onClick={onCloseModal}
          />
        </Modal.Header>

        <Modal.Body className="px-4">
          <TextStyles.FontSize14px className="d-flex gap-4 items-center justify-content-between">
            <div className="mb-3 w-100">
              <label htmlFor="title" className="mb-2">
                Title
              </label>

              <Input
                id="title"
                type="text"
                {...register("title", {
                  required: "Title is required.",
                })}
                disabled={isLoading}
              />

              {errors.title && errors.title.message && (
                <TextStyles.InValidFeedback>
                  {errors.title.message}
                </TextStyles.InValidFeedback>
              )}
            </div>

            <div>
              <label htmlFor="add-priority" className="mb-2">
                Select Priority
              </label>

              <div
                style={{
                  width: 110,
                  border: `1px solid ${Colors.borderInput}`,
                  borderRadius: "0.75rem",
                }}
              >
                <SelectPriority
                  value={priority}
                  onChange={(e) => setPriority(e.target.value)}
                />
              </div>
            </div>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px>
            <label htmlFor="note" className="mb-2">
              Note
            </label>
            <TextStyles.Textarea
              id="note"
              type="text"
              rows={10}
              {...register("note", {
                required: "Note is required.",
              })}
              disabled={isLoading}
            />

            {errors.note && errors.note.message && (
              <TextStyles.InValidFeedback>
                {errors.note.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>
        </Modal.Body>

        <Modal.Footer className="d-flex flex-nowrap gap-3 gap-md-4 justify-content-center align-items-center p-4 pt-1 border-0">
          <TextStyles.CancelButton
            className="fw-medium"
            onClick={onCloseModal}
            disabled={isLoading}
            style={{ maxWidth: "10rem" }}
          >
            Cancel
          </TextStyles.CancelButton>

          <TextStyles.Button
            className="fw-medium"
            type="submit"
            disabled={isLoading}
            style={{ maxWidth: "10rem" }}
          >
            {isLoading ? <LoaderSpin /> : "Update"}
          </TextStyles.Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default EditNote;
