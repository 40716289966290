import React from "react";

import TextStyles from "../../../../../../../Constants/TextStyles";
import Styled from "../AddSectionStyles";
import columnImage from "../../../../../../../Assets/assetsnew/column-image-new.svg";

const Column = ({ data }) => {
  return (
    <Styled.ReviewBox className="mt-4">
        <div className="d-flex align-items-center gap-3 mb-4">
          <img
            src={columnImage}
            alt="columnImage"
            style={{ maxWidth: "2.5rem" }}
          />

          <TextStyles.FontSize18px className="fw-medium">
            Column
          </TextStyles.FontSize18px>
        </div>

        <TextStyles.FontSize14px>
          <div className="mb-2 fw-medium">Column title</div>
          <Styled.Text className="three-dots mb-3">
            {data.column_title}
          </Styled.Text>

          <div className="mb-2 fw-medium">Column description</div>
          <div className="d-flex flex-wrap gap-3 flex-md-nowrap">
            <Styled.Text className="w-100">{data.input.input_one}</Styled.Text>
            <Styled.Text className="w-100">{data.input.input_two}</Styled.Text>
            <Styled.Text className="w-100">
              {data.input.input_three}
            </Styled.Text>
          </div>
        </TextStyles.FontSize14px>
      </Styled.ReviewBox>
  );
};

export default Column;
