import React, { useState } from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";

import Todo from "./Todo.jsx";
import Future from "./Future.jsx";
import Done from "./Done.jsx";

import Styled from "../../../clients/editClient/task/TaskStyles";
import TextStyles from "../../../../../Constants/TextStyles";
import Colors from "../../../../../Constants/Colors.js";
import { ReactTooltip } from "../../../Commons/ReactTooltip.js";

export default function task() {
  const [showInput, setShowInput] = useState(false);
  const [selector, setSelector] = useState("todo");
  const [selectedSortingOne, setSelectedSortingOne] = useState("orders");
  const [selectedSortingTwo, setSelectedSortingTwo] = useState("desc");

  const addNewInput = () => {
    setShowInput(true);
    setSelector("todo");
  };

  const updateSelector = (value) => {
    setSelector(value);
    setShowInput(false);
  };

  return (
    <div className="mt-5">
      <div className="d-flex justify-content-between align-items-center">
        <TextStyles.FontSize20px className="d-flex justify-content-start align-item-center">
          Tasks
        </TextStyles.FontSize20px>
        <Styled.Oval onClick={addNewInput}>
          <label htmlFor="create_new_task" className="mb-1">
            <AddOutlinedIcon
              sx={{
                fontSize: 22,
                color: Colors.white,
                cursor: "pointer",
              }}
            />
          </label>
        </Styled.Oval>
      </div>

      <Styled.SliderBox className="d-flex mt-2">
        <div
          onClick={() => {
            updateSelector("todo");
          }}
        >
          {selector === "todo" ? (
            <Styled.SelectedItem>To do</Styled.SelectedItem>
          ) : (
            <Styled.NotSelectedItem>To do</Styled.NotSelectedItem>
          )}
        </div>
        <div
          onClick={() => {
            updateSelector("future");
          }}
        >
          {selector === "future" ? (
            <Styled.SelectedItem>Future</Styled.SelectedItem>
          ) : (
            <Styled.NotSelectedItem>Future</Styled.NotSelectedItem>
          )}
        </div>
        <div
          onClick={() => {
            updateSelector("done");
          }}
        >
          {selector === "done" ? (
            <Styled.SelectedItem>Done</Styled.SelectedItem>
          ) : (
            <Styled.NotSelectedItem>Done</Styled.NotSelectedItem>
          )}
        </div>
      </Styled.SliderBox>
      <Styled.TodoRectangle className="mt-4">
        <TextStyles.FontSize14px
          className="d-flex gap-2 align-items-center w-100"
          style={{ fontWeight: 500 }}
        >
          <div
            className="d-flex justify-content-center align-items-center w-100"
            style={{ minWidth: 25, maxWidth: 25 }}
          />
          <div
            className="d-flex justify-content-start w-100"
            style={{ minWidth: 130 }}
          >
            <ReactTooltip anchorId="task_name" content="Sort by task name" />
            <span
              className="three-dots hover-task-name"
              id="task_name"
              role="button"
              onClick={() => {
                setSelectedSortingOne("task_name");
                if (selectedSortingTwo === "asc") {
                  setSelectedSortingTwo("desc");
                } else {
                  setSelectedSortingTwo("asc");
                }
              }}
            >
              Task name
              {selectedSortingOne === "task_name" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-task-name"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-task-name"
                    />
                  )}
                </>
              )}
            </span>
          </div>
          <div
            className="d-none d-md-flex justify-content-start w-100"
            style={{ maxWidth: 140, minWidth: 30 }}
          >
            <ReactTooltip anchorId="projects" content="Sort by project name" />
            <span
              className="three-dots hover-projects"
              id="projects"
              role="button"
              onClick={() => {
                setSelectedSortingOne("project_name");
                if (selectedSortingTwo === "asc") {
                  setSelectedSortingTwo("desc");
                } else {
                  setSelectedSortingTwo("asc");
                }
              }}
            >
              Project name
              {selectedSortingOne === "project_name" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-projects"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-projects"
                    />
                  )}
                </>
              )}
            </span>
          </div>

          <div
            className="d-none d-md-flex justify-content-start w-100"
            style={{ maxWidth: 130, minWidth: 30 }}
          >
            <ReactTooltip
              anchorId="client_name"
              content="Sort by client name"
            />
            <span
              className="three-dots hover-clients"
              id="client_name"
              role="button"
              onClick={() => {
                setSelectedSortingOne("company_name");
                if (selectedSortingTwo === "asc") {
                  setSelectedSortingTwo("desc");
                } else {
                  setSelectedSortingTwo("asc");
                }
              }}
            >
              Client name
              {selectedSortingOne === "company_name" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-clients"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-clients"
                    />
                  )}
                </>
              )}
            </span>
          </div>

          <div
            className="d-none d-md-flex justify-content-center w-100"
            style={{ maxWidth: 105, minWidth: 30 }}
          >
            <ReactTooltip anchorId="createdAt" content="Sort by created date" />
            <span
              className="three-dots hover-createdAt"
              id="createdAt"
              role="button"
              onClick={() => {
                setSelectedSortingOne("createdAt");
                if (selectedSortingTwo === "asc") {
                  setSelectedSortingTwo("desc");
                } else {
                  setSelectedSortingTwo("asc");
                }
              }}
            >
              CreatedAt
              {selectedSortingOne === "createdAt" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-createdAt"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-createdAt"
                    />
                  )}
                </>
              )}
            </span>
          </div>
          <div
            className="d-flex justify-content-center w-100"
            style={{ maxWidth: 105, minWidth: 30 }}
          >
            <ReactTooltip anchorId="due-date" content="Sort by due date" />
            <span
              className="three-dots hover-due-date"
              id="due-date"
              role="button"
              onClick={() => {
                setSelectedSortingOne("due_date");
                if (selectedSortingTwo === "asc") {
                  setSelectedSortingTwo("desc");
                } else {
                  setSelectedSortingTwo("asc");
                }
              }}
            >
              Due date
              {selectedSortingOne === "due-date" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-due-date"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-due-date"
                    />
                  )}
                </>
              )}
            </span>
          </div>
          <div
            className="d-none d-md-flex justify-content-end position-relative w-100"
            style={{ maxWidth: 207, minWidth: 207 }}
          />

          <div
            className="d-flex d-md-none justify-content-end position-relative w-100"
            style={{
              minWidth: 70,
              maxWidth: 70,
            }}
          />
        </TextStyles.FontSize14px>
      </Styled.TodoRectangle>
      {selector === "todo" && (
        <Todo
          showInput={showInput}
          setShowInput={setShowInput}
          selectedSortingOne={selectedSortingOne}
          selectedSortingTwo={selectedSortingTwo}
        />
      )}

      {selector === "future" && (
        <Future
          selectedSortingOne={selectedSortingOne}
          selectedSortingTwo={selectedSortingTwo}
        />
      )}
      {selector === "done" && (
        <Done
          selectedSortingOne={selectedSortingOne}
          selectedSortingTwo={selectedSortingTwo}
        />
      )}
    </div>
  );
}
