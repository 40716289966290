import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextStyles from "../../../../Constants/TextStyles";
import Styled from "./WeeklyViewStyles";
import Colors from "../../../../Constants/Colors";
import NoTimeTrackingImage from "../../../../Assets/assetsnew/no-time-tracking.svg";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import SelectWeekview from "../../Commons/SelectWeekview";

import SelectProjects from "../../Commons/SelectProjects";
import SelectClients from "../../Commons/SelectClients";
import SelectActivity from "../../Commons/SelectActivity";
import { message } from "antd";

import moment from "moment";
import {
  fetchWeekView,
  updateWeekviewActivityNames,
  createWeekViewSingleActivity,
  updateWeekViewSingleActivity,
  fetchAllActivityLists,
} from "../../../../store/service/timeTrackingService";
import EditWeekviewMultipleActivityTimes from "../TimerModals/EditWeekviewMultipleActivityTimes";
import dayjs from "dayjs";
import { convertSecondsToHHMM } from "../../Commons/utils";
import LoaderSpin from "../../Commons/LoaderSpin";
import TimeFormatInput from "../../Commons/TimeFormatInput";
import { timeTrackingActions } from "../../../../store/storage/timetrackingSlice";

const WeeklyView = () => {
  const dispatch = useDispatch();
  const weekViewData = useSelector((state) => state.timeTracking.weekViewData);
  const isLoading = useSelector((state) => state.timeTracking.isLoading);

  const activityListData = useSelector(
    (state) => state.timeTracking.activityListData
  );

  const clientOptionData = useSelector(
    (state) => state.clients.clientOptionData
  );
  const projectOptionsData = useSelector(
    (state) => state.project.projectOptionsData
  );

  const toggleFilter = useSelector(
    (state) => state.timeTracking.toggleWeekviewFilter
  );

  const filteredData = useSelector(
    (state) => state.timeTracking.filteredWeekviewData
  );

  const [startFilterDate, setStartFilterDate] = useState(null);

  const filteredWeek =
    filteredData && filteredData.filteredWeek
      ? filteredData.filteredWeek
      : dayjs();

  const filteredProject =
    filteredData && filteredData.filteredProject
      ? filteredData.filteredProject
      : null;

  const filteredClient =
    filteredData && filteredData.filteredClient
      ? filteredData.filteredClient
      : null;
  const filteredActivity =
    filteredData && filteredData.filteredActivity
      ? filteredData.filteredActivity
      : null;

  let groupByProjectId =
    weekViewData &&
    weekViewData.reduce((acc, value) => {
      acc[value.project_id && value.project_id._id] = [
        ...(acc[value.project_id && value.project_id._id] || []),
        value,
      ];
      return acc;
    }, {});

  const mappingWeekViewDataByProject =
    groupByProjectId && Object.values(groupByProjectId);

  let groupByActivityName =
    mappingWeekViewDataByProject &&
    mappingWeekViewDataByProject.map((d) => {
      const data = d.reduce((acc, value) => {
        acc[value.activity_name && value.activity_name] = [
          ...(acc[value.activity_name && value.activity_name] || []),
          value,
        ];
        return acc;
      }, {});
      const mappingWeekViewDataActivityName = data && Object.values(data);
      return mappingWeekViewDataActivityName;
    });

  const [selectedData, setSelectedData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);

  const toggleShowFilter = () => {
    dispatch(timeTrackingActions.toggleWeekviewAction());
  };

  const [updateRefresh, setUpdateRefresh] = useState(false);

  useEffect(() => {
    setSelectedData(null);
  }, [updateRefresh]);

  const [collapse, setCollapse] = useState(null);
  const collapsableHandleChange = (index) => {
    if (collapse === index) {
      return setCollapse(null);
    }
    setCollapse(index);
  };

  useEffect(() => {
    setCollapse(null);
  }, [showModal]);

  const timeout = useRef(null);

  useEffect(() => {
    const firstDateOfWeek = dayjs(filteredWeek)
      .startOf("week")
      .format("YYYY-MM-DD");

    const lastDateOfWeek = dayjs(filteredWeek)
      .endOf("week")
      .format("YYYY-MM-DD");

    setStartFilterDate(firstDateOfWeek);

    const Obj = {
      firstDateOfWeek,
      lastDateOfWeek,
      client_id: filteredClient !== 0 ? filteredClient : null,
      project_id: filteredProject !== 0 ? filteredProject : null,
      activity_name: filteredActivity !== 0 ? filteredActivity : null,
    };
    dispatch(fetchWeekView(Obj));
  }, [filteredData, updateRefresh]);

  const createAndUpdateActivity = (value, data, element, date) => {
    const timesInSecs = value;
    if (data.length > 0) {
      const Obj = {
        _id: data && data[0]._id,
        timesInSecs,
      };
      dispatch(updateWeekViewSingleActivity(Obj)).then((data) => {
        if (data.meta.requestStatus === "fulfilled") {
          setUpdateRefresh(!updateRefresh);
        } else {
          message.error(`${data.error.message}`);
        }
      });
    } else {
      let start_time = moment(date);
      start_time.set({
        hour: moment().hour(),
        minute: moment().minute(),
        second: moment().second(),
      });

      const Obj = {
        activity_name: element && element[0] && element[0].activity_name,
        client_id: element && element[0] && element[0].client_id,
        project_id: element && element[0] && element[0].project_id,
        start_time: start_time.toDate(),
        timesInSecs,
      };
      dispatch(createWeekViewSingleActivity(Obj)).then((data) => {
        if (data.meta.requestStatus === "fulfilled") {
          setUpdateRefresh(!updateRefresh);
        } else {
          message.error(`${data.error.message}`);
        }
      });
    }
  };

  const activityWiseTotalTimeEachDay = (data, element, date) => {
    let sum = 0;
    data &&
      data.forEach((d) => {
        if (d.timesInSecs) sum += d.timesInSecs;
      });

    return (
      <div>
        <TimeFormatInput
          onClick={(e) => {
            if (data.length > 1) {
              setShowModal(true);
              setSelectedData(data);
            }
          }}
          onBlur={(value) =>
            createAndUpdateActivity(value, data, element, date)
          }
          value={sum ? sum : 0}
          showModal={showModal}
          readOnly={data.length > 1 ? true : false}
        />
      </div>
    );
  };

  const projectWiseTotalTimeEachDay = (data) => {
    let sum = 0;
    data &&
      data.forEach((d) => {
        if (d.timesInSecs) sum += d.timesInSecs;
      });
    const hours = convertSecondsToHHMM(sum).hours;
    const minutes = convertSecondsToHHMM(sum).minutes;
    const formattedTime = `${hours}h ${minutes ? `${minutes}m` : ""}`;
    return formattedTime;
  };

  const totalTimeEachDay = (data) => {
    let sum = 0;
    data &&
      data.forEach((d) => {
        if (d.timesInSecs) sum += d.timesInSecs;
      });
    const hours = convertSecondsToHHMM(sum).hours;
    const minutes = convertSecondsToHHMM(sum).minutes;
    const formattedTime = `${hours}h ${minutes ? `${minutes}m` : ""}`;
    return formattedTime;
  };

  const updateActivityNames = (activity_name, element) => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      const Obj = {
        activity_name: activity_name,
        updateSelectedActivities: element.map((d) => d._id),
      };
      dispatch(updateWeekviewActivityNames(Obj)).then((data) => {
        if (data.meta.requestStatus === "fulfilled") {
          dispatch(fetchAllActivityLists()).then((data) => {
            if (data.meta.requestStatus === "fulfilled") {
              setUpdateRefresh(!updateRefresh);
            }
          });
        } else {
          message.error(`${data.error.message}`);
        }
      });
    }, 1000);
  };

  const weeklyTotalTimes = useMemo(() => {
    let sum = 0;
    weekViewData &&
      weekViewData.forEach((d) => {
        if (d.timesInSecs) sum += d.timesInSecs;
      });
    const formattedTime = convertSecondsToHHMM(sum).formattedTime;
    return formattedTime;
  }, [weekViewData]);

  return (
    <div className="my-3">
      <div className="d-flex justify-content-end mb-3">
        <TextStyles.HoverEffectSpan onClick={toggleShowFilter}>
          Filter
        </TextStyles.HoverEffectSpan>
      </div>
      {toggleFilter && (
        <div className="d-flex flex-wrap gap-3 justify-content-start mb-3">
          <div>
            <TextStyles.FontSize14px className="d-flex mb-2">
              Week
            </TextStyles.FontSize14px>
            <SelectWeekview
              initialDate={filteredWeek && dayjs(filteredWeek)}
              onWeekChange={(date) => {
                dispatch(
                  timeTrackingActions.filterWeekviewAction({
                    filteredWeek: moment(date).toISOString(),
                  })
                );
              }}
            />
          </div>
          <div>
            <TextStyles.FontSize14px className="d-flex mb-2">
              Project
            </TextStyles.FontSize14px>
            <SelectProjects
              defaultValue={filteredProject && filteredProject}
              options={projectOptionsData}
              onChange={(e) =>
                dispatch(
                  timeTrackingActions.filterWeekviewAction({
                    filteredProject: e.target.value,
                  })
                )
              }
            />
          </div>
          <div>
            <TextStyles.FontSize14px className="d-flex mb-2">
              Client
            </TextStyles.FontSize14px>
            <SelectClients
              defaultValue={filteredClient && filteredClient}
              options={clientOptionData}
              onChange={(e) =>
                dispatch(
                  timeTrackingActions.filterWeekviewAction({
                    filteredClient: e.target.value,
                  })
                )
              }
            />
          </div>
          <div>
            <TextStyles.FontSize14px className="d-flex mb-2">
              Activity
            </TextStyles.FontSize14px>
            <SelectActivity
              defaultValue={filteredActivity && filteredActivity}
              options={activityListData}
              onChange={(e) =>
                dispatch(
                  timeTrackingActions.filterWeekviewAction({
                    filteredActivity: e.target.value,
                  })
                )
              }
            />
          </div>
        </div>
      )}

      <TextStyles.FontSize16px
        className="d-flex justify-content-end mb-3"
        style={{ fontWeight: 500 }}
      >
        Total weekly time:{` ${weeklyTotalTimes}`}
      </TextStyles.FontSize16px>
      <div style={{ overflow: "auto" }}>
        <div
          style={{
            minWidth: 1000,
          }}
        >
          <Styled.WeeklyViewBox
            style={{
              background: Colors.tableHeadColor,
              borderRadius: "12px 12px 0 0",
            }}
          >
            <div className="d-flex gap-1 justify-content-between">
              <TextStyles.FontSize14px
                className="d-flex"
                style={{ width: "40%", minWidth: "2rem" }}
              />
              <TextStyles.FontSize14px
                className="d-flex gap-1 justify-content-end"
                style={{ width: "60%", minWidth: 250, fontWeight: 500 }}
              >
                <Styled.WeeklyViewDays
                  className="w-100"
                  style={{
                    minWidth: "2rem",
                    background: moment(startFilterDate).isSame(moment(), "days")
                      ? Colors.backgroundPink
                      : "",
                    color: moment(startFilterDate).isSame(moment(), "days")
                      ? Colors.primary
                      : "",
                  }}
                >
                  <span className="three-dots">
                    {moment(startFilterDate).isSame(moment(), "days")
                      ? "Today"
                      : "Sun"}
                  </span>
                </Styled.WeeklyViewDays>
                <Styled.WeeklyViewDays
                  className="w-100"
                  style={{
                    minWidth: "2rem",
                    background: moment(startFilterDate)
                      .add(1, "d")
                      .isSame(moment(), "days")
                      ? Colors.backgroundPink
                      : "",
                    color: moment(startFilterDate)
                      .add(1, "d")
                      .isSame(moment(), "days")
                      ? Colors.primary
                      : "",
                  }}
                >
                  <span className="three-dots">
                    {moment(startFilterDate)
                      .add(1, "d")
                      .isSame(moment(), "days")
                      ? "Today"
                      : "Mon"}
                  </span>
                </Styled.WeeklyViewDays>
                <Styled.WeeklyViewDays
                  className="w-100"
                  style={{
                    minWidth: "2rem",
                    background: moment(startFilterDate)
                      .add(2, "d")
                      .isSame(moment(), "days")
                      ? Colors.backgroundPink
                      : "",
                    color: moment(startFilterDate)
                      .add(2, "d")
                      .isSame(moment(), "days")
                      ? Colors.primary
                      : "",
                  }}
                >
                  <span className="three-dots">
                    {moment(startFilterDate)
                      .add(2, "d")
                      .isSame(moment(), "days")
                      ? "Today"
                      : "Tue"}
                  </span>
                </Styled.WeeklyViewDays>
                <Styled.WeeklyViewDays
                  className="w-100"
                  style={{
                    minWidth: "2rem",
                    background: moment(startFilterDate)
                      .add(3, "d")
                      .isSame(moment(), "days")
                      ? Colors.backgroundPink
                      : "",
                    color: moment(startFilterDate)
                      .add(3, "d")
                      .isSame(moment(), "days")
                      ? Colors.primary
                      : "",
                  }}
                >
                  <div className="three-dots">
                    {moment(startFilterDate)
                      .add(3, "d")
                      .isSame(moment(), "days")
                      ? "Today"
                      : "Wed"}
                  </div>
                </Styled.WeeklyViewDays>
                <Styled.WeeklyViewDays
                  className="w-100"
                  style={{
                    minWidth: "2rem",
                    background: moment(startFilterDate)
                      .add(4, "d")
                      .isSame(moment(), "days")
                      ? Colors.backgroundPink
                      : "",
                    color: moment(startFilterDate)
                      .add(4, "d")
                      .isSame(moment(), "days")
                      ? Colors.primary
                      : "",
                  }}
                >
                  <span className="three-dots">
                    {moment(startFilterDate)
                      .add(4, "d")
                      .isSame(moment(), "days")
                      ? "Today"
                      : "Thu"}
                  </span>
                </Styled.WeeklyViewDays>
                <Styled.WeeklyViewDays
                  className="w-100"
                  style={{
                    minWidth: "2rem",
                    background: moment(startFilterDate)
                      .add(5, "d")
                      .isSame(moment(), "days")
                      ? Colors.backgroundPink
                      : "",
                    color: moment(startFilterDate)
                      .add(5, "d")
                      .isSame(moment(), "days")
                      ? Colors.primary
                      : "",
                  }}
                >
                  <span className="three-dots">
                    {moment(startFilterDate)
                      .add(5, "d")
                      .isSame(moment(), "days")
                      ? "Today"
                      : "Fri"}
                  </span>
                </Styled.WeeklyViewDays>
                <Styled.WeeklyViewDays
                  className="w-100"
                  style={{
                    minWidth: "2rem",
                    background: moment(startFilterDate)
                      .add(6, "d")
                      .isSame(moment(), "days")
                      ? Colors.backgroundPink
                      : "",
                    color: moment(startFilterDate)
                      .add(6, "d")
                      .isSame(moment(), "days")
                      ? Colors.primary
                      : "",
                  }}
                >
                  <span className="three-dots">
                    {moment(startFilterDate)
                      .add(6, "d")
                      .isSame(moment(), "days")
                      ? "Today"
                      : "Sat"}
                  </span>
                </Styled.WeeklyViewDays>
              </TextStyles.FontSize14px>
            </div>
          </Styled.WeeklyViewBox>

          <Styled.WeeklyViewBox
            style={{
              marginBottom: 2,
            }}
          >
            <div className="d-flex gap-1 justify-content-between">
              <TextStyles.FontSize14px
                className="d-flex justify-content-start align-items-center"
                style={{ width: "40%", minWidth: "2rem", fontWeight: 500 }}
              >
                <span className="three-dots">Total time</span>
              </TextStyles.FontSize14px>
              <TextStyles.FontSize14px
                className="d-flex gap-1 justify-content-end"
                style={{ width: "60%", minWidth: 250 }}
              >
                <Styled.WeeklyViewDays
                  className="w-100"
                  style={{
                    minWidth: "2rem",
                    background: moment(startFilterDate).isSame(moment(), "days")
                      ? Colors.backgroundPink
                      : "",
                    color: moment(startFilterDate).isSame(moment(), "days")
                      ? Colors.primary
                      : "",
                  }}
                >
                  <span className="three-dots">
                    {totalTimeEachDay(
                      weekViewData &&
                        weekViewData.filter(
                          (d) => moment(d.start_time).format("ddd") === "Sun"
                        )
                    )}
                  </span>
                </Styled.WeeklyViewDays>
                <Styled.WeeklyViewDays
                  className="w-100"
                  style={{
                    minWidth: "2rem",
                    background: moment(startFilterDate)
                      .add(1, "d")
                      .isSame(moment(), "days")
                      ? Colors.backgroundPink
                      : "",
                    color: moment(startFilterDate)
                      .add(1, "d")
                      .isSame(moment(), "days")
                      ? Colors.primary
                      : "",
                  }}
                >
                  <span className="three-dots">
                    {totalTimeEachDay(
                      weekViewData &&
                        weekViewData.filter(
                          (d) => moment(d.start_time).format("ddd") === "Mon"
                        )
                    )}
                  </span>
                </Styled.WeeklyViewDays>
                <Styled.WeeklyViewDays
                  className="w-100"
                  style={{
                    minWidth: "2rem",
                    background: moment(startFilterDate)
                      .add(2, "d")
                      .isSame(moment(), "days")
                      ? Colors.backgroundPink
                      : "",
                    color: moment(startFilterDate)
                      .add(2, "d")
                      .isSame(moment(), "days")
                      ? Colors.primary
                      : "",
                  }}
                >
                  <span className="three-dots">
                    {totalTimeEachDay(
                      weekViewData &&
                        weekViewData.filter(
                          (d) => moment(d.start_time).format("ddd") === "Tue"
                        )
                    )}
                  </span>
                </Styled.WeeklyViewDays>
                <Styled.WeeklyViewDays
                  className="w-100"
                  style={{
                    minWidth: "2rem",
                    background: moment(startFilterDate)
                      .add(3, "d")
                      .isSame(moment(), "days")
                      ? Colors.backgroundPink
                      : "",
                    color: moment(startFilterDate)
                      .add(3, "d")
                      .isSame(moment(), "days")
                      ? Colors.primary
                      : "",
                  }}
                >
                  <span className="three-dots">
                    {" "}
                    {totalTimeEachDay(
                      weekViewData &&
                        weekViewData.filter(
                          (d) => moment(d.start_time).format("ddd") === "Wed"
                        )
                    )}
                  </span>
                </Styled.WeeklyViewDays>
                <Styled.WeeklyViewDays
                  className="w-100"
                  style={{
                    minWidth: "2rem",
                    background: moment(startFilterDate)
                      .add(4, "d")
                      .isSame(moment(), "days")
                      ? Colors.backgroundPink
                      : "",
                    color: moment(startFilterDate)
                      .add(4, "d")
                      .isSame(moment(), "days")
                      ? Colors.primary
                      : "",
                  }}
                >
                  <span className="three-dots">
                    {" "}
                    {totalTimeEachDay(
                      weekViewData &&
                        weekViewData.filter(
                          (d) => moment(d.start_time).format("ddd") === "Thu"
                        )
                    )}
                  </span>
                </Styled.WeeklyViewDays>
                <Styled.WeeklyViewDays
                  className="w-100"
                  style={{
                    minWidth: "2rem",
                    background: moment(startFilterDate)
                      .add(5, "d")
                      .isSame(moment(), "days")
                      ? Colors.backgroundPink
                      : "",
                    color: moment(startFilterDate)
                      .add(5, "d")
                      .isSame(moment(), "days")
                      ? Colors.primary
                      : "",
                  }}
                >
                  <span className="three-dots">
                    {totalTimeEachDay(
                      weekViewData &&
                        weekViewData.filter(
                          (d) => moment(d.start_time).format("ddd") === "Fri"
                        )
                    )}
                  </span>
                </Styled.WeeklyViewDays>
                <Styled.WeeklyViewDays
                  className="w-100"
                  style={{
                    minWidth: "2rem",
                    background: moment(startFilterDate)
                      .add(6, "d")
                      .isSame(moment(), "days")
                      ? Colors.backgroundPink
                      : "",
                    color: moment(startFilterDate)
                      .add(6, "d")
                      .isSame(moment(), "days")
                      ? Colors.primary
                      : "",
                  }}
                >
                  <span className="three-dots">
                    {" "}
                    {totalTimeEachDay(
                      weekViewData &&
                        weekViewData.filter(
                          (d) => moment(d.start_time).format("ddd") === "Sat"
                        )
                    )}
                  </span>
                </Styled.WeeklyViewDays>
              </TextStyles.FontSize14px>
            </div>
          </Styled.WeeklyViewBox>

          {weekViewData && weekViewData.length > 0 ? (
            <div>
              {groupByActivityName &&
                groupByActivityName.map((item, index) => {
                  return (
                    <Styled.WeeklyViewBox
                      style={{
                        marginBottom:
                          groupByActivityName.length - 1 !== index ? 2 : "",
                        borderRadius:
                          groupByActivityName.length - 1 === index
                            ? "0 0 12px 12px"
                            : "",
                      }}
                      key={index}
                    >
                      <div
                        className="d-flex gap-1 justify-content-between"
                        onClick={() => collapsableHandleChange(index)}
                        style={{ cursor: "pointer" }}
                      >
                        <TextStyles.FontSize14px
                          className="d-flex justify-content-start align-items-center"
                          style={{
                            width: "40%",
                            minWidth: "2rem",
                          }}
                        >
                          <span className="three-dots">
                            {item[0][0] &&
                            item[0][0].project_id &&
                            item[0][0].project_id.project_name
                              ? item[0][0].project_id.project_name
                              : "Unassigned"}
                            {item[0][0] &&
                              item[0][0].client_id &&
                              item[0][0].client_id.company_name &&
                              `(${item[0][0].client_id.company_name})`}
                            {collapse === index ? (
                              <ArrowDropUpIcon
                                sx={{ color: "#5B5B5B", fontSize: 30 }}
                              />
                            ) : (
                              <ArrowDropDownIcon
                                sx={{ color: "#5B5B5B", fontSize: 30 }}
                              />
                            )}
                          </span>
                        </TextStyles.FontSize14px>
                        <TextStyles.FontSize14px
                          className="d-flex gap-1 justify-content-end"
                          style={{ width: "60%", minWidth: 250 }}
                        >
                          <Styled.WeeklyViewDays
                            className="w-100"
                            style={{
                              minWidth: "2rem",
                              background: moment(startFilterDate).isSame(
                                moment(),
                                "days"
                              )
                                ? Colors.backgroundPink
                                : "",
                            }}
                          >
                            <span className="three-dots">
                              {projectWiseTotalTimeEachDay(
                                item
                                  .flat()
                                  .filter(
                                    (d) =>
                                      moment(d.start_time).format("ddd") ===
                                      "Sun"
                                  )
                              )}
                            </span>
                          </Styled.WeeklyViewDays>
                          <Styled.WeeklyViewDays
                            className="w-100"
                            style={{
                              minWidth: "2rem",
                              background: moment(startFilterDate)
                                .add(1, "d")
                                .isSame(moment(), "days")
                                ? Colors.backgroundPink
                                : "",
                            }}
                          >
                            <span className="three-dots">
                              {" "}
                              {projectWiseTotalTimeEachDay(
                                item
                                  .flat()
                                  .filter(
                                    (d) =>
                                      moment(d.start_time).format("ddd") ===
                                      "Mon"
                                  )
                              )}
                            </span>
                          </Styled.WeeklyViewDays>
                          <Styled.WeeklyViewDays
                            className="w-100"
                            style={{
                              minWidth: "2rem",
                              background: moment(startFilterDate)
                                .add(2, "d")
                                .isSame(moment(), "days")
                                ? Colors.backgroundPink
                                : "",
                            }}
                          >
                            <span className="three-dots">
                              {" "}
                              {projectWiseTotalTimeEachDay(
                                item
                                  .flat()
                                  .filter(
                                    (d) =>
                                      moment(d.start_time).format("ddd") ===
                                      "Tue"
                                  )
                              )}
                            </span>
                          </Styled.WeeklyViewDays>
                          <Styled.WeeklyViewDays
                            className="w-100"
                            style={{
                              minWidth: "2rem",
                              background: moment(startFilterDate)
                                .add(3, "d")
                                .isSame(moment(), "days")
                                ? Colors.backgroundPink
                                : "",
                            }}
                          >
                            <span className="three-dots">
                              {" "}
                              {projectWiseTotalTimeEachDay(
                                item
                                  .flat()
                                  .filter(
                                    (d) =>
                                      moment(d.start_time).format("ddd") ===
                                      "Wed"
                                  )
                              )}
                            </span>
                          </Styled.WeeklyViewDays>
                          <Styled.WeeklyViewDays
                            className="w-100"
                            style={{
                              minWidth: "2rem",
                              background: moment(startFilterDate)
                                .add(4, "d")
                                .isSame(moment(), "days")
                                ? Colors.backgroundPink
                                : "",
                            }}
                          >
                            <span className="three-dots">
                              {" "}
                              {projectWiseTotalTimeEachDay(
                                item
                                  .flat()
                                  .filter(
                                    (d) =>
                                      moment(d.start_time).format("ddd") ===
                                      "Thu"
                                  )
                              )}
                            </span>
                          </Styled.WeeklyViewDays>
                          <Styled.WeeklyViewDays
                            className="w-100"
                            style={{
                              minWidth: "2rem",
                              background: moment(startFilterDate)
                                .add(5, "d")
                                .isSame(moment(), "days")
                                ? Colors.backgroundPink
                                : "",
                            }}
                          >
                            <span className="three-dots">
                              {" "}
                              {projectWiseTotalTimeEachDay(
                                item
                                  .flat()
                                  .filter(
                                    (d) =>
                                      moment(d.start_time).format("ddd") ===
                                      "Fri"
                                  )
                              )}
                            </span>
                          </Styled.WeeklyViewDays>
                          <Styled.WeeklyViewDays
                            className="w-100"
                            style={{
                              minWidth: "2rem",
                              background: moment(startFilterDate)
                                .add(6, "d")
                                .isSame(moment(), "days")
                                ? Colors.backgroundPink
                                : "",
                            }}
                          >
                            <span className="three-dots">
                              {" "}
                              {projectWiseTotalTimeEachDay(
                                item
                                  .flat()
                                  .filter(
                                    (d) =>
                                      moment(d.start_time).format("ddd") ===
                                      "Sat"
                                  )
                              )}
                            </span>
                          </Styled.WeeklyViewDays>
                        </TextStyles.FontSize14px>
                      </div>

                      {collapse === index &&
                        item &&
                        item.map((element, i) => {
                          return (
                            <div
                              className="d-flex gap-1 justify-content-between"
                              key={i}
                            >
                              <TextStyles.GreyFont14px
                                className="d-flex justify-content-start align-items-center"
                                style={{
                                  width: "40%",
                                  minWidth: "2rem",
                                }}
                              >
                                <Styled.DaysInputRectangle
                                  style={{
                                    maxWidth: "20rem",
                                    paddingLeft: "0.5rem",
                                    textAlign: "start",
                                  }}
                                  placeholder="Write Activity"
                                  defaultValue={
                                    element && element[0].activity_name
                                  }
                                  onChange={(e) =>
                                    updateActivityNames(e.target.value, element)
                                  }
                                />
                              </TextStyles.GreyFont14px>
                              <TextStyles.GreyFont14px
                                className="d-flex gap-1 justify-content-end"
                                style={{ width: "60%", minWidth: 250 }}
                              >
                                <Styled.WeeklyViewDays
                                  className="w-100"
                                  style={{
                                    minWidth: "2rem",
                                    background: moment(startFilterDate).isSame(
                                      moment(),
                                      "days"
                                    )
                                      ? Colors.backgroundPink
                                      : "",
                                  }}
                                >
                                  {activityWiseTotalTimeEachDay(
                                    element.filter(
                                      (d) =>
                                        moment(d.start_time).format("ddd") ===
                                        "Sun"
                                    ),
                                    element,
                                    moment(startFilterDate).format("YYYY-MM-DD")
                                  )}
                                </Styled.WeeklyViewDays>
                                <Styled.WeeklyViewDays
                                  className="w-100"
                                  style={{
                                    minWidth: "2rem",
                                    background: moment(startFilterDate)
                                      .add(1, "d")
                                      .isSame(moment(), "days")
                                      ? Colors.backgroundPink
                                      : "",
                                  }}
                                >
                                  {activityWiseTotalTimeEachDay(
                                    element.filter(
                                      (d) =>
                                        moment(d.start_time).format("ddd") ===
                                        "Mon"
                                    ),
                                    element,
                                    moment(startFilterDate)
                                      .add(1, "d")
                                      .format("YYYY-MM-DD")
                                  )}
                                </Styled.WeeklyViewDays>
                                <Styled.WeeklyViewDays
                                  className="w-100"
                                  style={{
                                    minWidth: "2rem",
                                    background: moment(startFilterDate)
                                      .add(2, "d")
                                      .isSame(moment(), "days")
                                      ? Colors.backgroundPink
                                      : "",
                                  }}
                                >
                                  {activityWiseTotalTimeEachDay(
                                    element.filter(
                                      (d) =>
                                        moment(d.start_time).format("ddd") ===
                                        "Tue"
                                    ),
                                    element,
                                    moment(startFilterDate)
                                      .add(2, "d")
                                      .format("YYYY-MM-DD")
                                  )}
                                </Styled.WeeklyViewDays>
                                <Styled.WeeklyViewDays
                                  className="w-100"
                                  style={{
                                    minWidth: "2rem",
                                    background: moment(startFilterDate)
                                      .add(3, "d")
                                      .isSame(moment(), "days")
                                      ? Colors.backgroundPink
                                      : "",
                                  }}
                                >
                                  {activityWiseTotalTimeEachDay(
                                    element.filter(
                                      (d) =>
                                        moment(d.start_time).format("ddd") ===
                                        "Wed"
                                    ),
                                    element,
                                    moment(startFilterDate)
                                      .add(3, "d")
                                      .format("YYYY-MM-DD")
                                  )}
                                </Styled.WeeklyViewDays>
                                <Styled.WeeklyViewDays
                                  className="w-100"
                                  style={{
                                    minWidth: "2rem",
                                    background: moment(startFilterDate)
                                      .add(4, "d")
                                      .isSame(moment(), "days")
                                      ? Colors.backgroundPink
                                      : "",
                                  }}
                                >
                                  {activityWiseTotalTimeEachDay(
                                    element.filter(
                                      (d) =>
                                        moment(d.start_time).format("ddd") ===
                                        "Thu"
                                    ),
                                    element,
                                    moment(startFilterDate)
                                      .add(4, "d")
                                      .format("YYYY-MM-DD")
                                  )}
                                </Styled.WeeklyViewDays>
                                <Styled.WeeklyViewDays
                                  className="w-100"
                                  style={{
                                    minWidth: "2rem",
                                    background: moment(startFilterDate)
                                      .add(5, "d")
                                      .isSame(moment(), "days")
                                      ? Colors.backgroundPink
                                      : "",
                                  }}
                                >
                                  {activityWiseTotalTimeEachDay(
                                    element.filter(
                                      (d) =>
                                        moment(d.start_time).format("ddd") ===
                                        "Fri"
                                    ),
                                    element,
                                    moment(startFilterDate)
                                      .add(5, "d")
                                      .format("YYYY-MM-DD")
                                  )}
                                </Styled.WeeklyViewDays>
                                <Styled.WeeklyViewDays
                                  className="w-100"
                                  style={{
                                    minWidth: "2rem",
                                    background: moment(startFilterDate)
                                      .add(6, "d")
                                      .isSame(moment(), "days")
                                      ? Colors.backgroundPink
                                      : "",
                                  }}
                                >
                                  {activityWiseTotalTimeEachDay(
                                    element.filter(
                                      (d) =>
                                        moment(d.start_time).format("ddd") ===
                                        "Sat"
                                    ),
                                    element,
                                    moment(startFilterDate)
                                      .add(6, "d")
                                      .format("YYYY-MM-DD")
                                  )}
                                </Styled.WeeklyViewDays>
                              </TextStyles.GreyFont14px>
                            </div>
                          );
                        })}
                    </Styled.WeeklyViewBox>
                  );
                })}
            </div>
          ) : isLoading &&
            (weekViewData === null || weekViewData.length === 0) ? (
            <Styled.ListBox>
              <LoaderSpin color={Colors.primary} />
            </Styled.ListBox>
          ) : (
            !isLoading &&
            (weekViewData === null || weekViewData.length === 0) && (
              <Styled.ListBox>
                <img src={NoTimeTrackingImage} alt="no-time-tracting-img" />
                <TextStyles.GreyFont18px className="mt-2">
                  There is no activity this week.
                </TextStyles.GreyFont18px>
              </Styled.ListBox>
            )
          )}
        </div>
      </div>
      {selectedData && (
        <EditWeekviewMultipleActivityTimes
          show={showModal}
          handleClose={handleClose}
          selectedData={selectedData}
          updateRefresh={updateRefresh}
          setUpdateRefresh={setUpdateRefresh}
        />
      )}
    </div>
  );
};

export default React.memo(WeeklyView);
