import styled from "styled-components";
import Colors from "../../../../../Constants/Colors";

const NoteGridStyled = styled.ul`
  background-color: ${Colors.white};
  margin: 0;
  padding: 2rem 1rem;
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  font-size: 14px;
  border-top: 4px solid ${Colors.primary};
  border-radius: 0 0 0.75rem 0.75rem;
`;

const NoteItem = styled.li`
  list-style: none;
  border: 1px solid ${Colors.borderInput};
  border-radius: 0.75rem;
  width: 16.8rem;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: ${Colors.backgroundGrey};
    transform: translateY(-2px);
  }

  & > p {
    margin: 0;
  }
`;

const ListBox = styled.div`
  width: 100%;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.white};
  border-radius: 0.75rem;
  padding: 1rem;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
`;

export default { NoteGridStyled, NoteItem, ListBox };
