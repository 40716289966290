import React from "react";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import TodayIcon from "@mui/icons-material/Today";
import "./CustomDatePickerOne.css";
import Colors from "../../../../Constants/Colors";

const CustomDatePickerOne = ({
  status = null,
  placeholder = "Select Date",
  dateFormat = "DD MMM YYYY",
  selected = null,
  onChange,
  minDate = null,
  maxDate = null,
  allowClear = true,
  disabled = false,
  suffixIcon = <TodayIcon sx={{ color: Colors.primary, fontSize: 22 }} />,
  ...rest
}) => {
  const handleDateChange = (date) => {
    if (onChange) {
      onChange(date ? dayjs(date).toDate() : null);
    }
  };

  return (
    <Space
      direction="vertical"
      style={{
        width: "100%",
      }}
    >
      <DatePicker
        status={status}
        suffixIcon={suffixIcon}
        // defaultValue={selected ? dayjs(selected) : null}
        value={selected ? dayjs(selected) : null}
        onChange={handleDateChange}
        format={dateFormat}
        placeholder={placeholder}
        allowClear={allowClear}
        disabled={disabled}
        // disabledDate={(current) => {
        //   if (minDate && current < dayjs(minDate)) return true;
        //   if (maxDate && current > dayjs(maxDate)) return true;
        //   return false;
        // }}
        {...rest}
        className="custum-date-picker-one"
        popupClassName="custum-date-picker-one-popup"
      />
    </Space>
  );
};

export default CustomDatePickerOne;
