import React from "react";
import TextStyles from "../../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import Colors from "../../../../../../../Constants/Colors";
const TermAndTermination = (props) => {
  const selectedData = props && props.selectedData;

  const heading =
    selectedData &&
    selectedData.term_and_termination &&
    selectedData.term_and_termination.heading
      ? selectedData.term_and_termination.heading
      : "Term & Termination";
  const paragraph =
    selectedData &&
    selectedData.term_and_termination &&
    selectedData.term_and_termination.heading
      ? selectedData.term_and_termination.paragraph
      : "Additionally, either Party may terminate the Agreement immediately if the other Party fails to remedy a breach within 5 days of receiving written notice of such breach.  If Client terminates the Agreement without cause, Client agrees to compensate Contractor for services provided and expenses already incurred up to the termination date, including documented non- cancellable commitments. Contractor will make efforts to minimize these costs and expenses. Termination will not affect Client's rights granted under this Agreement. Client must pay all undisputed amounts owed to the Contractor upon termination. If Client hasn't paid undisputed fees for services or materials provided by Contractor as of the termination date, Client may not use such materials until payment is made in full. Any provisions in this Agreement that imply survival shall remain in effect after termination or expiration. Notwithstanding other terms, Contractor retains a perfected security interest in the deliverables or materials until Client pays all undisputed amounts in full. Contractor will release and waive the security interest upon receipt of full payment for all undisputed amounts.";

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.term_and_termination &&
          selectedData.term_and_termination.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox className="mt-4">
        <TextStyles.FontSize20px className="mb-3 fw-medium">
          {heading}
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px className="lh-lg">
          <div className="mb-2">
            Either Party has the right to terminate this Agreement at any time
            by providing
            <span
              className="mx-2"
              style={{ color: Colors.primary, fontWeight: 500 }}
            >
              {selectedData &&
                selectedData.term_and_termination &&
                selectedData.term_and_termination
                  .project_termination_notice_period}
            </span>
            written notice, with or without cause.
          </div>
          {paragraph}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
    </div>
  );
};

export default TermAndTermination;
