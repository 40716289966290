import React from "react";
import TextStyles from "../../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";

const Warranty = (props) => {
  const selectedData = props && props.selectedData;
  const heading =
    selectedData && selectedData.warranty && selectedData.warranty.heading
      ? selectedData && selectedData.warranty && selectedData.warranty.heading
      : "Warranty";
  const paragraph =
    selectedData && selectedData.warranty && selectedData.warranty.paragraph
      ? selectedData && selectedData.warranty && selectedData.warranty.paragraph
      : `Contractor hereby warrants that:(i) The Services will be conducted in a professional and workmanlike manner, free from any inconsistency with any other contractual obligations held by the Contractor;(ii) The Services, Inventions, and their development, use, production, distribution, or exploitation will not infringe, misappropriate, or violate any intellectual property or rights of any entity, including the Contractor;(iii) The Contractor possesses full rights to provide the Client with the assignments and rights as stipulated in this Agreement;(iv) The Contractor will comply with all applicable laws while performing the Services and adhering to the Terms and Conditions;(v) If the Contractor's work requires a license, the Contractor has obtained the necessary license, which remains in full force and effect.\n\nEXCEPT AS SPECIFIED IN THIS ATTACHMENT B, THE CONTRACTOR DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, SUITABILITY, COMPLETENESS, OR RESULTS DERIVED FROM THE WORK. EXCEPT AS EXPRESSLY STATED HEREIN, ALL DELIVERABLES ARE PROVIDED ON AN "AS-IS" BASIS.
`;
  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.warranty &&
          selectedData.warranty.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox className="mt-4">
        <TextStyles.FontSize20px className="mb-3 fw-medium">
          {heading}
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px className="lh-lg" style={{ whiteSpace: "break-spaces" }}>
          {paragraph}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
    </div>
  );
};

export default Warranty;
