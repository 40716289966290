import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import TextStyles from "../../../../Constants/TextStyles";
import Styles from "./ViewAgreementsStyles";
import { message } from "antd";

import { useParams, useHistory } from "react-router-dom";
import { putRequest } from "../../../../axios/axios";
import ClearIcon from "@mui/icons-material/Clear";
import ProposalView from "./ViewProposals/Review";
import ContractView from "./ViewContracts/Review";
import LoaderSpin from "../../Commons/LoaderSpin";
import Colors from "../../../../Constants/Colors";

const ViewAgreements = () => {
  const pcData = useParams();
  const history = useHistory();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [selector, setSelector] = useState("Proposal");
  const data = useSelector((state) => state.auth.data);
  const businessInfoData = useSelector((state) => state.auth.businessInfoData);
  const brandAndLogoData = useSelector((state) => state.auth.brandAndLogoData);

  useEffect(() => {
    viewPcCallHandler();
  }, [pcData]);

  const viewPcCallHandler = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    putRequest(`pc/proposal_contract/view_pc`, token, {
      _id: pcData.id,
    })
      .then((data) => {
        if (data.data.success === true) {
          setIsLoading(false);
          setError(false);
          setSelectedData(data.data.data);
        }
      })
      .catch((err) => {
        setError(true);
        setIsLoading(false);
        if (err && err.response && err.response.data) {
          return message.error(`${err.response.data.message}`);
        }
        if (err.isAxiosError) {
          return message.error(`${err.message}`);
        }
      });
  };

  return (
    <div>
      {isLoading ? (
        <TextStyles.FontSize14px
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoaderSpin color={Colors.primary} />
        </TextStyles.FontSize14px>
      ) : (
        <>
          <div
            className="fixed-top"
            style={{
              background: Colors.white,
              borderBottom: `2px solid ${
                brandAndLogoData && brandAndLogoData.brand_color
                  ? brandAndLogoData.brand_color
                  : Colors.primary
              }`,
              zIndex: 200,
              boxShadow: "0 2px 4px 2px rgba(0, 0, 0, .1)",
            }}
          >
            <TextStyles.FontSize27px
              className="d-flex justify-content-center"
              style={{
                minWidth: 100,
                fontWeight: 500,
                padding: "13px 50px 13px 13px",
              }}
            >
              <span className="three-dots">
                {`View ${
                  selectedData && selectedData.section
                    ? selectedData.section.toLowerCase(1)
                    : "documents"
                }`}
              </span>
            </TextStyles.FontSize27px>
            <div
              style={{ position: "absolute", top: 20, right: 13 }}
              onClick={() => history.push("/db/agreements")}
            >
              <ClearIcon
                sx={{
                  color: Colors.font2,
                  fontSize: 30,
                  cursor: "pointer",
                  opacity: 0.7,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            </div>
          </div>

          {error ? (
            <TextStyles.FontSize14px
              style={{
                width: "100vw",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextStyles.FontSize16px
                style={{
                  padding: "12px 20px",
                  borderRadius: 9,
                  border: `2px solid ${Colors.red}`,
                  color: Colors.red,
                }}
              >
                Invalid credential
              </TextStyles.FontSize16px>
            </TextStyles.FontSize14px>
          ) : (
            <div className="bg-light" style={{ paddingTop: 100 }}>
              {selectedData &&
                selectedData.section &&
                selectedData.section === "Proposal" && (
                  <ProposalView
                    selectedData={selectedData}
                    businessInfoData={businessInfoData}
                    data={data}
                    brandAndLogoData={brandAndLogoData}
                  />
                )}

              {selectedData &&
                selectedData.section &&
                selectedData.section !== "Proposal" && (
                  <>
                    <div className="d-flex justify-content-center">
                      <Styles.SliderBox className="d-flex mt-2 mb-4">
                        <div
                          onClick={() => {
                            setSelector("Proposal");
                          }}
                        >
                          {selector === "Proposal" ? (
                            <Styles.SelectedItem
                              bgColor={
                                brandAndLogoData && brandAndLogoData.brand_color
                              }
                            >
                              {selectedData &&
                              selectedData.section === "Contract"
                                ? "Cover page"
                                : "Proposal"}
                            </Styles.SelectedItem>
                          ) : (
                            <Styles.NotSelectedItem
                              bgColor={
                                brandAndLogoData && brandAndLogoData.brand_color
                              }
                            >
                              {selectedData &&
                              selectedData.section === "Contract"
                                ? "Cover page"
                                : "Proposal"}
                            </Styles.NotSelectedItem>
                          )}
                        </div>
                        <div
                          onClick={() => {
                            setSelector("Contract");
                          }}
                        >
                          {selector === "Contract" ? (
                            <Styles.SelectedItem
                              bgColor={
                                brandAndLogoData && brandAndLogoData.brand_color
                              }
                            >
                              Contract
                            </Styles.SelectedItem>
                          ) : (
                            <Styles.NotSelectedItem
                              bgColor={
                                brandAndLogoData && brandAndLogoData.brand_color
                              }
                            >
                              Contract
                            </Styles.NotSelectedItem>
                          )}
                        </div>
                      </Styles.SliderBox>
                    </div>
                    {selector === "Proposal" && (
                      <ProposalView
                        selectedData={selectedData}
                        businessInfoData={businessInfoData}
                        data={data}
                        brandAndLogoData={brandAndLogoData}
                      />
                    )}
                    {selector === "Contract" && (
                      <ContractView
                        selectedData={selectedData}
                        businessInfoData={businessInfoData}
                        data={data}
                        brandAndLogoData={brandAndLogoData}
                      />
                    )}
                  </>
                )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ViewAgreements;
