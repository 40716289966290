import React from "react";

import Tasks from "./Tasks/Tasks";
import Reminder from "./Reminders/Reminders";
import Notes from "./Notes/Notes";
import Styled from "./TasksAndNotesStyles";
import Colors from "../../../Constants/Colors";
import { useHistory } from "react-router-dom";
import ProtectedRoute from "../../auth/ProtectedRoute";

export default function TasksAndNotes() {
  const history = useHistory();
  let urlElements = window.location.href.split("/");

  return (
    <div
      style={{
        backgroundColor: Colors.backgroundGrey,
        minHeight: "100vh",
        padding: "105px 0 60px 0",
        contain: "content",
      }}
    >
      <div
        className="d-flex px-2 px-sm-3 px-md-4 px-lg-4"
        style={{ height: "4rem" }}
      >
        <div
          style={{
            width: "100%",
            position: "relative",
            overflowY: "hidden",
            height: "100%",
          }}
        >
          <Styled.SliderBox
            style={{
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              onClick={() => {
                history.push("/db/tasks&notes/tasks");
              }}
            >
              {urlElements[5] === "tasks" ? (
                <Styled.SelectedItem>Tasks</Styled.SelectedItem>
              ) : (
                <Styled.NotSelectedItem>Tasks</Styled.NotSelectedItem>
              )}
            </div>
            <div
              onClick={() => {
                history.push("/db/tasks&notes/reminders");
              }}
            >
              {urlElements[5] === "reminders" ? (
                <Styled.SelectedItem>Reminders</Styled.SelectedItem>
              ) : (
                <Styled.NotSelectedItem>Reminders</Styled.NotSelectedItem>
              )}
            </div>
            <div
              onClick={() => {
                history.push("/db/tasks&notes/notes");
              }}
            >
              {urlElements[5] === "notes" ? (
                <Styled.SelectedItem>Notes</Styled.SelectedItem>
              ) : (
                <Styled.NotSelectedItem>Notes</Styled.NotSelectedItem>
              )}
            </div>
          </Styled.SliderBox>
        </div>
      </div>

      <div className="px-2 px-sm-3 px-md-4 px-lg-4">
        <ProtectedRoute path="/db/tasks&notes/tasks" exact component={Tasks} />
        <ProtectedRoute
          path="/db/tasks&notes/reminders"
          exact
          component={Reminder}
        />
        <ProtectedRoute path="/db/tasks&notes/notes" exact component={Notes} />
      </div>
    </div>
  );
}
