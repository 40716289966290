import styled from "styled-components";
import Colors from "../../../../Constants/Colors";

const TodoRectangle = styled.div`
  width: 100%;
  height: 3.15rem;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  background-color: ${Colors.tableHeadColor};
  border-radius: 0.75rem 0.75rem 0 0;

  .visible-task-name,
  .visible-projects,
  .visible-clients,
  .visible-createdAt,
  .visible-due-date {
    visibility: hidden;
  }

  .hover-task-name:hover .visible-task-name,
  .hover-projects:hover .visible-projects,
  .hover-clients:hover .visible-clients,
  .hover-createdAt:hover .visible-createdAt,
  .hover-due-date:hover .visible-due-date {
    visibility: visible;
  }
`;

const NewCreate = styled.div`
  width: fit-content;
  height: 3.15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem 0 0.4rem;
  border-radius: 0.75rem;
  background-color: ${Colors.primary};
  cursor: pointer;
  color: ${Colors.white};
  font-family: Poppins, serif;
  font-size: 0.875rem;
  transition: background-color 0.4s ease;
  &:hover {
    background-color: ${Colors.hoverButtonColor};
  }
`;

const Input = styled.input`
  width: 100%;
  max-width: 500px;
  margin: 0 0.7rem;
  font-size: 14px;
  background-color: ${(props) => (props.invalid ? Colors.red : Colors.white)};
  caret-color: ${Colors.font2};
`;

const Sorting = styled.div`
  display: flex;
  min-width: 13.75rem;
  justify-content: space-between;
  align-items: center;
  padding: 3px 8px;
  border-radius: 6px;

  label {
    display: flex;
    width: 100%;
    cursor: pointer;
  }

  .form-check-input {
    width: 1rem;
    height: 1rem;
    box-shadow: none !important;
    border: 2px solid ${Colors.font2};
    cursor: pointer;
  }

  .form-check-input:checked {
    background-color: ${Colors.primary};
    border-color: ${Colors.primary};
  }

  &:hover {
    background: ${Colors.backgroundPink};
  }

  &:hover svg {
    color: ${Colors.primary};
  }
`;
const ListBox = styled.div`
  width: 100%;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.white};
  border-radius: 0.75rem;
  padding: 1rem;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
`;

export default {
  TodoRectangle,
  NewCreate,
  Input,
  Sorting,
  ListBox,
};
