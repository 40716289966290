import React from "react";
import Styled from "./RemnderListStyles";
import TextStyles from "../../../../../Constants/TextStyles";
import Colors from "../../../../../Constants/Colors";

import moment from "moment";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ReactTooltip } from "../../../Commons/ReactTooltip";
import { useSelector } from "react-redux";

const ReminderList = ({
  remindersData,
  index,
  data,
  sendInvoiceHandler,
  recordPaymentHandler,
  dismissReminderHandler,
  doneReminderHandler,
  setShowDismissModal,
  setConfirmDialog,
  resendInvoiceHandler,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    // transition,
    isDragging,
  } = useSortable({ id: data && data.id });

  const sortingData = useSelector((state) => state.reminders.sortingData);

  const selectedSortingOne =
    sortingData && sortingData.selectedSortingOne
      ? sortingData.selectedSortingOne
      : "orders";

  const style = {
    transform: CSS.Transform.toString(transform),
    zIndex: isDragging ? 10 : "",
    // opacity: isDragging ? 0.9 : 1,
    // transition: transition, // Smooth snapping back
    // boxShadow: isDragging ? "0 4px 8px rgba(0, 0, 0, 0.2)" : "none",
    position: "relative",
    background: isDragging ? Colors.backgroundPink : "",
    marginBottom: 1,
    borderRadius: remindersData.length - 1 === index ? "0 0 12px 12px" : "",
  };

  return (
    <Styled.TodoRectangle ref={setNodeRef} style={style}>
      <TextStyles.FontSize14px className="d-flex gap-2 align-items-center w-100">
        <div
          className="d-flex align-items-center w-100"
          style={{ minWidth: 25, maxWidth: 25 }}
        >
          <MonetizationOnOutlinedIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              fontSize: 24,
            }}
          />
        </div>
        <div
          className="d-flex justify-content-start w-100"
          style={{ minWidth: 130 }}
        >
          <span className="three-dots"> {data.task_name}</span>
        </div>
        <div
          className="d-none d-md-flex justify-content-start w-100"
          style={{ maxWidth: 150, minWidth: 30 }}
        >
          <span className="three-dots">
            {data.project_id && data.project_id.project_name}
          </span>
        </div>
        <div
          className="d-none d-md-flex justify-content-start w-100"
          style={{ maxWidth: 140, minWidth: 30 }}
        >
          <span className="three-dots">
            {data.client_id && data.client_id.company_name}
          </span>
        </div>
        <div
          className="d-none d-md-flex justify-content-center w-100"
          style={{ maxWidth: 105, minWidth: 30 }}
        >
          <span className="three-dots">
            {moment(data.createdAt).format("DD MMM YYYY")}
          </span>
        </div>
        <div
          className="d-flex justify-content-center w-100"
          style={{ maxWidth: 105, minWidth: 30 }}
        >
          <span className="three-dots">
            {moment(data.due_date).format("DD MMM YYYY")}
          </span>
        </div>
        <div
          style={{
            width: "100%",
            maxWidth: 120,
            minWidth: 80,
            display: "flex",
            justifyContent: "end",
          }}
        >
          <div className="d-flex gap-2 justify-content-between">
            <div className="dropdown">
              <button
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <MoreHorizIcon
                  fontSize="large"
                  sx={{
                    background: Colors.white,
                    cursor: "pointer",
                    borderRadius: "6px",
                    padding: "2px 4px",
                    height: "30px",
                    color: "grey",
                  }}
                />
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                {data.task_name !== "Invoice deposit" &&
                  !data.invoice_id &&
                  data.status !== "done" && (
                    <li>
                      <button className="dropdown-item">
                        <TextStyles.FontSize14px
                          onClick={() => sendInvoiceHandler(data)}
                        >
                          Send invoice
                        </TextStyles.FontSize14px>
                      </button>
                    </li>
                  )}

                {data.invoice_id && data.status !== "done" && (
                  <li>
                    <button className="dropdown-item">
                      <TextStyles.FontSize14px
                        onClick={() => recordPaymentHandler(data)}
                      >
                        Record full payment
                      </TextStyles.FontSize14px>
                    </button>
                  </li>
                )}

                {data.invoice_id && data.status !== "done" && (
                  <li>
                    <button className="dropdown-item">
                      <TextStyles.FontSize14px
                        onClick={() => resendInvoiceHandler(data)}
                      >
                        Resend Invoice
                      </TextStyles.FontSize14px>
                    </button>
                  </li>
                )}

                {data.status !== "done" && (
                  <li>
                    <button className="dropdown-item">
                      <TextStyles.FontSize14px
                        onClick={() => doneReminderHandler(data)}
                      >
                        Completed
                      </TextStyles.FontSize14px>
                    </button>
                  </li>
                )}

                <li>
                  <button className="dropdown-item">
                    <TextStyles.FontSize14px
                      onClick={() => {
                        setShowDismissModal(true);
                        setConfirmDialog({
                          onConfirm: () => dismissReminderHandler(data),
                        });
                      }}
                    >
                      Dismiss
                    </TextStyles.FontSize14px>
                  </button>
                </li>
              </ul>
            </div>
            <ReactTooltip
              anchorId={`reminder_dragAndDrop${index}`}
              content="Rearrange by dragging"
            />
            {selectedSortingOne === "orders" && (
              <div
                role="button"
                id={`reminder_dragAndDrop${index}`}
                {...attributes}
                {...listeners}
              >
                <DragIndicatorIcon
                  fontSize="large"
                  sx={{
                    background: Colors.white,
                    cursor: "pointer",
                    borderRadius: "6px",
                    padding: "2px 4px",
                    height: "30px",
                    color: "grey",
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </TextStyles.FontSize14px>
    </Styled.TodoRectangle>
  );
};

export default ReminderList;
