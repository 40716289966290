import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { message } from "antd";
import { Modal } from "react-bootstrap";

import ClearIcon from "@mui/icons-material/Clear";

import LoaderSpin from "../../../Commons/LoaderSpin";

import {
  AutoCompleteSelectProject,
  AutoCompleteSelectClient,
} from "../../../Commons/AutoComplete";

import SelectFrequency from "../../../Commons/SelectFrequency";
import SelectFrequencyTimes from "../../../Commons/SelectFrequencyTimes";
import Constant from "../../../Commons/Constant";
import CustomDatePickerOne from "../../../Commons/DatePickers/CustomDatePickerOne";

import Styled from "./EditTaskStyles";
import TextStyles from "../../../../../Constants/TextStyles";
import Colors from "../../../../../Constants/Colors";

import { editTask } from "../../../../../store/service/TaskService";
import { fetchClientForAProject } from "../../../../../store/service/clientService";
import { fetchProjectsForAClient } from "../../../../../store/service/projectService";

const EditTask = ({
  setUpdateRefresh,
  updateRefresh,
  handleClose,
  show,
  selectedTaskData,
}) => {
  const {
    client_id,
    _id,
    task_name,
    due_date,
    hide_until_date,
    project_id,
    repeat_task_id,
    repeat_task,
    status,
  } = selectedTaskData;

  const [repeatTask, setRepeatTask] = useState(false);

  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.tasks.isLoading);
  const clientForAProject = useSelector(
    (state) => state.clients.clientForAProject
  );

  const projectsForAClient = useSelector(
    (state) => state.project.projectsForAClient
  );

  const isClientLoading = useSelector((state) => state.clients.isLoading);
  const isProjectLoading = useSelector((state) => state.project.isLoading);

  const clientOptionData = useSelector(
    (state) => state.clients.clientOptionData
  );

  const projectOptionsData = useSelector(
    (state) => state.project.projectOptionsData
  );

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
    control,
    watch,
  } = useForm({
    defaultValues: {
      task_name: task_name && task_name,
      due_date: due_date ? moment(due_date).toDate() : null,
      hide_until_date: hide_until_date
        ? moment(hide_until_date).toDate()
        : null,
      select_client:
        client_id &&
        clientOptionData &&
        clientOptionData
          .filter((data) => data._id === client_id._id)
          .map((data) => {
            return {
              label: data && data.company_name,
              _id: data && data._id,
            };
          })[0],
      select_project:
        project_id &&
        projectOptionsData &&
        projectOptionsData
          .filter((data) => data._id === project_id._id)
          .map((d) => {
            return {
              _id: d._id,
              title: d.project_name,
              client_id: d.client_id._id,
              client_name: d.client_id.company_name,
            };
          })[0],
      repeat_task_status:
        repeat_task && repeat_task.recurring ? repeat_task.recurring : false,
      frequency:
        repeat_task && repeat_task.frequency
          ? repeat_task.frequency
          : "One time",
      times: repeat_task && repeat_task.times ? repeat_task.times : 1,
      starting_date:
        repeat_task && repeat_task.starting_date
          ? moment(repeat_task.starting_date).toDate()
          : null,
    },
  });

  const repeatTaskStatus = watch("repeat_task_status");

  useEffect(() => {
    setRepeatTask(repeatTaskStatus);
  }, [repeatTaskStatus]);

  const selectProject = watch("select_project");
  const selectClient = watch("select_client");

  useEffect(() => {
    dispatch(
      fetchClientForAProject({
        project_id: selectProject && selectProject._id,
      })
    );
    dispatch(
      fetchProjectsForAClient({
        client_id: selectClient && selectClient._id,
      })
    );
  }, [selectProject, selectClient]);

  const hideDate = watch("hide_until_date");
  const dueDate = watch("due_date");

  const [hideDateErrorMessage, setHideDateErrorMessage] = useState(null);

  const validateHideDate = () => {
    if (hideDate && (dueDate === null || dueDate === undefined)) {
      setHideDateErrorMessage("Please select due date before hide date!");
      return false;
    } else if (hideDate && dueDate !== null && dueDate !== undefined) {
      const hDate = moment(hideDate);
      const dDate = moment(dueDate);
      if (dDate.isSameOrBefore(hDate)) {
        setHideDateErrorMessage(
          `Hide date should be less than ${moment(dueDate).format(
            "DD MMM YYYY"
          )}.`
        );
        return false;
      } else {
        setHideDateErrorMessage(null);
        return true;
      }
    } else {
      setHideDateErrorMessage(null);
      return true;
    }
  };

  useEffect(() => {
    validateHideDate();
  }, [dueDate, hideDate]);

  const formsubmit = (data) => {
    const Obj = {
      repeat_task_id: repeat_task_id,
      assign_to_client_id: data.select_client && data.select_client._id,
      assign_to_project_id: data.select_project && data.select_project._id,
      task_id: _id,
      task_name: data.task_name,
      due_date: moment(data.due_date).toDate(),
      hide_until_date: moment(data.hide_until_date).toDate(),
      status: status,
      starting_date:
        data.repeat_task_status === true && data.starting_date
          ? moment(data.starting_date).toDate()
          : data.repeat_task_status === true
          ? moment().toDate()
          : null,

      frequency: data.repeat_task_status === true ? data.frequency : "One time",
      times:
        data.repeat_task_status === true && data.frequency === "One time"
          ? 1
          : data.repeat_task_status === true && data.frequency !== "One time"
          ? data.times
          : 1,
      recurring: data.repeat_task_status,
      occurance: repeat_task.occurance,
    };

    dispatch(editTask(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
        handleClose();
        reset();
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}`);
      }
    });
  };

  return (
    <Modal
      scrollable={repeatTask}
      show={show}
      onHide={handleClose}
      // className="custom-modal"
      contentClassName="ToMakeModalBorderRudius12px"
      centered
    >
      <form onSubmit={handleSubmit(formsubmit)} className="modal-content">
        <div className="modal-header d-flex justify-content-between">
          <TextStyles.FontSize20px>Edit task</TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </div>
        <div className="modal-body">
          <TextStyles.FontSize14px>
            <label htmlFor="taskName" className="mb-2">
              Task name
            </label>

            <Styled.Input
              type="text"
              id="taskName"
              placeholder="Enter task name"
              name="task_name"
              {...register("task_name", {
                required: "Task name is required.",
              })}
            />
            {errors.task_name && (
              <TextStyles.InValidFeedback>
                {errors.task_name.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="my-3">
            <div className="row">
              <div className="col-6">
                <label htmlFor="due_date" className="mb-2">
                  Due date
                </label>

                <Controller
                  name="due_date"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <CustomDatePickerOne
                        onChange={onChange}
                        selected={value}
                        placeholder="Set due date"
                        allowClear={true}
                      />
                    );
                  }}
                />
              </div>
              <div className="col-6">
                <label htmlFor="hide_until_date" className="mb-2">
                  Hide until
                </label>

                <Controller
                  name={"hide_until_date"}
                  control={control}
                  rules={{ validate: validateHideDate }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <CustomDatePickerOne
                        onChange={onChange}
                        selected={value}
                        placeholder="Set hide date"
                        allowClear={true}
                      />
                    );
                  }}
                />
              </div>
              {hideDateErrorMessage && (
                <TextStyles.InValidFeedback className="mt-2">
                  {hideDateErrorMessage}
                </TextStyles.InValidFeedback>
              )}
            </div>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="project" className="mb-2">
              Select project
            </label>
            <Controller
              control={control}
              name="select_project"
              render={({ field: { onChange, value } }) => (
                <AutoCompleteSelectProject
                  placeholder="Select project"
                  isLoading={isProjectLoading}
                  options={
                    projectsForAClient &&
                    projectsForAClient.map((d) => {
                      return {
                        _id: d && d._id,
                        title: d && d.project_name,
                        client_id: d && d.client_id && d.client_id._id,
                        client_name:
                          d && d.client_id && d.client_id.company_name,
                      };
                    })
                  }
                  defaultValue={value}
                  onChange={onChange}
                />
              )}
            />
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="select_client" className="mb-2">
              Select client
            </label>

            <Controller
              control={control}
              name="select_client"
              render={({ field: { onChange, value } }) => (
                <AutoCompleteSelectClient
                  placeholder="Select client"
                  isLoading={isClientLoading}
                  options={
                    clientForAProject &&
                    clientForAProject.map((data) => {
                      return {
                        label: data && data.company_name,
                        _id: data && data._id,
                      };
                    })
                  }
                  onChange={onChange}
                  defaultValue={value}
                />
              )}
            />
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="mb-3">
            <TextStyles.CheckBox className="form-check">
              <input
                name="repeat_task_status"
                className="form-check-input"
                type="checkbox"
                id="flexCheckChecked"
                {...register("repeat_task_status")}
              />
              <label className="form-check-label" htmlFor="flexCheckChecked">
                Repeat task
              </label>
            </TextStyles.CheckBox>
          </TextStyles.FontSize14px>
          {repeatTask && (
            <>
              <TextStyles.FontSize14px className="mb-3">
                <div className="row">
                  <div className="col">
                    <Controller
                      control={control}
                      name="frequency"
                      render={({ field: { onChange, value } }) => (
                        <SelectFrequency
                          options={Constant.taskFrequencyOptions}
                          placeholder="Select frequency"
                          onChange={onChange}
                          defaultValue={value}
                        />
                      )}
                    />
                  </div>
                  <div className="col">
                    <Controller
                      control={control}
                      name="times"
                      render={({ field: { onChange, value } }) => (
                        <SelectFrequencyTimes
                          options={Constant.taskFrequencyTimesOptions}
                          placeholder="Select times"
                          onChange={onChange}
                          defaultValue={value}
                        />
                      )}
                    />
                  </div>
                </div>
              </TextStyles.FontSize14px>
              <TextStyles.FontSize14px className="mb-3">
                <div className="row">
                  <div className="col-6">
                    <label htmlFor="starting_date" className="mb-2">
                      Starting date
                    </label>

                    <Controller
                      name={"starting_date"}
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <CustomDatePickerOne
                            onChange={onChange}
                            selected={value}
                            placeholder="Set starting date"
                            allowClear={true}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              </TextStyles.FontSize14px>
            </>
          )}
        </div>
        <div className="modal-footer" style={{ display: "inline-block" }}>
          <div className="d-flex gap-4 justify-content-between align-items-center">
            <TextStyles.CancelButton onClick={handleClose}>
              Cancel
            </TextStyles.CancelButton>
            <TextStyles.Button type="submit" disabled={isLoading}>
              {isLoading ? <LoaderSpin /> : "Save"}
            </TextStyles.Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default EditTask;
