import React, { useEffect, useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";

import FolderSharedTwoToneIcon from "@mui/icons-material/FolderSharedTwoTone";
import EventIcon from "@mui/icons-material/Event";
import AdjustOutlinedIcon from "@mui/icons-material/AdjustOutlined";
import FolderTwoToneIcon from "@mui/icons-material/FolderTwoTone";
import PaidTwoToneIcon from "@mui/icons-material/PaidTwoTone";
import ArrowUpwardTwoToneIcon from "@mui/icons-material/ArrowUpwardTwoTone";
import ArrowDownwardTwoToneIcon from "@mui/icons-material/ArrowDownwardTwoTone";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import NoProjectImage from "../../../Assets/assetsnew/no-project.svg";

import { Popover } from "antd";

import CreateProject from "./createProject/createProject.jsx";
import { useHistory } from "react-router-dom";

import Colors from "../../../Constants/Colors";
import Styled from "./projectStyles";
import TextStyles from "../../../Constants/TextStyles";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {
  fetchProjects,
  changeProjectStatus,
  fetchProjectForOptions,
} from "../../../store/service/projectService";
import { message } from "antd";
import ArchiveModal from "../Commons/ArchiveModal";
import EndModal from "../Commons/EndModal";

import { ReactTooltip } from "../Commons/ReactTooltip";

import SelectClients from "../Commons/SelectClients";
import SelectProjectStatus from "../Commons/SelectProjectStatus";
import Pagination from "../Commons/Pagination";
import SelectDate from "../Commons/SelectDate.jsx";
import Joyride, { EVENTS, STATUS } from "react-joyride";
import { updateUserTourSetupData } from "../../../store/service/AuthService";
import LoaderSpin from "../Commons/LoaderSpin.js";
import { projectsActions } from "../../../store/storage/projectSlice.js";

const steps = [
  {
    title: (
      <TextStyles.FontSize18px>
        Create your first project
      </TextStyles.FontSize18px>
    ),
    target: ".create_project_tour",
    content: (
      <TextStyles.GreyFont14px className="d-flex text-start">
        Select the client, project name, start date, and other details. Add
        services and billing schedules as needed.
      </TextStyles.GreyFont14px>
    ),
    disableBeacon: true,
    placement: "bottom-start",
  },
];

export default function projects() {
  const [show, setShow] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const prTotalCount = useSelector((state) => state.project.prTotalCount);
  const isLoading = useSelector((state) => state.project.isLoading);
  const projects = useSelector((state) => state.project.projects);
  const clientOptionData = useSelector(
    (state) => state.clients.clientOptionData
  );

  const toggleFilter = useSelector((state) => state.project.toggleFilter);

  const filteredData = useSelector((state) => state.project.filteredData);

  const filterdDateRange =
    filteredData && filteredData.filterdDateRange
      ? filteredData.filterdDateRange
      : null;

  const filteredClient =
    filteredData && filteredData.filteredClient
      ? filteredData.filteredClient
      : null;

  const filteredProjectStatus =
    filteredData && filteredData.filteredProjectStatus
      ? filteredData.filteredProjectStatus
      : "Running";

  const sortingData = useSelector((state) => state.project.sortingData);

  const selectedSortingOne =
    sortingData && sortingData.selectedSortingOne
      ? sortingData.selectedSortingOne
      : "project_start_date";
  const selectedSortingTwo =
    sortingData && sortingData.selectedSortingTwo
      ? sortingData.selectedSortingTwo
      : "desc";

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const handleCloseArchiveModal = () => setShowArchiveModal(false);
  const [showEndModal, setShowEndModal] = useState(false);
  const handleCloseEndModal = () => setShowEndModal(false);

  // filtered state
  const [updateRefresh, setUpdateRefresh] = useState(false);
  const toggleShowFilter = () => {
    dispatch(projectsActions.toggleAction());
  };

  // tour related coding
  const tourData = useSelector((state) => state.auth.userTourSetupData);
  const [run, setRun] = useState(true);

  useEffect(() => {
    setRun(tourData && tourData.project_tour);
  }, [tourData]);

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
    }
    if ([EVENTS.TOUR_END, EVENTS.SKIP].includes(type)) {
      dispatch(updateUserTourSetupData({ project_tour: false }));
    }
  };

  // sorting clients
  const [openSorting, setOpenSorting] = useState(false);

  const hideSortingHandler = () => {
    setOpenSorting(false);
  };
  const handleOpenSortingChange = (newOpen) => {
    setOpenSorting(newOpen);
  };

  const sortingOptionChangeHandler = (event) => {
    dispatch(
      projectsActions.sortingAction({
        selectedSortingOne: event.target.value,
      })
    );
  };

  const sortingOrderingChangeHandler = (event) => {
    dispatch(
      projectsActions.sortingAction({
        selectedSortingTwo: event.target.value,
      })
    );
  };

  //pagination
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    setPage(1);
  }, [filteredData, itemsPerPage]);

  useEffect(() => {
    if (projects && projects.length === 0) {
      if (page > 1) {
        setPage(page - 1);
      }
    }
  }, [projects]);

  // Invoke when user click to request another page.
  const handlePageClick = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    let start_time = null;
    let end_time = null;

    if (filterdDateRange && typeof filterdDateRange === "object") {
      start_time = moment(filterdDateRange[1]).endOf("days").toDate();
      end_time = moment(filterdDateRange[0]).startOf("days").toDate();
    } else {
      if (filterdDateRange === "Last 7 days") {
        start_time = moment().endOf("days").toDate();
        end_time = moment(start_time).subtract(6, "d").startOf("days").toDate();
      } else if (filterdDateRange === "Last 15 days") {
        start_time = moment().endOf("days").toDate();
        end_time = moment(start_time)
          .subtract(14, "d")
          .startOf("days")
          .toDate();
      } else if (filterdDateRange === "Last 30 days") {
        start_time = moment().endOf("days").toDate();
        end_time = moment(start_time)
          .subtract(29, "d")
          .startOf("days")
          .toDate();
      } else if (filterdDateRange === "Last 45 days") {
        start_time = moment().endOf("days").toDate();
        end_time = moment(start_time)
          .subtract(44, "d")
          .startOf("days")
          .toDate();
      } else if (filterdDateRange === "Last 60 days") {
        start_time = moment().endOf("days").toDate();
        end_time = moment(start_time)
          .subtract(59, "d")
          .startOf("days")
          .toDate();
      } else if (filterdDateRange === "Last 90 days") {
        start_time = moment().endOf("days").toDate();
        end_time = moment(start_time)
          .subtract(89, "d")
          .startOf("days")
          .toDate();
      } else if (filterdDateRange === "This month") {
        start_time = moment().endOf("month").toDate();
        end_time = moment().startOf("month").toDate();
      } else if (filterdDateRange === "Last month") {
        start_time = moment().subtract(1, "months").endOf("month").toDate();
        end_time = moment().subtract(1, "months").startOf("month").toDate();
      } else if (filterdDateRange === "This year") {
        start_time = moment().endOf("year").toDate();
        end_time = moment().startOf("year").toDate();
      } else if (filterdDateRange === "Last year") {
        start_time = moment().subtract(1, "years").endOf("year").toDate();
        end_time = moment().subtract(1, "years").startOf("year").toDate();
      }
    }

    const Obj = {
      page: page,
      perPage: itemsPerPage,
      start_time,
      end_time,
      client_id: filteredClient !== 0 ? filteredClient : null,
      status:
        filteredProjectStatus === "All statuses" ? null : filteredProjectStatus,
      sortBy: selectedSortingOne,
      orderBy: selectedSortingTwo,
    };
    dispatch(fetchProjects(Obj));
  }, [updateRefresh, filteredData, page, itemsPerPage, sortingData]);

  useEffect(() => {
    dispatch(fetchProjectForOptions());
  }, [updateRefresh]);

  const launchProject = (item) => {
    dispatch(projectsActions.setSelectedProject(item));
    history.push(`/db/projects/${item._id}/terms`);
  };

  const EndProjectHandler = (id, status) => {
    dispatch(changeProjectStatus({ project_id: id, status })).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleCloseEndModal();
        setUpdateRefresh(!updateRefresh);
        message.success("Project ended successfully!");
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };
  const ArchiveProjectHandler = (id, status) => {
    dispatch(changeProjectStatus({ project_id: id, status })).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleCloseArchiveModal();
        setUpdateRefresh(!updateRefresh);
        message.success("Project archived successfully!");
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  const UnarchiveProjectHandler = (id, status) => {
    dispatch(changeProjectStatus({ project_id: id, status })).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
        message.success("Project unarchived successfully!");
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  const unendProjectHandler = (id, status) => {
    dispatch(changeProjectStatus({ project_id: id, status })).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
        message.success("Project unend successfully!");
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  const sortingContent = () => {
    return (
      <TextStyles.FontSize14px>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="company_name">
            <FolderSharedTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">Client name</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="company_name"
              type="radio"
              name="sorting"
              value="company_name"
              checked={selectedSortingOne === "company_name"}
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="project_name">
            <FolderTwoToneIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Project name</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="project_name"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "project_name"}
              value="project_name"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="project_start_date">
            <EventIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Start date</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="project_start_date"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "project_start_date"}
              value="project_start_date"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="project_end_date">
            <EventIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">End date</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="project_end_date"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "project_end_date"}
              value="project_end_date"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="project_status">
            <AdjustOutlinedIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Status</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="project_status"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "status"}
              value="status"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="totalInvoicedAmount">
            <PaidTwoToneIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Invoiced amount</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="totalInvoicedAmount"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "totalInvoicedAmount"}
              value="totalInvoicedAmount"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <hr
          style={{
            borderColor: "f9f9f9",
            marginTop: 10,
            marginBottom: 10,
            borderTop: "none",
            opacity: 0.1,
          }}
        />

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="asc">
            <ArrowUpwardTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">Ascending (A-Z)</span>
          </label>

          <div className="form-check">
            <input
              className="form-check-input"
              id="asc"
              type="radio"
              name="ordering"
              checked={selectedSortingTwo === "asc"}
              value="asc"
              onChange={sortingOrderingChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="desc">
            <ArrowDownwardTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">Descending (Z-A)</span>
          </label>

          <div className="form-check">
            <input
              className="form-check-input"
              id="desc"
              type="radio"
              name="ordering"
              checked={selectedSortingTwo === "desc"}
              value="desc"
              onChange={sortingOrderingChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting className="mt-3">
          <TextStyles.GreyFont16px
            onClick={hideSortingHandler}
            style={{
              width: "100%",
              fontWeight: 500,
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            Done
          </TextStyles.GreyFont16px>
        </Styled.Sorting>
      </TextStyles.FontSize14px>
    );
  };

  const setSortingActiveHandler = (selectedSort) => {
    dispatch(
      projectsActions.sortingAction({
        selectedSortingOne: selectedSort,
        selectedSortingTwo: selectedSortingTwo === "desc" ? "asc" : "desc",
      })
    );
  };

  return (
    <div
      style={{
        backgroundColor: Colors.backgroundGrey,
        minHeight: "100vh",
        padding: "105px 0 60px 0",
        contain: "content",
      }}
    >
      <div className="px-lg-4 px-md-4 px-sm-3 px-2">
        <div className="d-flex flex-wrap gap-3 justify-content-between align-items-center mb-3">
          <Styled.NewCreate
            onClick={() => setShow(true)}
            className="create_project_tour"
          >
            <span className="ms-2">
              <AddOutlinedIcon sx={{ fontSize: 22, color: Colors.white }} />
            </span>
            <span className="fw-medium" style={{ fontSize: 16 }}> New project</span>
          </Styled.NewCreate>
          <div className="d-flex justify-content-end align-items-center ms-auto">
            <TextStyles.HoverEffectSpan onClick={toggleShowFilter}>
              Filter
            </TextStyles.HoverEffectSpan>

            <div
              className="client-partion-rectangle mx-2"
              style={{
                width: "2px",
                height: "23px",
                backgroundColor: "#7d849e",
              }}
            />
            <TextStyles.FontSize16px>
              <Popover
                placement="bottomRight"
                // title={"Hello"}
                content={sortingContent}
                trigger="click"
                open={openSorting}
                onOpenChange={handleOpenSortingChange}
              >
                <TextStyles.HoverEffectSpan sort={true}>
                  Sort
                  <ArrowDropDownOutlinedIcon
                    sx={{
                      color: Colors.font2,
                      fontSize: 27,
                      transform: openSorting ? "rotate(180deg)" : "",
                    }}
                  />
                </TextStyles.HoverEffectSpan>
              </Popover>
            </TextStyles.FontSize16px>
          </div>
        </div>

        {toggleFilter && (
          <div className="d-flex flex-wrap justify-content-start mb-3">
            <div className="me-3 mb-2">
              <TextStyles.FontSize14px className="d-flex mb-2">
                Date range
              </TextStyles.FontSize14px>
              <SelectDate
                defaultValue={filterdDateRange && filterdDateRange}
                onChange={(value) => {
                  dispatch(
                    projectsActions.filterAction({
                      filterdDateRange: value,
                    })
                  );
                }}
              />
            </div>
            <div className="me-3 mb-2">
              <TextStyles.FontSize14px className="d-flex mb-2">
                Client
              </TextStyles.FontSize14px>
              <SelectClients
                options={clientOptionData}
                defaultValue={filteredClient && filteredClient}
                onChange={(e) =>
                  dispatch(
                    projectsActions.filterAction({
                      filteredClient: e.target.value,
                    })
                  )
                }
              />
            </div>
            <div className="me-3 mb-2">
              <TextStyles.FontSize14px className="d-flex mb-2">
                Status
              </TextStyles.FontSize14px>
              <SelectProjectStatus
                defaultValue={filteredProjectStatus && filteredProjectStatus}
                onChange={(e) =>
                  dispatch(
                    projectsActions.filterAction({
                      filteredProjectStatus: e.target.value,
                    })
                  )
                }
              />
            </div>
          </div>
        )}
        <Styled.TableHead className="mt-3">
          <div className="d-flex gap-2 align-items-center">
            <TextStyles.FontSize14px
              style={{
                width: "120%",
                display: "flex",
                textAlign: "start",
                minWidth: 30,
              }}
            >
              <ReactTooltip anchorId="projects" content="Sort by project" />
              <span
                className="three-dots hover-project"
                id="projects"
                role="button"
                onClick={() => setSortingActiveHandler("project_name")}
              >
                Project
                {selectedSortingOne === "project_name" ? (
                  <>
                    {selectedSortingTwo === "desc" ? (
                      <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                    ) : (
                      <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                    )}
                  </>
                ) : (
                  <>
                    {selectedSortingTwo === "asc" ? (
                      <ArrowUpwardRoundedIcon
                        sx={{
                          fontSize: "1.2rem",
                        }}
                        className="visible-project"
                      />
                    ) : (
                      <ArrowDownwardRoundedIcon
                        sx={{
                          fontSize: "1.2rem",
                        }}
                        className="visible-project"
                      />
                    )}
                  </>
                )}
              </span>
            </TextStyles.FontSize14px>

            <TextStyles.FontSize14px
              style={{
                width: "120%",
                display: "flex",
                textAlign: "start",
                minWidth: 30,
              }}
            >
              <ReactTooltip anchorId="totalClients" content="Sort by client" />
              <span
                className="three-dots hover-company"
                id="totalClients"
                role="button"
                onClick={() => setSortingActiveHandler("company_name")}
              >
                Client
                {selectedSortingOne === "company_name" ? (
                  <>
                    {selectedSortingTwo === "desc" ? (
                      <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                    ) : (
                      <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                    )}
                  </>
                ) : (
                  <>
                    {selectedSortingTwo === "asc" ? (
                      <ArrowUpwardRoundedIcon
                        sx={{
                          fontSize: "1.2rem",
                        }}
                        className="visible-company"
                      />
                    ) : (
                      <ArrowDownwardRoundedIcon
                        sx={{
                          fontSize: "1.2rem",
                        }}
                        className="visible-company"
                      />
                    )}
                  </>
                )}
              </span>
            </TextStyles.FontSize14px>

            <TextStyles.FontSize14px
              style={{
                width: "100%",
                display: "flex",
                textAlign: "start",
                minWidth: 30,
              }}
            >
              <ReactTooltip anchorId="startDate" content="Sort by start date" />
              <span
                className="three-dots hover-start-date"
                id="startDate"
                role="button"
                onClick={() => setSortingActiveHandler("project_start_date")}
              >
                Start date
                {selectedSortingOne === "project_start_date" ? (
                  <>
                    {selectedSortingTwo === "desc" ? (
                      <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                    ) : (
                      <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                    )}
                  </>
                ) : (
                  <>
                    {selectedSortingTwo === "asc" ? (
                      <ArrowUpwardRoundedIcon
                        sx={{
                          fontSize: "1.2rem",
                        }}
                        className="visible-start-date"
                      />
                    ) : (
                      <ArrowDownwardRoundedIcon
                        sx={{
                          fontSize: "1.2rem",
                        }}
                        className="visible-start-date"
                      />
                    )}
                  </>
                )}
              </span>
            </TextStyles.FontSize14px>

            <TextStyles.FontSize14px
              style={{
                width: "100%",
                display: "flex",
                textAlign: "start",
                minWidth: 30,
              }}
            >
              <ReactTooltip anchorId="endDate" content="Sort by end date" />
              <span
                className="three-dots hover-end-date"
                id="endDate"
                role="button"
                onClick={() => setSortingActiveHandler("project_end_date")}
              >
                End date
                {selectedSortingOne === "project_end_date" ? (
                  <>
                    {selectedSortingTwo === "desc" ? (
                      <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                    ) : (
                      <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                    )}
                  </>
                ) : (
                  <>
                    {selectedSortingTwo === "asc" ? (
                      <ArrowUpwardRoundedIcon
                        sx={{
                          fontSize: "1.2rem",
                        }}
                        className="visible-end-date"
                      />
                    ) : (
                      <ArrowDownwardRoundedIcon
                        sx={{
                          fontSize: "1.2rem",
                        }}
                        className="visible-end-date"
                      />
                    )}
                  </>
                )}
              </span>
            </TextStyles.FontSize14px>

            <TextStyles.FontSize14px
              style={{
                width: "80%",
                display: "flex",
                justifyContent: "center",
                textAlign: "start",
                minWidth: 30,
              }}
            >
              <ReactTooltip anchorId="status" content="Sort by status" />
              <span
                className="three-dots hover-status"
                id="status"
                role="button"
                onClick={() => setSortingActiveHandler("status")}
              >
                Status
                {selectedSortingOne === "status" ? (
                  <>
                    {selectedSortingTwo === "desc" ? (
                      <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                    ) : (
                      <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                    )}
                  </>
                ) : (
                  <>
                    {selectedSortingTwo === "asc" ? (
                      <ArrowUpwardRoundedIcon
                        sx={{
                          fontSize: "1.2rem",
                        }}
                        className="visible-status"
                      />
                    ) : (
                      <ArrowDownwardRoundedIcon
                        sx={{
                          fontSize: "1.2rem",
                        }}
                        className="visible-status"
                      />
                    )}
                  </>
                )}
              </span>
            </TextStyles.FontSize14px>

            <TextStyles.FontSize14px
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                minWidth: 30,
              }}
            >
              <ReactTooltip
                anchorId="amountInvoiced"
                content="Sort by amount invoiced"
              />
              <span
                className="three-dots hover-amount"
                id="amountInvoiced"
                role="button"
                onClick={() => setSortingActiveHandler("totalInvoicedAmount")}
              >
                Amount invoiced
                {selectedSortingOne === "totalInvoicedAmount" ? (
                  <>
                    {selectedSortingTwo === "desc" ? (
                      <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                    ) : (
                      <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                    )}
                  </>
                ) : (
                  <>
                    {selectedSortingTwo === "asc" ? (
                      <ArrowUpwardRoundedIcon
                        sx={{
                          fontSize: "1.2rem",
                        }}
                        className="visible-amount"
                      />
                    ) : (
                      <ArrowDownwardRoundedIcon
                        sx={{
                          fontSize: "1.2rem",
                        }}
                        className="visible-amount"
                      />
                    )}
                  </>
                )}
              </span>
            </TextStyles.FontSize14px>

            <TextStyles.FontSize14px
              className="project-actions"
              style={{
                width: "80%",
              }}
            />
          </div>
        </Styled.TableHead>

        {projects && projects.length > 0 ? (
          projects.map((item, index) => {
            return (
              <Styled.TableCell
                key={index}
                style={{
                  borderRadius:
                    projects.length - 1 === index ? "0 0 12px 12px" : "",
                }}
                onClick={() => launchProject(item)}
              >
                <div className="d-flex gap-2 align-items-center w-100">
                  <TextStyles.FontSize14px
                    style={{
                      width: "120%",
                      display: "flex",
                      textAlign: "start",
                      minWidth: 30,
                    }}
                  >
                    <span className="three-dots w-75">{item.project_name}</span>
                  </TextStyles.FontSize14px>
                  <TextStyles.FontSize14px
                    style={{
                      width: "120%",
                      display: "flex",
                      textAlign: "start",
                      minWidth: 30,
                    }}
                  >
                    <span className="three-dots w-75">
                      {item.client_id && item.client_id.company_name}
                    </span>
                  </TextStyles.FontSize14px>
                  <TextStyles.FontSize14px
                    style={{
                      width: "100%",
                      display: "flex",
                      textAlign: "start",
                      minWidth: 30,
                    }}
                  >
                    <span className="three-dots">
                      {item.project_start_date &&
                        moment(item.project_start_date).format("DD MMM YYYY")}
                    </span>
                  </TextStyles.FontSize14px>
                  <TextStyles.FontSize14px
                    style={{
                      width: "100%",
                      display: "flex",
                      textAlign: "start",
                      minWidth: 30,
                    }}
                  >
                    <span className="three-dots">
                      {item.project_end_date
                        ? moment(item.project_end_date).format("DD MMM YYYY")
                        : "On going"}
                    </span>
                  </TextStyles.FontSize14px>
                  <div
                    style={{
                      width: "80%",
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "start",
                      minWidth: 30,
                    }}
                  >
                    <TextStyles.FontSize14px
                      className="three-dots"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "0.2rem",
                        fontWeight: 600,
                        boxSizing: "border-box",
                        padding: "0.25rem 0.5rem",
                        maxHeight: "1.5rem",
                        background:
                          item.status === "Upcoming"
                            ? "#FFF5E6"
                            : item.status === "Running"
                            ? "#DDF8EC"
                            : item.status === "Ended"
                            ? "#ffeded"
                            : item.status === "Archived"
                            ? "#fafafa"
                            : "",

                        color:
                          item.status === "Upcoming"
                            ? "#f09528"
                            : item.status === "Running"
                            ? "#188652"
                            : item.status === "Ended"
                            ? "#f74040"
                            : item.status === "Archived"
                            ? Colors.font2
                            : "",
                      }}
                    >
                      <span className="three-dots">{item.status}</span>
                    </TextStyles.FontSize14px>
                  </div>
                  <TextStyles.FontSize14px
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      minWidth: 30,
                    }}
                  >
                    <span className="three-dots">
                      {item.currency && item.currency.symbol}
                      {parseFloat(item.totalInvoicedAmount).toFixed(2)}
                    </span>
                  </TextStyles.FontSize14px>
                  <div
                    className="project-actions"
                    style={{
                      width: "80%",
                    }}
                  >
                    <div className="editBtn_desktop">
                      <div
                        id={`edit_project${index}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          launchProject(item);
                        }}
                      >
                        <BorderColorOutlinedIcon
                          sx={{
                            color: Colors.success,
                            cursor: "pointer",
                            fontSize: 30,
                            background: Colors.white,
                            padding: "2px 6px",
                            borderRadius: "6px",
                            width: 35,
                            height: 30,
                          }}
                        />
                      </div>

                      <ReactTooltip
                        anchorId={`edit_project${index}`}
                        content="Edit project"
                      />
                    </div>

                    <div
                      className="dropdown"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <button
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <MoreHorizIcon
                          fontSize="large"
                          sx={{
                            background: Colors.white,
                            cursor: "pointer",
                            borderRadius: "6px",
                            padding: "2px 4px",
                            height: "30px",
                            color: "grey",
                          }}
                        />
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li className="editBtn_mobile">
                          <button className="dropdown-item">
                            <TextStyles.FontSize14px
                              onClick={(e) => {
                                e.stopPropagation();
                                launchProject(item);
                              }}
                            >
                              Edit project
                            </TextStyles.FontSize14px>
                          </button>
                        </li>

                        {item.status !== "Ended" && (
                          <li>
                            <button className="dropdown-item">
                              <TextStyles.FontSize14px
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setShowEndModal(true);
                                  setConfirmDialog({
                                    onConfirm: () =>
                                      EndProjectHandler(item._id, "Ended"),
                                  });
                                }}
                              >
                                End project
                              </TextStyles.FontSize14px>
                            </button>
                          </li>
                        )}
                        {item.status === "Ended" && (
                          <li>
                            <button className="dropdown-item">
                              <TextStyles.FontSize14px
                                onClick={(e) => {
                                  e.stopPropagation();
                                  unendProjectHandler(item._id, "Running");
                                }}
                              >
                                Resume project
                              </TextStyles.FontSize14px>
                            </button>
                          </li>
                        )}
                        {item.status !== "Archived" && (
                          <li>
                            <button className="dropdown-item">
                              <TextStyles.FontSize14px
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setShowArchiveModal(true);
                                  setConfirmDialog({
                                    onConfirm: () =>
                                      ArchiveProjectHandler(
                                        item._id,
                                        "Archived"
                                      ),
                                  });
                                }}
                              >
                                Archive project
                              </TextStyles.FontSize14px>
                            </button>
                          </li>
                        )}
                        {item.status === "Archived" && (
                          <li>
                            <button className="dropdown-item">
                              <TextStyles.FontSize14px
                                onClick={(e) => {
                                  e.stopPropagation();
                                  UnarchiveProjectHandler(
                                    item._id,
                                    "unarchive"
                                  );
                                }}
                              >
                                Unarchive project
                              </TextStyles.FontSize14px>
                            </button>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </Styled.TableCell>
            );
          })
        ) : isLoading && (projects === null || projects.length === 0) ? (
          <Styled.ListBox>
            <LoaderSpin color={Colors.primary} />
          </Styled.ListBox>
        ) : (
          !isLoading &&
          (projects === null || projects.length === 0) && (
            <Styled.ListBox>
              <img src={NoProjectImage} alt="no-project-img" />
              <TextStyles.GreyFont18px className="mt-2">
                There are no projects created yet.
              </TextStyles.GreyFont18px>
            </Styled.ListBox>
          )
        )}
        <div className="d-flex justify-content-center py-4">
          {prTotalCount > itemsPerPage && (
            <Pagination
              handlePageClick={handlePageClick}
              pageCount={Math.ceil(prTotalCount / itemsPerPage)}
              page={page}
            />
          )}
        </div>
        <Joyride
          steps={steps}
          run={run}
          disableBeacon
          continuous={true}
          showSkipButton={true}
          showProgress={true}
          scrollToFirstStep={false}
          callback={handleJoyrideCallback}
          scrollOffset={200}
          styles={{
            options: {
              primaryColor: Colors.primary,
              textColor: Colors.font1,
              zIndex: 50,
              overlayColor: "rgba(0, 0, 0, 0.2)",
              spotlightShadow: "0 0 15px rgba(0, 0, 0, 0)",
            },
            beacon: {
              zIndex: 50,
            },
            overlay: {
              zIndex: 50,
            },
            tooltipTitle: {
              fontSize: 18,
              margin: 0,
            },
            tooltipContent: {
              padding: "10px 0 0 0",
            },
          }}
        />
        {show && (
          <CreateProject
            show={show}
            handleClose={() => setShow(false)}
            updateRefresh={updateRefresh}
            setUpdateRefresh={setUpdateRefresh}
          />
        )}

        {showEndModal && (
          <EndModal
            title="project"
            confirmDialog={confirmDialog}
            showEndModal={showEndModal}
            handleCloseEndModal={handleCloseEndModal}
          />
        )}
        {showArchiveModal && (
          <ArchiveModal
            title="project"
            confirmDialog={confirmDialog}
            showArchiveModal={showArchiveModal}
            handleCloseArchiveModal={handleCloseArchiveModal}
          />
        )}
      </div>
    </div>
  );
}
