import { createSlice } from "@reduxjs/toolkit";
import {
  fetchReminders,
  dismissedReminder,
  doneReminder,
  updateOrderingOfReminder,
} from "../service/reminderService";

const initialReminderState = {
  reminders: null,
  remindersTotalCount: null,
  isLoading: false,
  error: null,
  filteredData: {},
  toggleFilter: true,
  sortingData: {},
};

export const reminderSlice = createSlice({
  name: "reminder",
  initialState: initialReminderState,
  reducers: {
    filterAction: (state, action) => {
      state.filteredData = { ...state.filteredData, ...action.payload };
    },
    toggleAction: (state) => {
      state.toggleFilter = !state.toggleFilter;
    },
    sortingAction: (state, action) => {
      state.sortingData = { ...state.sortingData, ...action.payload };
    },
    dragAndDropAction: (state, action) => {
      state.reminders = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch reminders
      .addCase(fetchReminders.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchReminders.fulfilled, (state, action) => {
        state.isLoading = false;
        state.reminders = action.payload.data.data;
        state.remindersTotalCount = action.payload.data.remindersTotalCount;
      })
      .addCase(fetchReminders.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // dismiss reminder
      .addCase(dismissedReminder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(dismissedReminder.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(dismissedReminder.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // done reminder
      .addCase(doneReminder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(doneReminder.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(doneReminder.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // update order of reminders
      .addCase(updateOrderingOfReminder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateOrderingOfReminder.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updateOrderingOfReminder.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});
export const reminderActions = reminderSlice.actions;
export default reminderSlice.reducer;
