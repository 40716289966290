import React from "react";

import TextStyles from "../../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";

const RightToAuthorship = (props) => {
  const selectedData = props && props.selectedData;

  const heading =
    selectedData &&
    selectedData.rightToAuthorship &&
    selectedData.rightToAuthorship.heading
      ? selectedData &&
        selectedData.rightToAuthorship &&
        selectedData.rightToAuthorship.heading
      : "Right to Authorship Credit";

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.rightToAuthorship &&
          selectedData.rightToAuthorship.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox className="mt-4">
        <TextStyles.FontSize20px className="mb-3 fw-medium">
          {heading}
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px className="lh-lg">
          {selectedData &&
          selectedData.rightToAuthorship &&
          selectedData.rightToAuthorship.paragraph ? (
            <div style={{ whiteSpace: "break-spaces" }}>
              {selectedData.rightToAuthorship.paragraph}
            </div>
          ) : selectedData &&
            selectedData.rightToAuthorship &&
            selectedData.rightToAuthorship.retain_rights === true ? (
            <div>
              <div>
                The parties acknowledge that the Contractor is the creator of
                the deliverables. The Client is not obligated to proactively
                display the Contractor's name alongside the deliverables but
                must not mislead others by attributing the creation of the
                deliverables to anyone other than the Contractor. The Client
                agrees that the Contractor may use the work product as part of
                their portfolio, websites, galleries, and other media solely for
                the purpose of showcasing the Contractor's work, without any
                other purpose.
              </div>
              <div className="mt-2">
                The Contractor shall not publish any confidential or non-public
                work without obtaining the prior written consent of the Client.
              </div>
            </div>
          ) : (
            <div>
              <div>
                Both parties mutually agree that the Client must appropriately
                acknowledge the Contractor as the creator of the deliverables
                when requested. The Client is not obligated to proactively
                display the Contractor's name alongside the deliverables, but
                the Client must not mislead others by attributing the creation
                of the deliverables to anyone other than the Contractor.
              </div>
              <div className="mt-3">
                The Contractor shall refrain from publishing any confidential or
                non-public work without obtaining the prior written consent of
                the Client.
              </div>
            </div>
          )}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
    </div>
  );
};

export default RightToAuthorship;
