import React from "react";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { message } from "antd";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateReimbursementPC } from "../../../../../../store/service/proposalAndContractService";
import Colors from "../../../../../../Constants/Colors";

const ExpenseReimbursement = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const dispatch = useDispatch();

  const isReimbursementRequired =
    selectedData &&
    selectedData.reimbursement &&
    selectedData.reimbursement.required
      ? true
      : false;

  const toggleReimbursementViewHandler = () => {
    const Obj = {
      _id: selectedData._id,
      reimbursement: {
        required: isReimbursementRequired ? false : true,
      },
    };
    dispatch(updateReimbursementPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        message.success(
          isReimbursementRequired
            ? "Clause hidden successfully!"
            : "Clause unhidden successfully!"
        );
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div>
      <Styled.ReviewContentBox className="mt-4">
        <div
          className="edit-delete-icons"
          onClick={toggleReimbursementViewHandler}
        >
          {isReimbursementRequired ? (
            <VisibilityOffOutlinedIcon
              sx={{
                cursor: "pointer",
                color: Colors.font2,
                opacity: 0.8,
                "&:hover": {
                  opacity: 1,
                },
              }}
            />
          ) : (
            <VisibilityOutlinedIcon
              sx={{
                cursor: "pointer",
                color: Colors.font2,
                opacity: 0.8,
                "&:hover": {
                  opacity: 1,
                },
              }}
            />
          )}
        </div>

        <TextStyles.FontSize20px className="mb-3">
          <span
            className={`fw-medium ${isReimbursementRequired ? "" : "disabled"}`}
          >
            Expense Reimbursement
          </span>
        </TextStyles.FontSize20px>

        <TextStyles.FontSize14px
          className={`lh-lg ${isReimbursementRequired ? "" : "disabled"}`}
        >
          Client shall reimburse all reasonable expenses that have been
          pre-authorized in writing by the Client in advance. Payment for such
          expenses shall be made within
          <span
            className="mx-1"
            style={{ color: Colors.primary, fontWeight: 500 }}
          >
            {selectedData &&
              selectedData.payment_terms &&
              selectedData.payment_terms.payment_due}
          </span>
          of receipt of an itemized invoice.
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
    </div>
  );
};

export default ExpenseReimbursement;
