import React from "react";
import TextStyles from "../../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";

import Colors from "../../../../../../../Constants/Colors";

const LatePayment = (props) => {
  const selectedData = props && props.selectedData;

  const heading =
    selectedData &&
    selectedData.late_payment_fee &&
    selectedData.late_payment_fee.heading
      ? selectedData.late_payment_fee.heading
      : "Late Payment";

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.late_payment_fee &&
          selectedData.late_payment_fee.required === true
            ? "inline"
            : "none",
      }}
    >
          <Styled.ReviewContentBox className="mt-4">
            <TextStyles.FontSize20px className="mb-3 fw-medium">
              {heading}
            </TextStyles.FontSize20px>
              <TextStyles.FontSize14px className="lh-lg">
                In case of delayed payment of an invoice, the Contractor
                reserves the right to charge a late payment fee of
                {selectedData &&
                  selectedData.late_payment_fee &&
                  selectedData.late_payment_fee.late_payment ===
                    "Percentage" && (
                    <span
                      className="mx-1"
                      style={{ color: Colors.primary, fontWeight: 500 }}
                    >
                      {`${selectedData.late_payment_fee.late_payment_percentage}% per month`}
                    </span>
                  )}
                {selectedData &&
                  selectedData.late_payment_fee &&
                  selectedData.late_payment_fee.late_payment === "Amount" && (
                    <span
                      className="mx-1"
                      style={{ color: Colors.primary, fontWeight: 500 }}
                    >
                      {`${selectedData.currency.symbol}${selectedData.late_payment_fee.late_payment_amount} as late payment fee`}
                    </span>
                  )}
                . Acceptance of these service charges by Contractor does not
                waive any rights or remedies in the event of Client's breach of
                this Agreement. All payment obligations are non-cancellable, and
                fees paid are non-refundable.
              </TextStyles.FontSize14px>
          </Styled.ReviewContentBox>
    </div>
  );
};

export default LatePayment;
