import styled from "styled-components";
import Colors from "../../../../../Constants/Colors";

const Input = styled.input`
  width: 100%;
  margin-bottom: 6px;
  background-color: ${Colors.white};
  border: 1px solid ${Colors.borderInput};
  padding: 0 1rem;
  height: 3.15rem;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border 0.4s ease;
  :focus {
    border: 1px solid ${Colors.primary};
  }

  &:hover {
    border: 1px solid ${Colors.primary};
  }
`;

export default {
  Input,
};
