import React, { useEffect, useState } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { authActions } from "../../store/storage/authSlice";
import { useDispatch } from "react-redux";
import moment from "moment";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      try {
        const tokenExpiration = jwtDecode(token).exp;
        const dateNow = moment().unix();
        if (tokenExpiration > dateNow) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
          dispatch(authActions.logout());
        }
      } catch (error) {
        console.error("Error decoding token:", error);
        setIsAuthenticated(false);
        dispatch(authActions.logout());
      }
    } else {
      setIsAuthenticated(false);
      dispatch(authActions.logout());
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (!isAuthenticated && pathname !== "/db/login") {
      localStorage.setItem("pathname", pathname);
    } else {
      localStorage.removeItem("pathname");
    }
  }, [isAuthenticated, pathname]);

  if (isAuthenticated === null) {
    return null; // Show loader or empty state
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/db/login" />
      }
    />
  );
};

export default ProtectedRoute;
