import React from "react";
import TextStyles from "../../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";

const NonSolicit = (props) => {
  const selectedData = props && props.selectedData;

  const heading =
    selectedData && selectedData.non_solicit && selectedData.non_solicit.heading
      ? selectedData &&
        selectedData.non_solicit &&
        selectedData.non_solicit.heading
      : "Non-Solicit";
  const paragraph =
    selectedData &&
    selectedData.non_solicit &&
    selectedData.non_solicit.paragraph
      ? selectedData &&
        selectedData.non_solicit &&
        selectedData.non_solicit.paragraph
      : "Contractor agrees that throughout the period of providing Services and for one year thereafter, they will refrain from encouraging or soliciting any employee, vendor, client, or contractor of the Client to depart from the Client for any reason.";

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.non_solicit &&
          selectedData.non_solicit.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox className="mt-4">
        <TextStyles.FontSize20px className="mb-3 fw-medium">
          {heading}
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px className="lh-lg">{paragraph}</TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
    </div>
  );
};

export default NonSolicit;
