import React from "react";
import { useDispatch, useSelector } from "react-redux";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import Styled from "./QuickSetupStyles";
import Colors from "../../../../../Constants/Colors";
import TextStyles from "../../../../../Constants/TextStyles";
import SelectServiceType from "../../../Commons/SelectServiceType";
import { invoiceActions } from "../../../../../store/storage/invoiceSlice";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import moment from "moment";

const QuickSetup = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const location = useLocation();
  const pathname =
    location && location.pathname.split("/").slice(0, -1).join("/");

  const selectedInvoiceData = useSelector(
    (state) => state.invoices.selectedInvoiceData
  );

  const servicesList = 
    selectedInvoiceData && selectedInvoiceData.line_item_services;
  const { userCurrency } = useSelector((state) => state.auth);
  const currencySymbol =
    userCurrency && userCurrency.currency && userCurrency.currency.symbol;
  const { register, watch, handleSubmit, control } = useForm({
    defaultValues: {
      servicesList: servicesList
        ? servicesList
        : [
            {
              service_name: "",
              service_rate: "",
              service_rate_type: "Flat fee",
              service_rate_count: "1",
              desc: "",
            },
          ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "servicesList",
  });

  const formsubmit = (data) => {
    const Obj = {
      currency: userCurrency && userCurrency.currency,
      line_item_services: data.servicesList.map((d) => {
        return {
          service_name: d.service_name,
          service_rate: d.service_rate,
          service_rate_type: d.service_rate_type
            ? d.service_rate_type
            : "Flat fee",
          service_rate_count: d.service_rate_count
            ? parseFloat(d.service_rate_count)
            : 1,
          desc: d.desc,
        };
      }),
      invoice_services: [],
      taxes: [],
      invoice_title: "Invoice",
      issued: moment().toISOString(),
      due_date: moment().toISOString(),
      project_id: null,
    };
    dispatch(invoiceActions.selectedInvoiceAction(Obj));

    history.push(`${pathname}/review`);
  };

  return (
    <div className="px-lg-4 px-md-4 px-sm-3 px-2">
      <TextStyles.FontSize27px className="text-center mb-3 mb-md-4 mb-lg-5">
        What do you want to invoice?
      </TextStyles.FontSize27px>
      <div className="d-flex justify-content-center align-items-center w-100">
        <div className="w-100" style={{ maxWidth: 650 }}>
          <form onSubmit={handleSubmit(formsubmit)}>
            <Styled.SetupBox className="mb-5">
              {fields.map((item, index) => {
                const serviceRate = watch(`servicesList.${index}.service_rate`);
                const serviceRateType = watch(
                  `servicesList.${index}.service_rate_type`
                );
                const serviceRateCount = watch(
                  `servicesList.${index}.service_rate_count`
                );
                let serviceTotal;
                if (serviceRateType === "Flat fee") {
                  serviceTotal = serviceRate * 1;
                } else {
                  serviceTotal = serviceRate * (serviceRateCount || 1);
                }
                return (
                  <div key={item.id} className="services_container">
                    <TextStyles.FontSize14px className="mb-3">
                      <label htmlFor="service_name" className="mb-2">
                        Service name
                      </label>
                      <div className="d-flex flex-row align-items-center">
                        <TextStyles.InputRectangle
                          style={{ width: "85%" }}
                          id="service_name"
                          type="text"
                          placeholder="Service name"
                          name="service_name"
                          {...register(`servicesList.${index}.service_name`)}
                        />
                        <span className="ms-4">
                          {fields.length !== 1 && (
                            <DeleteForeverRoundedIcon
                              sx={{ color: Colors.font2 }}
                              onClick={() => remove(index)}
                              className="deleteBtn"
                            />
                          )}
                        </span>
                      </div>
                    </TextStyles.FontSize14px>
                    <TextStyles.FontSize14px className="mb-3">
                      <label htmlFor="rate" className="mb-2">
                        Rate
                      </label>

                      <div className="d-flex flex-wrap align-items-center">
                        <Styled.inputBox
                          style={{ maxWidth: "fit-content" }}
                          className="me-3"
                        >
                          <span>{currencySymbol}</span>
                          <input
                            style={{ width: 60 }}
                            type="number"
                            step="0.01"
                            min={"0"}
                            id="service_rate"
                            name="service_rate"
                            placeholder="00.00"
                            {...register(`servicesList.${index}.service_rate`)}
                          />
                        </Styled.inputBox>
                        <div className="me-2">
                          <Controller
                            control={control}
                            name={`servicesList.${index}.service_rate_type`}
                            render={({ field: { onChange, value } }) => (
                              <SelectServiceType
                                placeholder="Select"
                                onChange={onChange}
                                defaultValue={value}
                              />
                            )}
                          />
                        </div>
                        {serviceRateType && serviceRateType !== "Flat fee" ? (
                          <TextStyles.FontSize14px className="d-flex flex-wrap align-items-center">
                            <CloseIcon
                              className="me-2"
                              sx={{ color: Colors.font2, fontSize: 20 }}
                            />
                            <Styled.inputBox
                              style={{ maxWidth: 60 }}
                              className="me-2"
                            >
                              <input
                                type="number"
                                step="0.01"
                                min={"0"}
                                defaultValue={1}
                                placeholder="00"
                                name="service_rate_count"
                                {...register(
                                  `servicesList.${index}.service_rate_count`
                                )}
                                style={{ width: 30, textAlign: "center" }}
                              />
                            </Styled.inputBox>
                            <TextStyles.FontSize14px>
                              {serviceRateType === "Per hour"
                                ? "hours"
                                : serviceRateType === "Per day"
                                ? "days"
                                : serviceRateType === "Per item"
                                ? "items"
                                : serviceRateType === "Per word"
                                ? "words"
                                : ""}
                            </TextStyles.FontSize14px>
                          </TextStyles.FontSize14px>
                        ) : (
                          ""
                        )}
                      </div>
                    </TextStyles.FontSize14px>
                    <TextStyles.FontSize14px className="mb-3">
                      <label htmlFor="description" className="mb-2">
                        Description
                      </label>
                      <TextStyles.Textarea
                        rows={4}
                        id="description"
                        name="desc"
                        placeholder="Add a note..."
                        {...register(`servicesList.${index}.desc`)}
                      />
                    </TextStyles.FontSize14px>

                    <div
                      className="mb-3"
                      style={{
                        width: "100%",
                        height: "1px",
                        border: "0.5px solid #edeef2",
                      }}
                    />
                    <TextStyles.FontSize20px className="d-flex justify-content-between align-items-center three-dots w-100 mt-3">
                      <div className="three-dots" style={{ minWidth: 100 }}>
                        Total service charges
                      </div>
                      <div
                        className="three-dots"
                        style={{ color: Colors.primary, minWidth: 50 }}
                      >
                        {currencySymbol}
                        {serviceTotal > 0}
                        {serviceTotal > 0
                          ? parseFloat(serviceTotal).toFixed(2)
                          : "00.00"}
                      </div>
                    </TextStyles.FontSize20px>

                    <div
                      className="mb-3"
                      style={{
                        width: "100%",
                        height: "1px",
                        border: "0.5px solid #edeef2",
                      }}
                    />
                  </div>
                );
              })}
              <TextStyles.FontSize14px
                className="d-flex justify-content-end"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  append({
                    service_name: "",
                    service_rate: "",
                    service_rate_type: "Flat fee",
                    service_rate_count: "1",
                    desc: "",
                  })
                }
              >
                <AddCircleOutlineOutlinedIcon sx={{ color: Colors.font1 }} />
                <span className="ms-2">Add another service</span>
              </TextStyles.FontSize14px>
            </Styled.SetupBox>
            <Styled.NextButton>Next</Styled.NextButton>
          </form>
        </div>
      </div>
    </div>
  );
};

export default QuickSetup;
