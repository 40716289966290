import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { message } from "antd";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateCompensationPC } from "../../../../../../store/service/proposalAndContractService";
import CompensationModal from "../../PageModals/CompensationModal";
import Colors from "../../../../../../Constants/Colors";

const Compensation = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const heading =
    selectedData &&
    selectedData.compensation &&
    selectedData.compensation.heading
      ? selectedData &&
        selectedData.compensation &&
        selectedData.compensation.heading
      : "Compensation";
  const paragraph =
    selectedData &&
    selectedData.compensation &&
    selectedData.compensation.paragraph
      ? selectedData &&
        selectedData.compensation &&
        selectedData.compensation.paragraph
      : `Client will pay the Contractor a compensation at the decided rate (Per hour, day, item or word), based on invoices submitted by Contractor to the Client on basis (Once, weekly, bi-weekly, monthly) within the due date of receipt of said invoices. Contractor acknowledges and agrees that Contractor must accurately and truthfully report to the Client the number of working hours per each pay period (the “Payroll Worksheet”).`;

  const isCompensationRequired =
    selectedData &&
    selectedData.compensation &&
    selectedData.compensation.required
      ? true
      : false;

  const toggleCompensationViewHandler = () => {
    const Obj = {
      _id: selectedData._id,
      compensation: {
        required: isCompensationRequired ? false : true,
        heading: isCompensationRequired ? heading : null,
        paragraph: isCompensationRequired ? paragraph : null,
      },
    };
    dispatch(updateCompensationPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        message.success(
          isCompensationRequired
            ? "Clause hidden successfully!"
            : "Clause unhidden successfully!"
        );
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div>
      <Styled.ReviewContentBox className="mt-4">
        <div className="edit-delete-icons">
          <span onClick={toggleCompensationViewHandler}>
            {isCompensationRequired ? (
              <VisibilityOffOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            ) : (
              <VisibilityOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            )}
          </span>

          {isCompensationRequired && (
            <BorderColorOutlinedIcon
              sx={{
                cursor: "pointer",
                color: Colors.font2,
                opacity: 0.8,
                marginLeft: 2,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={() => setShowModal(true)}
            />
          )}
        </div>

        <TextStyles.FontSize20px className="mb-3">
          <span
            className={`fw-medium ${isCompensationRequired ? "" : "disabled"}`}
          >
            {heading}
          </span>
        </TextStyles.FontSize20px>

        <TextStyles.FontSize14px
          className={`lh-lg ${isCompensationRequired ? "" : "disabled"}`}
        >
          {paragraph}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>

      {showModal && (
        <CompensationModal
          selectedData={selectedData}
          paragraph={paragraph}
          heading={heading}
          show={showModal}
          handleClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default Compensation;
