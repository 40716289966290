import styled from "styled-components";
import Colors from "../../../../../Constants/Colors";

const TodoRectangle = styled.div`
  width: 100%;
  height: 3.15rem;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  background-color: ${Colors.tableHeadColor};
  border-radius: 0.75rem 0.75rem 0 0;
  .visible-task-name,
  .visible-projects,
  .visible-clients,
  .visible-createdAt,
  .visible-due-date {
    visibility: hidden;
  }

  .hover-task-name:hover .visible-task-name,
  .hover-projects:hover .visible-projects,
  .hover-clients:hover .visible-clients,
  .hover-createdAt:hover .visible-createdAt,
  .hover-due-date:hover .visible-due-date {
    visibility: visible;
  }
`;

const Oval = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  background-color: ${Colors.primary};
`;

const SliderBox = styled.div`
  width: 100%;
  border-radius: 0.75rem;
  box-shadow: 0 8px 12px 0 rgba(238, 241, 245, 0.89);
  background-color: ${Colors.white};
  font-weight: 500;
`;

const SelectedItem = styled.div`
  padding: 15px 18px 14px 24px;
  cursor: pointer;
  font-family: Poppins;
  font-size: 0.875rem;
  color: ${Colors.primary};
  border-bottom: 3px Solid ${Colors.primary};
`;

const NotSelectedItem = styled(SelectedItem)`
  color: ${Colors.font1};
  border-bottom: none;
`;

export default {
  TodoRectangle,
  Oval,
  SliderBox,
  SelectedItem,
  NotSelectedItem,
};
