import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../../axios/axios";

export const fetchNotesForUser = createAsyncThunk(
  "notes/fetchNotesForUser",
  async (payload) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      getRequest("/notes/fetch-notes-for-user", token, payload)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const createNote = createAsyncThunk("/notes/addNote", async (data) => {
  const token = localStorage.getItem("token");

  return new Promise((resolve, reject) => {
    postRequest("/notes/create-note", token, data)
      .then((data) => {
        if (data.data.success) resolve(data.data);
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err);
        }
      });
  });
});

export const updateNote = createAsyncThunk(
  "/notes/updateNote",
  async (data) => {
    const index = data.index;
    const payload = {
      title: data.title,
      note: data.note,
      priority: data.priority,
    };

    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest(`/notes/update-note/${data.id}`, token, payload)
        .then((data) => {
          if (data.data.success) resolve({ index, ...data.data });
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const deleteNote = createAsyncThunk("/notes/deleteNote", async (id) => {
  const token = localStorage.getItem("token");

  return new Promise((resolve, reject) => {
    deleteRequest("/notes/delete-note", token, { id })
      .then((data) => {
        if (data.data.success) resolve(id);
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err);
        }
      });
  });
});
