import React from "react";
import { TimePicker, Space } from "antd";
import dayjs from "dayjs";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import "./CustomTimePickerOne.css";
import Colors from "../../../../Constants/Colors";

const CustomTimePickerOne = ({
  status = null,
  placeholder = "Select Time",
  timeFormat = "hh:mm A",
  selected = null,
  onChange,
  allowClear = true,
  disabled = false,
  suffixIcon = (
    <AccessTimeFilledIcon sx={{ color: Colors.primary, fontSize: 22 }} />
  ),
  ...rest
}) => {
  const handleTimeChange = (time) => {
    if (onChange) {
      onChange(time ? dayjs(time).toDate() : null);
    }
  };

  return (
    <Space
      direction="vertical"
      style={{
        width: "100%",
      }}
    >
      <TimePicker
        status={status}
        suffixIcon={suffixIcon}
        value={selected ? dayjs(selected) : null}
        onChange={handleTimeChange}
        format={timeFormat}
        placeholder={placeholder}
        allowClear={allowClear}
        disabled={disabled}
        {...rest}
        className="custum-time-picker-one"
        popupClassName="custum-time-picker-one-popup"
      />
    </Space>
  );
};

export default CustomTimePickerOne;
