import React from "react";

import moment from "moment/moment";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import Styled from "./TaskListStyles";
import TextStyles from "../../../../../Constants/TextStyles";
import Colors from "../../../../../Constants/Colors";

import { ReactTooltip } from "../../../Commons/ReactTooltip";

import CustomDatePickerThree from "../../../Commons/DatePickers/CustomDatePickerThree";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useSelector } from "react-redux";

const TaskList = ({
  index,
  data,
  taskData,
  showInput,
  filteredTaskStatus,
  setShow,
  setSelectedTaskData,
  doneTaskCallApi,
  startTimerHandler,
  isTTLoading,
  deleteTaskHandler,
  setShowDeleteModal,
  setConfirmDialog,
  todoTaskCallApi,
  setDueDateApiCall,
  isLoading,
  Controller,
  control2,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    // transition,
    isDragging,
  } = useSortable({ id: data && data.id });

  const sortingData = useSelector((state) => state.tasks.sortingData);

  const selectedSortingOne =
    sortingData && sortingData.selectedSortingOne
      ? sortingData.selectedSortingOne
      : "orders";

  const style = {
    transform: CSS.Transform.toString(transform),
    zIndex: isDragging ? 10 : "",
    position: "relative",
    background: isDragging ? Colors.backgroundPink : "",
    marginTop: 1,
    borderRadius:
      taskData && taskData.length === 1 && !showInput
        ? "0 0 12px 12px"
        : taskData && taskData.length - 1 === index
        ? "0 0 12px 12px"
        : "",
  };

  return (
    <Styled.TodoRectangle
      ref={setNodeRef}
      style={style}
      onClick={() => {
        setShow(true);
        setSelectedTaskData(data);
      }}
    >
      <TextStyles.FontSize14px className="d-flex gap-2 align-items-center w-100">
        <div
          className="d-flex justify-content-center align-items-center w-100"
          style={{ minWidth: 25, maxWidth: 25 }}
        >
          {data.status === "done" && (
            <span>
              <TaskAltIcon
                fontSize="medium"
                sx={{
                  color: Colors.primary,
                  cursor: "pointer",
                }}
              />
            </span>
          )}
          {data.status !== "done" && (
            <span
              style={{ width: 24, height: 30 }}
              className="d-flex justify-content-center align-items-center radioButtonWrapper"
            >
              <RadioButtonUncheckedIcon
                className="radioButton"
                fontSize="medium"
                sx={{
                  color: Colors.font2,
                  cursor: "pointer",
                }}
              />

              <TaskAltIcon
                className="radioCheckedButton"
                fontSize="medium"
                sx={{
                  color: Colors.font2,
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  doneTaskCallApi(data);
                }}
              />
            </span>
          )}
        </div>
        <div
          className="d-flex justify-content-start w-100"
          style={{ minWidth: 130 }}
        >
          <span className="three-dots"> {data.task_name}</span>
        </div>
        <div
          className="d-none d-md-flex justify-content-start w-100"
          style={{ maxWidth: 140, minWidth: 30 }}
        >
          <span className="three-dots">
            {data.project_id && data.project_id.project_name}
          </span>
        </div>
        <div
          className="d-none d-md-flex justify-content-start w-100"
          style={{ maxWidth: 130, minWidth: 30 }}
        >
          <span className="three-dots">
            {data.client_id && data.client_id.company_name}
          </span>
        </div>
        <div
          className="d-none d-md-flex justify-content-center w-100"
          style={{ maxWidth: 105, minWidth: 30 }}
        >
          <span className="three-dots">
            {moment(data.createdAt).format("DD MMM YYYY")}
          </span>
        </div>
        <div
          className="d-flex justify-content-center w-100"
          style={{ maxWidth: 105, minWidth: 30 }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Controller
            name={`update_task.${index}.due_date`}
            control={control2}
            render={({ field: { onChange, value } }) => {
              return (
                <CustomDatePickerThree
                  onChange={(value) => {
                    onChange(value);
                    setDueDateApiCall(value, data);
                  }}
                  selected={value}
                  placeholder="Set Due Date"
                  disabled={isLoading ? true : false}
                />
              );
            }}
          />
        </div>

        <div
          className="d-none d-md-flex justify-content-end position-relative w-100"
          style={{ maxWidth: 207, minWidth: 207 }}
        >
          <div
            style={{
              position: "absolute",
              right: 0,
              height: "100%",
              top: "auto",
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            {filteredTaskStatus === "todo" && (
              <div className="d-flex justify-content-end gap-2 w-100 ">
                <div
                  id={`tasks-startTimer${index}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!isTTLoading) {
                      startTimerHandler(data);
                    }
                  }}
                >
                  <PlayCircleIcon
                    sx={{
                      color: Colors.primary,
                      fontSize: 24,
                      background: Colors.white,
                      cursor: "pointer",
                      padding: "2px 6px",
                      borderRadius: "6px",
                      width: 35,
                      height: 30,
                    }}
                  />
                </div>
                <ReactTooltip
                  anchorId={`tasks-startTimer${index}`}
                  content="Start timer"
                />
                <div
                  id={`tasks-doneTask${index}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!isLoading) {
                      doneTaskCallApi(data);
                    }
                  }}
                >
                  <CheckCircleOutlineIcon
                    sx={{
                      fontSize: 24,
                      color: Colors.font2,
                      background: Colors.white,
                      cursor: "pointer",
                      padding: "2px 6px",
                      borderRadius: "6px",
                      width: 35,
                      height: 30,
                    }}
                  />
                </div>

                <ReactTooltip
                  anchorId={`tasks-doneTask${index}`}
                  content="Done task"
                />
                <div
                  id={`tasks-editTask${index}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShow(true);
                    setSelectedTaskData(data);
                  }}
                >
                  <BorderColorOutlinedIcon
                    sx={{
                      color: Colors.success,
                      cursor: "pointer",
                      fontSize: 30,
                      background: Colors.white,
                      padding: "2px 6px",
                      borderRadius: "6px",
                      width: 35,
                      height: 30,
                    }}
                  />
                </div>

                <ReactTooltip
                  anchorId={`tasks-editTask${index}`}
                  content="Edit task"
                />
                <div
                  id={`tasks-deteteTask${index}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowDeleteModal(true);
                    setConfirmDialog({
                      onConfirm: () => deleteTaskHandler(data),
                    });
                  }}
                >
                  <DeleteForeverRoundedIcon
                    sx={{
                      color: Colors.red,
                      cursor: "pointer",
                      fontSize: 30,
                      background: Colors.white,
                      padding: "2px 2px",
                      borderRadius: "6px",
                      width: 35,
                      height: 30,
                    }}
                  />
                </div>

                <ReactTooltip
                  anchorId={`tasks-deteteTask${index}`}
                  content="Delete task"
                />
              </div>
            )}

            {filteredTaskStatus === "future" && (
              <div className="d-flex justify-content-end gap-2 w-100 ">
                <div
                  id={`tasks-doneTask${index}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!isLoading) {
                      doneTaskCallApi(data);
                    }
                  }}
                >
                  <CheckCircleOutlineIcon
                    sx={{
                      fontSize: 24,
                      color: Colors.font2,
                      background: Colors.white,
                      cursor: "pointer",
                      padding: "2px 6px",
                      borderRadius: "6px",
                      width: 35,
                      height: 30,
                    }}
                  />
                </div>

                <ReactTooltip
                  anchorId={`tasks-doneTask${index}`}
                  content="Done task"
                />
                <div
                  id={`tasks-editTask${index}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShow(true);
                    setSelectedTaskData(data);
                  }}
                >
                  <BorderColorOutlinedIcon
                    sx={{
                      color: Colors.success,
                      cursor: "pointer",
                      fontSize: 30,
                      background: Colors.white,
                      padding: "2px 6px",
                      borderRadius: "6px",
                      width: 35,
                      height: 30,
                    }}
                  />
                </div>

                <ReactTooltip
                  anchorId={`tasks-editTask${index}`}
                  content="Edit task"
                />
                <div
                  id={`tasks-deteteTask${index}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowDeleteModal(true);
                    setConfirmDialog({
                      onConfirm: () => deleteTaskHandler(data),
                    });
                  }}
                >
                  <DeleteForeverRoundedIcon
                    sx={{
                      color: Colors.red,
                      cursor: "pointer",
                      fontSize: 30,
                      background: Colors.white,
                      padding: "2px 2px",
                      borderRadius: "6px",
                      width: 35,
                      height: 30,
                    }}
                  />
                </div>

                <ReactTooltip
                  anchorId={`tasks-deteteTask${index}`}
                  content="Delete task"
                />
              </div>
            )}

            {filteredTaskStatus === "done" && (
              <div
                className="d-flex justify-content-end gap-2 w-100 dropdown"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <button
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <MoreHorizIcon
                    fontSize="large"
                    sx={{
                      background: Colors.white,
                      cursor: "pointer",
                      borderRadius: "6px",
                      padding: "2px 4px",
                      height: "30px",
                      color: "grey",
                    }}
                  />
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <button className="dropdown-item">
                      <TextStyles.FontSize14px
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!isLoading) {
                            todoTaskCallApi(data);
                          }
                        }}
                      >
                        Send to To do
                      </TextStyles.FontSize14px>
                    </button>
                  </li>
                  <li>
                    <button className="dropdown-item">
                      <TextStyles.FontSize14px
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowDeleteModal(true);
                          setConfirmDialog({
                            onConfirm: () => deleteTaskHandler(data),
                          });
                        }}
                      >
                        Delete
                      </TextStyles.FontSize14px>
                    </button>
                  </li>
                </ul>
              </div>
            )}
            <ReactTooltip
              anchorId={`tasklg_dragAndDrop${index}`}
              content="Rearrange by dragging"
            />
            {selectedSortingOne === "orders" && (
              <div
                className="d-flex ms-2 justify-content-end w-100"
                style={{ minWidth: 35 }}
                role="button"
                id={`tasklg_dragAndDrop${index}`}
                {...attributes}
                {...listeners}
              >
                <DragIndicatorIcon
                  fontSize="large"
                  sx={{
                    background: Colors.white,
                    cursor: "pointer",
                    borderRadius: "6px",
                    padding: "2px 4px",
                    height: "30px",
                    color: "grey",
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div
          className="d-flex d-md-none justify-content-end position-relative w-100"
          style={{
            minWidth: 70,
            maxWidth: 70,
          }}
        >
          <div
            style={{
              position: "absolute",
              right: 0,
              height: "100%",
              top: "auto",
              width: "100%",
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <div
              className="dropdown"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <button
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <MoreHorizIcon
                  fontSize="large"
                  sx={{
                    background: Colors.white,
                    cursor: "pointer",
                    borderRadius: "6px",
                    padding: "2px 4px",
                    height: "30px",
                    color: "grey",
                  }}
                />
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                {filteredTaskStatus === "todo" && (
                  <>
                    <li>
                      <button className="dropdown-item">
                        <TextStyles.FontSize14px
                          onClick={(e) => {
                            e.stopPropagation();
                            if (!isTTLoading) {
                              startTimerHandler(data);
                            }
                          }}
                        >
                          Start timer
                        </TextStyles.FontSize14px>
                      </button>
                    </li>
                    <li>
                      <button className="dropdown-item">
                        <TextStyles.FontSize14px
                          onClick={(e) => {
                            e.stopPropagation();
                            if (!isLoading) {
                              doneTaskCallApi(data);
                            }
                          }}
                        >
                          Done task
                        </TextStyles.FontSize14px>
                      </button>
                    </li>
                    <li>
                      <button className="dropdown-item">
                        <TextStyles.FontSize14px
                          onClick={(e) => {
                            e.stopPropagation();
                            setShow(true);
                            setSelectedTaskData(data);
                          }}
                        >
                          Edit task
                        </TextStyles.FontSize14px>
                      </button>
                    </li>
                    <li>
                      <button className="dropdown-item">
                        <TextStyles.FontSize14px
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowDeleteModal(true);
                            setConfirmDialog({
                              onConfirm: () => deleteTaskHandler(data),
                            });
                          }}
                        >
                          Delete task
                        </TextStyles.FontSize14px>
                      </button>
                    </li>
                  </>
                )}
                {filteredTaskStatus === "future" && (
                  <>
                    <li>
                      <button className="dropdown-item">
                        <TextStyles.FontSize14px
                          onClick={(e) => {
                            e.stopPropagation();
                            if (!isLoading) {
                              doneTaskCallApi(data);
                            }
                          }}
                        >
                          Done task
                        </TextStyles.FontSize14px>
                      </button>
                    </li>
                    <li>
                      <button className="dropdown-item">
                        <TextStyles.FontSize14px
                          onClick={(e) => {
                            e.stopPropagation();
                            setShow(true);
                            setSelectedTaskData(data);
                          }}
                        >
                          Edit task
                        </TextStyles.FontSize14px>
                      </button>
                    </li>
                    <li>
                      <button className="dropdown-item">
                        <TextStyles.FontSize14px
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowDeleteModal(true);
                            setConfirmDialog({
                              onConfirm: () => deleteTaskHandler(data),
                            });
                          }}
                        >
                          Delete task
                        </TextStyles.FontSize14px>
                      </button>
                    </li>
                  </>
                )}
                {filteredTaskStatus === "done" && (
                  <>
                    <li>
                      <button className="dropdown-item">
                        <TextStyles.FontSize14px
                          onClick={(e) => {
                            e.stopPropagation();
                            if (!isLoading) {
                              todoTaskCallApi(data);
                            }
                          }}
                        >
                          Send to To do
                        </TextStyles.FontSize14px>
                      </button>
                    </li>
                    <li>
                      <button className="dropdown-item">
                        <TextStyles.FontSize14px
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowDeleteModal(true);
                            setConfirmDialog({
                              onConfirm: () => deleteTaskHandler(data),
                            });
                          }}
                        >
                          Delete
                        </TextStyles.FontSize14px>
                      </button>
                    </li>
                  </>
                )}
              </ul>
            </div>
            <ReactTooltip
              anchorId={`tasksm_dragAndDrop${index}`}
              content="Rearrange by dragging"
            />
            {selectedSortingOne === "orders" && (
              <div
                className="d-flex justify-content-end ms-2 w-100"
                style={{ minWidth: 35 }}
                role="button"
                id={`tasksm_dragAndDrop${index}`}
                {...attributes}
                {...listeners}
              >
                <DragIndicatorIcon
                  fontSize="large"
                  sx={{
                    background: Colors.white,
                    cursor: "pointer",
                    borderRadius: "6px",
                    padding: "2px 4px",
                    height: "30px",
                    color: "grey",
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </TextStyles.FontSize14px>
    </Styled.TodoRectangle>
  );
};

export default TaskList;
