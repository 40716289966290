import React from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import "./CustomDatePickerThree.css";

const CustomDatePickerThree = ({
  status = null,
  placeholder = "Select Date",
  dateFormat = "DD MMM YYYY",
  selected = null,
  onChange,
  minDate = null,
  maxDate = null,
  allowClear = false,
  disabled = false,
  ...rest
}) => {
  const handleDateChange = (date) => {
    if (onChange) {
      onChange(date ? dayjs(date).toDate() : null);
    }
  };

  return (
    <DatePicker
      status={status}
      // defaultValue={selected ? dayjs(selected) : null}
      value={selected ? dayjs(selected) : null}
      onChange={handleDateChange}
      format={dateFormat}
      placeholder={placeholder}
      allowClear={allowClear}
      disabled={disabled}
      // disabledDate={(current) => {
      //   if (minDate && current < dayjs(minDate)) return true;
      //   if (maxDate && current > dayjs(maxDate)) return true;
      //   return false;
      // }}
      suffixIcon={null}
      {...rest}
      className="custum-date-picker-three"
      popupClassName="custum-date-picker-three-popup"
    />
  );
};

export default CustomDatePickerThree;
