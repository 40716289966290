import React from "react";

import TextStyles from "../../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";

import Colors from "../../../../../../../Constants/Colors";

const Deposit = (props) => {
  const selectedData = props && props.selectedData;

  const heading = "Deposit";

  return (
    <div
      style={{
        display:
          selectedData.deposit && selectedData.deposit.required === true
            ? "inline"
            : "none",
      }}
    >
          <Styled.ReviewContentBox className="mt-4">
            <TextStyles.FontSize20px className="mb-3 fw-medium">
              {heading}
            </TextStyles.FontSize20px>
            <TextStyles.FontSize14px className="lh-lg">
                  Upon the acceptance of this Agreement, a deposit of
                  <span
                    className="mx-2"
                    style={{ color: Colors.primary, fontWeight: 500 }}
                  >
                    {selectedData.currency.symbol}
                    {parseFloat(
                      selectedData.billing_scheduled.deposit.value || 0
                    ).toFixed(2)}
                  </span>
                  is required before work will commence.
            </TextStyles.FontSize14px>
          </Styled.ReviewContentBox>
    </div>
  );
};

export default Deposit;
