import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { message } from "antd";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateNoticesPC } from "../../../../../../store/service/proposalAndContractService";
import NoticesModal from "../../PageModals/NoticesModal";
import Colors from "../../../../../../Constants/Colors";

const Notices = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const heading =
    selectedData && selectedData.notices && selectedData.notices.heading
      ? selectedData && selectedData.notices && selectedData.notices.heading
      : "Notices";

  const paragraph =
    selectedData && selectedData.notices && selectedData.notices.paragraph
      ? selectedData.notices.paragraph
      : `All notices required by this Agreement shall be sent either (i) via US mail or a nationally recognized carrier to the other Party's address on file or (ii) via email to the other Party's designated representative. Each Party is responsible for providing and updating, as needed, its mailing and email address for such notices. Notices sent by email shall be considered effective upon sending if no error or "bounce back" is received within twenty-four (24) hours of submission.`;

  const isNoticeRequired =
    selectedData && selectedData.notices && selectedData.notices.required
      ? true
      : false;

  const toggleNoticeViewHandler = () => {
    const Obj = {
      _id: selectedData._id,
      notices: {
        required: isNoticeRequired ? false : true,
        heading: isNoticeRequired ? heading : null,
        paragraph: isNoticeRequired ? paragraph : null,
      },
    };
    dispatch(updateNoticesPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        message.success(
          isNoticeRequired
            ? "Clause hidden successfully!"
            : "Clause unhidden successfully!"
        );
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div>
      <Styled.ReviewContentBox className="mt-4">
        <div className="edit-delete-icons">
          <span onClick={toggleNoticeViewHandler}>
            {isNoticeRequired ? (
              <VisibilityOffOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            ) : (
              <VisibilityOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            )}
          </span>

          {isNoticeRequired && (
            <BorderColorOutlinedIcon
              sx={{
                cursor: "pointer",
                color: Colors.font2,
                opacity: 0.8,
                marginLeft: 2,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={() => setShowModal(true)}
            />
          )}
        </div>

        <TextStyles.FontSize20px className="mb-3">
          <span className={`fw-medium ${isNoticeRequired ? "" : "disabled"}`}>
            {heading}
          </span>
        </TextStyles.FontSize20px>

        <TextStyles.FontSize14px
          className={`lh-lg ${isNoticeRequired ? "" : "disabled"}`}
          style={{ whiteSpace: "break-spaces" }}
        >
          {paragraph}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>

      {showModal && (
        <NoticesModal
          selectedData={selectedData}
          heading={heading}
          paragraph={paragraph}
          show={showModal}
          handleClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default Notices;
