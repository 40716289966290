import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { message } from "antd";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateRelationshipOfPartiesPC } from "../../../../../../store/service/proposalAndContractService";
import RelationshipOfPartiesModal from "../../PageModals/RelationshipOfPartiesModal";
import Colors from "../../../../../../Constants/Colors";

const RelationshiWithParty = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const heading =
    selectedData &&
    selectedData.relationship_of_parties &&
    selectedData.relationship_of_parties.heading
      ? selectedData &&
        selectedData.relationship_of_parties &&
        selectedData.relationship_of_parties.heading
      : "Relationship of Parties";
  const paragraph =
    selectedData &&
    selectedData.relationship_of_parties &&
    selectedData.relationship_of_parties.paragraph
      ? selectedData &&
        selectedData.relationship_of_parties &&
        selectedData.relationship_of_parties.paragraph
      : "This Agreement does not establish a partnership, joint venture, agency, or employment relationship between Contractor and Client. Both Parties acknowledge that Contractor is an independent contractor throughout the duration of this Agreement.";

  const isRelationShipOfPartiesRequired =
    selectedData &&
    selectedData.relationship_of_parties &&
    selectedData.relationship_of_parties.required
      ? true
      : false;

  const toggleRelationShipOfPartiesViewHandler = () => {
    const Obj = {
      _id: selectedData._id,
      relationship_of_parties: {
        required: isRelationShipOfPartiesRequired ? false : true,
        heading: isRelationShipOfPartiesRequired ? heading : null,
        paragraph: isRelationShipOfPartiesRequired ? paragraph : null,
      },
    };
    dispatch(updateRelationshipOfPartiesPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        message.success(
          isRelationShipOfPartiesRequired
            ? "Clause hidden successfully!"
            : "Clause unhidden successfully!"
        );
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div>
      <Styled.ReviewContentBox className="mt-4">
        <div className="edit-delete-icons">
          <span onClick={toggleRelationShipOfPartiesViewHandler}>
            {isRelationShipOfPartiesRequired ? (
              <VisibilityOffOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            ) : (
              <VisibilityOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            )}
          </span>

          {isRelationShipOfPartiesRequired && (
            <BorderColorOutlinedIcon
              sx={{
                cursor: "pointer",
                color: Colors.font2,
                opacity: 0.8,
                marginLeft: 2,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={() => setShowModal(true)}
            />
          )}
        </div>

        <TextStyles.FontSize20px className="mb-3">
          <span
            className={`fw-medium ${
              isRelationShipOfPartiesRequired ? "" : "disabled"
            }`}
          >
            {heading}
          </span>
        </TextStyles.FontSize20px>

        <TextStyles.FontSize14px
          className={`lh-lg ${
            isRelationShipOfPartiesRequired ? "" : "disabled"
          }`}
        >
          {paragraph}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
      {showModal && (
        <RelationshipOfPartiesModal
          selectedData={selectedData}
          paragraph={paragraph}
          heading={heading}
          show={showModal}
          handleClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default RelationshiWithParty;
